<template>
  <div :class="componentClass" class="s-wrapper">
    <v-text-field v-bind="attributes" ref="input" :error="hasError" @update:model-value="$emit('update:modelValue', $event)">
      <template v-if="$slots['append-inner']" #append-inner>
        <slot name="append-inner"></slot>
      </template>
      <template v-if="$slots['append']" #append>
        <slot name="append"></slot>
      </template>
      <template v-if="$slots['prepend']" #prepend>
        <slot name="prepend"></slot>
      </template>
      <template v-if="$slots['prepend-inner']" #prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
      <template v-if="$slots['default']" #default>
        <slot name="default"></slot>
      </template>
    </v-text-field>
    <k-input-error v-if="props.errors" :name="props.name" :errors="props.errors" :label="props.label" />
    <k-input-error v-if="detailStore" :name="props.name" :errors="detailStore.errors" :label="props.label" />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref, useAttrs } from "vue"
import { VTextField } from "vuetify/components"
import { DetailPageStore } from "@/store/factories/detailPageStore"

const props = defineProps({
  ...VTextField.props,
  errors: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  hideDetails: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
})
defineEmits(["update:modelValue"])
const detailStore = inject<DetailPageStore | null>("detailStore", null)

const attrs = useAttrs()

/** We're setting any here, so that ts doesn't complain that we potentially assign too many attributes to the v-text-field component */
const attributes = computed<any>(() => {
  const combinedProps = {
    ...props,
    ...attrs,
    variant: "outlined",
    density: "compact",
    centerAffix: true,
    hideDetails: props.hideDetails === undefined ? !props.hint : props.hideDetails,
  }
  delete combinedProps["class"]
  return combinedProps
})

const componentClass = computed(() => props.class)

const hasError = computed(() => {
  if (!props.name) {
    return false
  }
  if (props.errors?.[props.name]) {
    return true
  }
  if (detailStore?.errors?.[props.name]) {
    return true
  }
  return false
})

const input = ref<InstanceType<typeof VTextField>>()
</script>

<style lang="scss" scoped>
.s-wrapper {
  flex: 1 0;
}
</style>
