export function getCertificateTemplatesDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/outlet`,
      component: () => import("./components/CertificateTemplateDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:certificateTemplateId/general`,
          name: `${currentModule}/certificate-template-general`,
          component: () => import("./views/CertificateTemplateEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "certificates",
          },
        },
      ],
    },
  ]
}
export function getCertificateTemplatesRoutes() {
  return [
    {
      path: "/certificate-templates",
      name: "certificate-templates",
      component: () => import("./views/CertificateTemplateList.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getCertificateTemplatesDetailPageRoutes("certificates")],
    },
  ]
}
