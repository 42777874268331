<template>
  <div v-if="errors && name && errors[name]">
    <span v-for="(err, index) in errors[name]" :key="index" class="text-label2 text-pink d-block">{{ replaceLabel(err) }}</span>
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name?: string
    errors?: Record<string, any>
    label?: string
  }>(),
  {
    name: "",
    errors: undefined,
    label: "",
  },
)

function replaceLabel(message: string) {
  return message.replace(":attribute", props.label)
}
</script>
