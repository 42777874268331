<template>
  <div class="s-table__wrapper">
    <div v-if="$slots.filters" class="s-table__filters">
      <slot name="filters" />
    </div>
    <ag-grid-vue
      class="s-table ag-theme-keelearning"
      :class="{ '-pagination-disabled': disablePagination }"
      :suppress-row-click-selection="true"
      :no-rows-overlay-component="props.mightHaveNotAllowedEntries ? EmptyAreaWithNotAlloweEntries : EmptyArea"
      :tooltip-show-delay="100"
      dom-layout="autoHeight"
      :suppress-cell-focus="true"
      :column-defs="filteredColumns"
      :default-col-def="defaultColumnDefinition"
      :get-row-id="props.getRowId"
      :get-row-class="props.getRowClass"
      :row-class="defaultRowClass"
      row-selection="multiple"
      :animate-rows="false"
      :loading-overlay-component="LoadingOverlay"
      @cell-clicked="onCellClicked"
      @sort-changed="onSortChanged"
      @grid-ready="onGridReady"
      @row-selected="emit('rowSelected', $event)"
      @row-clicked="onRowClicked"
      @first-data-rendered="emit('firstDataRendered', $event)"
    ></ag-grid-vue>
    <pagination v-if="!disablePagination" :store="store" />
  </div>
</template>
<script lang="ts" setup>
import { AgGridVue } from "ag-grid-vue3"
import { watch, onUnmounted, computed } from "vue"

import "ag-grid-community/styles/ag-grid.css"
import "@/styles/vendor/ag-grid/theme.scss"
import { GridApi, GridReadyEvent, SortChangedEvent, CellClickedEvent, RowClickedEvent } from "ag-grid-community"
import LoadingOverlay from "@/components/KTable/LoadingOverlay.vue"
import { EntryListStore } from "@/store/factories/entryListStore"
import Pagination from "@/components/KTable/Pagination.vue"
import EmptyArea from "@/components/KTable/EmptyArea.vue"
import type { ColDef, ColumnState } from "ag-grid-community"
import { storeToRefs } from "pinia"
import EmptyAreaWithNotAlloweEntries from "@/components/KTable/EmptyAreaWithNotAlloweEntries.vue"

type ColumnDefinition = ColDef<any> & {
  excelHeaderName?: string
}

const props = withDefaults(
  defineProps<{
    store: EntryListStore
    getRowId?: (data: any) => any
    getRowClass?: (data: any) => any
    columns: ColumnDefinition[]
    clickable?: boolean
    disablePagination?: boolean
    dataKey?: string
    mightHaveNotAllowedEntries?: boolean
  }>(),
  {
    getRowId: (data: any) => {
      return data.data.id
    },
    getRowClass: () => {
      return ""
    },
    clickable: false,
    dataKey: "entries",
    disablePagination: false,
    mightHaveNotAllowedEntries: false,
  },
)
const emit = defineEmits(["rowClicked", "rowSelected", "onGridReady", "firstDataRendered"])

const filteredColumns = computed(() => {
  return [...props.columns].filter((column: any) => !column.onlyForExport)
})

let gridApi: GridApi | null = null

onUnmounted(() => {
  if (!gridApi) {
    return
  }
  if (!gridApi.isDestroyed()) {
    gridApi.destroy()
  }
})

function onGridReady(event: GridReadyEvent) {
  emit("onGridReady", event)
  gridApi = event.api

  watch(
    () => props.store[props.dataKey],
    () => {
      let newData: Array<any> | null = props.store[props.dataKey]
      if (Array.isArray(newData)) {
        newData = [...newData]
      }
      gridApi?.setGridOption("rowData", newData)
      gridApi?.redrawRows()
    },
    {
      immediate: true,
    },
  )

  watch(
    () => props.store.isLoading,
    (isLoading: boolean) => {
      if (isLoading) {
        gridApi?.showLoadingOverlay()
      } else {
        const entries: any[] = props.store[props.dataKey]
        if (!entries?.length) {
          gridApi?.showNoRowsOverlay()
        } else {
          gridApi?.hideOverlay()
        }
      }
    },
    {
      immediate: true,
    },
  )
}

function onCellClicked(event: CellClickedEvent) {
  // Select a row if the user clicks anywhere in the "row selection" checkbox cell
  if (typeof event.colDef.checkboxSelection === "boolean" && event.colDef.checkboxSelection) {
    event.node.setSelected(!event.node.isSelected())
    if (event.event) {
      // Cancel bubble is a deprecated IE feature, but we use it to tell the onRowClicked event that it shouldn't trigger
      event.event.cancelBubble = true
    }
  }
}

function onRowClicked(event: RowClickedEvent) {
  if (event.event?.cancelBubble) {
    return
  }
  emit("rowClicked", event)
}

function onSortChanged(event: SortChangedEvent) {
  const newSort = {
    by: null as null | string,
    desc: false,
  }
  event.columnApi.getColumnState().forEach((column: ColumnState) => {
    if (column.sort === "asc") {
      newSort.by = column.colId
      newSort.desc = false
    }
    if (column.sort === "desc") {
      newSort.by = column.colId
      newSort.desc = true
    }
  })
  const { sort } = storeToRefs(props.store)
  sort.value.by = newSort.by
  sort.value.desc = newSort.desc
}

const defaultRowClass = computed(() => {
  if (props.clickable) {
    return "-clickableRow"
  }
  return ""
})

const defaultColumnDefinition = {
  comparator() {
    // Disable client side sorting, as we're doing sorting on the server
    return 0
  },
}

defineExpose({
  getAPI(): GridApi | null {
    return gridApi
  },
})
</script>

<style lang="scss" scoped>
.s-table__wrapper {
  background: white;
  border-radius: 4px;
  border: 1px solid $color-grey2;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05));
}

.s-table__filters {
  padding: 20px 12px 12px 12px;
}

:global(.s-table .-clickableRow:hover) {
  cursor: pointer;
  background-color: $color-grey1;
}

:global(.s-table.-pagination-disabled .ag-row:last-child) {
  border-bottom: none;
}
</style>
