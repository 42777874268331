export function getAccountRoutes() {
  return [
    {
      path: "/login",
      name: "login",
      component: () => import("./views/LoginView.vue"),
      meta: {
        anonymousOnly: true,
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import(/* webpackChunkName: "account-forgot-password" */ "./views/ForgotPasswordView.vue"),
      meta: {
        anonymousOnly: true,
      },
    },
    {
      path: "/reset-password/:userId",
      name: "reset-password",
      component: () => import(/* webpackChunkName: "account-reset-password" */ "./views/ResetPasswordView.vue"),
      meta: {
        anonymousOnly: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("./views/LogoutView.vue"),
    },
    {
      path: "/privacy-note",
      name: "privacy-note",
      component: () => import("./views/PrivacyNoteView.vue"),
      meta: {
        hideNavigation: true,
      },
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("./views/PrivacyPolicyView.vue"),
      meta: {
        hideNavigation: true,
      },
    },
    {
      path: "/upvoty",
      name: "upvoty",
      component: () => import("./views/UpvotyView.vue"),
      meta: {
        hideNavigation: true,
      },
    },
  ]
}
