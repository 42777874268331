export function getEmailReportingsDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/outlet`,
      component: () => import("./components/EmailReportingDetailsWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:id/general`,
          name: `${currentModule}/email-reporting-general`,
          component: () => import("./views/EmailReportingEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "email-reportings",
          },
        },
      ],
    },
  ]
}
