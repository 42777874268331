<template>
  <div :class="{ [activeClass]: activeClass, 'c-checkbox-disabled': disabled }">
    <label class="d-flex ga-1 py-2 mb-1 cursor-pointer">
      <input v-model="value" type="checkbox" class="c-checkbox d-block" :disabled="disabled" @change="emit('change', $event)" />
      <span class="flex-1-1">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

type KCustomCheckboxProps = {
  modelValue: boolean
  name?: string
  activeClass?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<KCustomCheckboxProps>(), {
  name: "",
  activeClass: "",
  disabled: false,
})
const emit = defineEmits(["update:modelValue", "change"])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
  },
})
</script>

<style scoped lang="scss">
.c-checkbox-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.c-checkbox {
  /* Remove most all native input styles */
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid $color-grey3;
  background: #fff;
  opacity: 1;
  position: relative;

  &::before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    width: 0.65em;
    height: 0.65em;
    color: $color-vio3;
    font-size: 12px;
    position: absolute;
    bottom: 7px;
    left: 1px;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    transform: scale(1);
  }
}
</style>
