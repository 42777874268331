import KAlert from "@/components/KAlert.vue"
import KAutocomplete from "@/components/KAutocomplete.vue"
import KBreadcrumb from "@/components/KBreadcrumb.vue"
import KButton from "@/components/KButton.vue"
import KCheckbox from "@/components/KCheckbox.vue"
import KBreadcrumbHeader from "@/components/KBreadcrumbHeader.vue"
import KContentHeader from "@/components/KContentHeader.vue"
import KDateField from "@/components/KDateField.vue"
import KDetailPage from "@/components/KDetailPage/KDetailPage.vue"
import KDetailPageOutlet from "@/components/KDetailPage/KDetailPageOutlet.vue"
import KDropDownButton from "@/components/KDropDownButton.vue"
import KLabelValue from "@/components/KLabelValue.vue"
import KRadio from "@/components/KRadio.vue"
import KRadioGroup from "@/components/KRadioGroup.vue"
import KRichTextEditor from "@/components/KRichTextEditor.vue"
import KSelect from "@/components/KSelect.vue"
import KSettingsPanel from "@/components/KSettingsPanel.vue"
import KSplitButton from "@/components/KSplitButton.vue"
import KSwitch from "@/components/KSwitch.vue"
import KTable from "@/components/KTable.vue"
import KTextField from "@/components/KTextField.vue"
import KTextarea from "@/components/KTextarea.vue"
import KTreeview from "@/components/KTreeview.vue"
import KChip from "@/components/KChip.vue"
import KCard from "@/components/KCard.vue"
import { App } from "vue"
import KInputError from "@/components/KInputError.vue"
import KRenderSeconds from "@/components/KRenderSeconds.vue"
import KColorPicker from "@/components/KColorPicker.vue"
import KIconPicker from "@/components/KIconPicker.vue"
import KIconColorPicker from "@/components/KIconColorPicker.vue"
import KCustomCheckbox from "@/components/KCustomCheckbox.vue"
import KConfirmationDialog from "@/components/KConfirmationDialog.vue"
import KKebabMenu from "@/components/KKebabMenu.vue"

export const loadKeelearningComponents = (app: App<Element>) => {
  app.component("KAlert", KAlert)
  app.component("KAutocomplete", KAutocomplete)
  app.component("KBreadcrumb", KBreadcrumb)
  app.component("KButton", KButton)
  app.component("KCheckbox", KCheckbox)
  app.component("KCustomCheckbox", KCustomCheckbox)
  app.component("KBreadcrumbHeader", KBreadcrumbHeader)
  app.component("KContentHeader", KContentHeader)
  app.component("KDateField", KDateField)
  app.component("KDetailPage", KDetailPage)
  app.component("KDetailPageOutlet", KDetailPageOutlet)
  app.component("KDropDownButton", KDropDownButton)
  app.component("KKebabMenu", KKebabMenu)
  app.component("KLabelValue", KLabelValue)
  app.component("KRadio", KRadio)
  app.component("KRadioGroup", KRadioGroup)
  app.component("KRichTextEditor", KRichTextEditor)
  app.component("KSelect", KSelect)
  app.component("KSettingsPanel", KSettingsPanel)
  app.component("KSplitButton", KSplitButton)
  app.component("KSwitch", KSwitch)
  app.component("KTable", KTable)
  app.component("KTextField", KTextField)
  app.component("KTextarea", KTextarea)
  app.component("KTreeview", KTreeview)
  app.component("KChip", KChip)
  app.component("KCard", KCard)
  app.component("KInputError", KInputError)
  app.component("KRenderSeconds", KRenderSeconds)
  app.component("KColorPicker", KColorPicker)
  app.component("KIconPicker", KIconPicker)
  app.component("KIconColorPicker", KIconColorPicker)
  app.component("KConfirmationDialog", KConfirmationDialog)
}
