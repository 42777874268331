<template>
  <v-menu open-on-hover :open-delay="150" location="bottom">
    <template #activator="{ props: activatorProps }">
      <k-button variant="text" :class="props.class" :color="props.muted ? 'grey-darken-3' : undefined" append-icon="fas fa-sm fa-chevron-down" prepend-icon="far fa-globe" v-bind="activatorProps">{{ currentLanguageLabel }}</k-button>
    </template>
    <v-list>
      <v-list-item v-for="(label, key) in getAvailableLanguagesForApp(appConfigStore)" :key="key" :title="label" @click="selectLanguage(key)" />
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { availableLanguages, getAvailableLanguagesForApp, getSelectedLanguage, setSelectedLanguage } from "@/logic/i18n"
import { computed } from "vue"
import { useAppConfigStore } from "@/store/appConfig"
const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    muted?: boolean
    class?: string
  }>(),
  {
    muted: false,
    class: undefined,
  },
)
const appConfigStore = useAppConfigStore()
const currentLanguageLabel = computed(() => {
  return availableLanguages[getSelectedLanguage().value] || "Deutsch"
})

async function selectLanguage(language: keyof typeof availableLanguages): Promise<void> {
  i18n.locale.value = language
  await setSelectedLanguage(language)
}
</script>
