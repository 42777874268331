<template>
  <v-menu location="bottom" :attach="false">
    <template #activator="{ props: menuProps }">
      <v-btn icon elevation="0" v-bind="menuProps">
        <v-icon color="secondary">far fa-ellipsis-v</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(itemLabel, value) in props.items" :key="value" @click="handleClick(value, resource)">
        <v-list-item-title>{{ itemLabel }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  (e: "select", value: string, resource?: number): void
  (e: "update:modelValue", value: string, resource?: number): void
}>()

const props = defineProps<{
  items: object
  resource?: number
}>()

const handleClick = (value: string, resource?: number) => {
  emit("select", value, resource)
  emit("update:modelValue", value, resource)
}
</script>
