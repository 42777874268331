export function getTestsRoutes() {
  return [
    {
      path: "/tests",
      name: "tests",
      component: () => import(/* webpackChunkName: tests-old-backend */ "./components/TestsOld.vue"),
    },
    {
      path: "/tests/:testId/:subPage?",
      name: "test-details",
      component: () => import(/* webpackChunkName: course-statistics-old-backend*/ "./components/TestDetailsOld.vue"),
    },
  ]
}
