import { getContentCategoryDetailsPageRoutes } from "@/modules/content-categories/routes"
import { getAppointmentsDetailPageRoutes } from "@/modules/appointments/route"
import { getEmailReportingsDetailPageRoutes } from "@/modules/email-reportings/route"
import { getConstants } from "@/logic/constants"
import { getFormDetailPageRoutes } from "@/modules/forms/route"
import { getQuestionDetailPageRoutes } from "@/modules/questions/routes"
import { getMediaLibraryFileDetailPageRoutes, getMediaLibraryFolderDetailPageRoutes } from "@/modules/media-libraries/routes"

export function getCoursesRoutes() {
  return [
    {
      path: "/courses",
      name: "courses",
      component: () => import("./views/Courses.vue"),
    },
    {
      path: "/courses/categories",
      name: "courses-categories",
      component: () => import("./views/CourseCategories.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getContentCategoryDetailsPageRoutes(`${getConstants().MORPH_TYPES.COURSE}-content-categories`, "details")],
    },
    {
      path: "/courses/templates",
      name: "courses-templates",
      component: () => import("./course-templates/views/Index.vue"),
    },
    {
      path: "/courses/templates/manage",
      name: "courses-manage-templates",
      component: () => import("./course-templates/views/manage/Index.vue"),
    },
    {
      path: "/courses/templates/:courseTemplateId/preview",
      name: "courses-template-preview",
      component: () => import("./course-templates/views/TemplatePreview.vue"),
    },
    {
      path: "/courses/:courseId",
      name: `course-edit/outlet`,
      component: () => import("./views/CourseEditWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: "contents/:chapterId?",
          name: "course-edit-contents",
          component: () => import("./views/CourseEditContents.vue"),
          meta: {
            isDetailPageParent: true,
            module: "courses",
          },
          children: [
            ...getAppointmentsDetailPageRoutes("courses", "/courses/:courseId/contents/:chapterId/:contentId/appointments"),
            ...getFormDetailPageRoutes("courses", "/courses/:courseId/contents/:chapterId/:contentId/forms"),
            ...getQuestionDetailPageRoutes("courses", "/courses/:courseId/contents/:chapterId/:contentId/questions"),
            ...getMediaLibraryFolderDetailPageRoutes("courses", "/courses/:courseId/contents/:chapterId/:contentId/folder/:folderId/medialibrary"),
            ...getMediaLibraryFileDetailPageRoutes("courses", "/courses/:courseId/contents/:chapterId/:contentId/folder/:folderId/medialibrary/files"),
          ],
        },
        {
          path: "settings",
          name: "course-edit-settings",
          component: () => import("./views/CourseEditSettings.vue"),
        },
        {
          path: "comments",
          name: `courses/course-comments`,
          component: () => import("./views/CourseComments.vue"),
          meta: {
            isDetailPage: true,
            module: "courses",
          },
        },
      ],
    },
    {
      path: "/course-statistics/:courseId",
      name: "course-statistics",
      component: () => import("./components/CourseStatisticsOld.vue"),
    },
    {
      path: "/analytics/courses/:courseId",
      name: "single-course-analytics-wrapper",
      component: () => import("./analytics/views/SingleCourseAnalyticsWrapper.vue"),
      children: [
        {
          path: "",
          name: "single-course-analytics-overview",
          component: () => import("./analytics/views/SingleCourseAnalytics.vue"),
        },
        {
          path: "participants",
          name: "single-course-analytics-participants",
          component: () => import("./analytics/views/CourseParticipantsList.vue"),
        },
        {
          path: "contents",
          name: "single-course-analytics-contents-wrapper",
          component: () => import("./analytics/views/CourseContentsWrapper.vue"),
          children: [
            {
              path: "",
              name: "single-course-analytics-contents",
              component: () => import("./analytics/views/CourseContentsList.vue"),
            },
            {
              path: ":courseContentId/forms",
              name: "single-course-analytics-contents-forms",
              component: () => import("./analytics/views/CourseFormContentUsersList.vue"),
            },
            {
              path: ":courseContentId/todolists",
              name: "single-course-analytics-contents-todolists",
              component: () => import("./analytics/views/CourseTodolistContentUsersList.vue"),
            },
            {
              path: ":courseContentId/todolists/:attemptId",
              name: "single-course-analytics-contents-todolists-attempt",
              component: () => import("./analytics/views/CourseTodolistContentAttempt.vue"),
            },
            {
              path: ":courseContentId/questions",
              name: "single-course-analytics-contents-questions",
              component: () => import("./analytics/views/CourseQuestionsContentList.vue"),
            },
            {
              path: ":courseContentId/wbt",
              name: "single-course-analytics-contents-wbt",
              component: () => import("./analytics/views/CourseWBTAnalytics.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/analytics/courses/:courseId/participants/reportings",
      name: "single-course-analytics-participants-reportings",
      component: () => import("./analytics/views/CourseParticipantsListReportings.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getEmailReportingsDetailPageRoutes("course-participant-list", "details")],
    },
    {
      path: "/analytics/courses",
      name: "courses-analytics",
      component: () => import("./analytics/views/CoursesAnalytics.vue"),
    },
    {
      path: "/analytics/courses/reportings",
      name: "courses-analytics-reportings",
      component: () => import("./analytics/views/CourseListReportings.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getEmailReportingsDetailPageRoutes("course-analytics-list", "details")],
    },
  ]
}
