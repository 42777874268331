<template>
  <div
    class="d-flex flex-column"
    :class="{
      'align-end': !left,
      'align-start': left,
    }"
  >
    <div class="text-grey-darken-3" :class="{ 'text-label2 mb-1': !big, 'text-h4': big }">{{ label }}</div>
    <router-link v-if="props.to" :to="props.to" class="text-body text-tu-lighten-1 text-decoration-none">
      {{ value }}
    </router-link>
    <div v-else class="text-body text-grey-darken-4">{{ value }}</div>
  </div>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from "vue-router"

const props = withDefaults(
  defineProps<{
    big?: boolean
    label: string
    left?: boolean
    to?: RouteLocationRaw
    value: string | number | null
  }>(),
  {
    big: false,
    left: false,
    to: undefined,
  },
)
</script>
