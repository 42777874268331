export function getQuestionDetailPageRoutes(currentModule: string, routePrefix: string) {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/question-outlet`,
      component: () => import("./components/QuestionDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:questionId/general`,
          name: `${currentModule}/question-general`,
          component: () => import("./views/QuestionEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "question",
          },
        },
      ],
    },
  ]
}

export function getQuestionsRoutes() {
  return [
    {
      path: "/questions",
      name: "questions",
      component: () => import("./views/QuestionList.vue"),
      children: getQuestionDetailPageRoutes("questions", "detail"),
      meta: {
        isDetailPageOutlet: true,
      },
    },
  ]
}
