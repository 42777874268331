// Styles
import "vuetify/styles"
import "@/styles/app.scss"
import "@/styles/overrides/index.scss"
import { aliases, fa } from "vuetify/iconsets/fa"

// Vuetify
import { createVuetify } from "vuetify"
import { de, en } from "vuetify/locale"

const keelearningTheme = {
  dark: false,
  colors: {
    primary: "#18B7CE", // KU Tu 2
    secondary: "#49495C", // KU Vio 3
    info: "#18B7CE", // KU Tu 2
    error: "#f95779",
  },
}
export default createVuetify({
  locale: {
    locale: "de",
    fallback: "de",
    messages: { de, en },
  },
  theme: {
    defaultTheme: "keelearningTheme",
    themes: {
      keelearningTheme,
    },
  },
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
})
