<template>
  <KButton v-bind="attributes" class="s-headerButton"><slot /></KButton>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import KButton from "./KButton.vue"

const KHeaderButtonProps = KButton.props

type KHeaderButtonPropsType = Partial<InstanceType<typeof KHeaderButtonProps>["$props"]>

export default defineComponent<KHeaderButtonPropsType>({
  props: KHeaderButtonProps,
  computed: {
    attributes() {
      const props: KHeaderButtonPropsType = {
        ...this.$props,
        ...this.$attrs,
        variant: "text",
        color: "grey-darken-3",
      }
      return props
    },
  },
})
</script>

<style lang="scss" scoped>
.s-headerButton:hover {
  color: $color-tu1 !important;

  :deep(.v-btn__overlay) {
    opacity: 0;
  }
}
</style>
