export function getAppointmentsDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/appointment-outlet`,
      component: () => import("./components/AppointmentDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:appointmentId/general`,
          name: `${currentModule}/appointment-general`,
          component: () => import("./views/AppointmentEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "appointments",
          },
        },
        {
          path: `:appointmentId/participation-management`,
          name: `${currentModule}/appointment-participation-management`,
          component: () => import("./views/AppointmentEditParticipationManagement.vue"),
          meta: {
            isDetailPage: true,
            module: "appointments",
          },
        },
      ],
    },
  ]
}
export function getAppointmentsRoutes() {
  return [
    {
      path: "/appointments",
      name: "appointments",
      component: () => import("./views/AppointmentList.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getAppointmentsDetailPageRoutes("appointments")],
    },
  ]
}
