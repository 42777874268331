<template>
  <div class="s-emptyArea">
    <div class="text-h2 text-grey-darken-3">{{ props.text ? props.text : $t("kTable.noResults") }}</div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text?: string
  }>(),
  {
    text: "",
  },
)
</script>

<style lang="scss" scoped>
.s-emptyArea {
  min-height: 128px;
  padding: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
