<template>
  <div class="c-icon-picker-container">
    <KTextField v-model="searchIcon" :disabled="disabled" name="icon" clearable :label="$t('global.searchIcon')" class="mb-4">
      <template #append-inner>
        <v-icon> far fa-search</v-icon>
      </template>
    </KTextField>
    <div class="c-icons-list">
      <button
        v-for="icon in filteredIcons"
        :key="icon.name"
        :disabled="disabled"
        :class="{ selected: selectedIcon.name === icon.name && selectedColor === '#FFFFFF', 'custom-selected': selectedIcon.name === icon.name && selectedColor !== '#FFFFFF' }"
        type="button"
        class="c-icon"
        @click="setSelectedIcon(icon)"
      >
        <v-icon>fa-1x far fa-{{ icon.name }}</v-icon>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup async>
import { computed, Ref, ref, watch } from "vue"
import { SharedIcons } from "@/logic/icons"
import { getContrastColor } from "@/logic/helpers"

const textColor = computed(() => {
  return getContrastColor(props.selectedColor)
})

const { getIcons } = await import("@/logic/icons")

type KIconPickerProps = {
  modelValue: string
  selectedColor?: string
  maxResult?: number
  name?: string
  disabled?: boolean
}
type KIcon = {
  name: string
  searchAttribute?: string
  translations: {
    de: string
    en: string
  }
}

const props = withDefaults(defineProps<KIconPickerProps>(), {
  selectedColor: "",
  name: "",
  maxResult: 15,
  disabled: false,
})
const emit = defineEmits(["update:modelValue"])

const chunks: Set<KIcon[]> = new Set()
const searchIcon = ref("")
/* eslint-disable-next-line vue/no-setup-props-destructure */
const maxResult: Ref<number> = ref(props.maxResult)
const filteredIcons: Ref<KIcon[]> = ref([])

const selectedIcon = computed<KIcon>({
  get() {
    if (!props.modelValue) return {} as KIcon
    const iconName = (props.modelValue as string).replace("far fa-", "")
    return getIcon(iconName)
  },
  set(value) {
    emit("update:modelValue", `far fa-${value.name}`)
  },
})

const getIcon = (name: string) => {
  let icon: KIcon | undefined
  chunks.forEach((items) => {
    if (typeof icon !== "undefined") {
      return
    }
    icon = items.find((icon) => {
      return icon.name === name
    })
  })
  if (typeof icon === "undefined") {
    return {} as KIcon
  }
  return icon
}

// load all icons and divide them into small chunks
const getAndPrepareIcons = (icons: SharedIcons) => {
  return new Promise((resolve, reject) => {
    try {
      const iconsArr = Object.entries(icons).map(([name, translations]) => {
        return { name: name, translations: translations, searchAttribute: (name + "|" + Object.values(translations).join("|")).toLowerCase() }
      })

      const chunkSize = 50
      for (let i = 0; i < iconsArr.length; i += chunkSize) {
        chunks.add(iconsArr.slice(i, i + chunkSize))
      }
      resolve(chunks)
    } catch (e) {
      reject(e)
    }
  })
}

// first load to show some icons to the user
const setPreselectedIcons = () => {
  filteredIcons.value = [...defaultIcons]
  if (selectedIcon.value && selectedIcon.value.name) {
    if (!filteredIcons.value.find((icon) => icon.name === selectedIcon.value.name)) {
      filteredIcons.value.pop()
      filteredIcons.value.unshift(selectedIcon.value)
    }
  }
}

const defaultIcons = [
  {
    name: "globe-europe",
    searchAttribute: "",
    translations: {
      en: "globe-europe",
      de: "globe-europe",
    },
  },
  {
    name: "flag",
    searchAttribute: "",
    translations: {
      en: "flag",
      de: "flag",
    },
  },
  {
    name: "star",
    searchAttribute: "",
    translations: {
      en: "star",
      de: "star",
    },
  },
  {
    name: "briefcase",
    searchAttribute: "",
    translations: {
      en: "briefcase",
      de: "briefcase",
    },
  },
  {
    name: "heart",
    searchAttribute: "",
    translations: {
      en: "heart",
      de: "heart",
    },
  },
  {
    name: "globe",
    searchAttribute: "",
    translations: {
      en: "globe",
      de: "globe",
    },
  },
  {
    name: "map-marked",
    searchAttribute: "",
    translations: {
      en: "map-marked",
      de: "map-marked",
    },
  },
  {
    name: "location",
    searchAttribute: "",
    translations: {
      en: "location",
      de: "location",
    },
  },
  {
    name: "map-marker-alt",
    searchAttribute: "",
    translations: {
      en: "map-marker-alt",
      de: "map-marker-alt",
    },
  },
  {
    name: "compass",
    searchAttribute: "",
    translations: {
      en: "compass",
      de: "compass",
    },
  },
  {
    name: "sack-dollar",
    searchAttribute: "",
    translations: {
      en: "sack-dollar",
      de: "sack-dollar",
    },
  },
  {
    name: "stars",
    searchAttribute: "",
    translations: {
      en: "stars",
      de: "stars",
    },
  },
  {
    name: "grin",
    searchAttribute: "",
    translations: {
      en: "grin",
      de: "grin",
    },
  },
  {
    name: "grin-stars",
    searchAttribute: "",
    translations: {
      en: "grin-stars",
      de: "grin-stars",
    },
  },
  {
    name: "grin-hearts",
    searchAttribute: "",
    translations: {
      en: "grin-hearts",
      de: "grin-hearts",
    },
  },
]

const setSelectedIcon = (icon: KIcon) => {
  if (props.disabled) {
    return
  }
  selectedIcon.value = icon
}

watch(searchIcon, function (newValue) {
  if (!newValue) {
    setPreselectedIcons()
    return
  }
  filteredIcons.value = []
  maxResult.value = 30
  chunks.forEach((items) => {
    if (filteredIcons.value.length >= maxResult.value) {
      filteredIcons.value.length = maxResult.value
      return
    }
    const filteredItems = items.filter((icon) => {
      return icon.searchAttribute?.includes(newValue.toLowerCase())
    })
    filteredIcons.value = [...filteredIcons.value, ...filteredItems]
  })
})

const icons = getIcons()
getAndPrepareIcons(icons).then(() => setPreselectedIcons())
</script>

<style scoped lang="scss">
.c-icon-picker-container {
  width: 212px;

  .c-icons-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(0px, 1fr));
    gap: 8px;

    .c-icon {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background-color: $color-grey3;
      color: #fff;
      display: grid;
      place-content: center;
      transition: 0.15s background-color ease-in-out;

      &:hover {
        background-color: $color-grey4;
      }

      &.selected {
        background-color: $color-tu2;
        color: v-bind(textColor);
      }

      &.custom-selected {
        background-color: v-bind(selectedColor);
        color: v-bind(textColor);
      }
    }
  }
}
</style>
