import { api } from "@/logic/api"
import { useAccountStore } from "@/modules/account/store"
import { useLocalStorage } from "@vueuse/core"
import { useI18n } from "vue-i18n"
import { useLocale } from "vuetify"
import { useAppConfigStore } from "@/store/appConfig"

export const availableLanguages = {
  de: "Deutsch",
  en: "English",
  fr: "Français (bêta)",
}

export function getAvailableLanguagesForApp(appConfigStore: ReturnType<typeof useAppConfigStore>) {
  const languages = availableLanguages
  Object.keys(availableLanguages).forEach((lang) => {
    if (lang === "de") {
      return
    }
    if (!appConfigStore.settings.languages.includes(lang)) {
      delete languages[lang as keyof typeof availableLanguages]
    }
  })
  return languages
}

type KLI18n = ReturnType<typeof useI18n<any, keyof typeof availableLanguages>>
let i18n: KLI18n | null = null

const defaultLanguage = "de"
const selectedLanguage = useLocalStorage<keyof typeof availableLanguages>("selectedLanguage", defaultLanguage, {
  listenToStorageChanges: false,
})

export async function setSelectedLanguage(language: keyof typeof availableLanguages): Promise<void> {
  selectedLanguage.value = language
  const accountStore = useAccountStore()
  if (accountStore.isLoggedIn) {
    await api.post("/account/set-language", {
      language,
    })
    window.location.reload()
  }
}

export function getSelectedLanguage() {
  return selectedLanguage
}

export function setI18n(newI18n: KLI18n) {
  i18n = newI18n
  const { current } = useLocale()
  current.value = selectedLanguage.value
}

export function getI18n() {
  if (!i18n) {
    console.error("i18n was not setup yet")
  }
  return i18n!
}
