export function getTagsDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/outlet`,
      component: () => import(/* webpackChunkName: "tag-details-wrapper" */ "./components/TagsDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:tagsId/general`,
          name: `${currentModule}/tags-general`,
          component: () => import(/* webpackChunkName: "tags-general" */ "./views/TagsEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "tags",
          },
        },
        {
          path: `:tagsId/usage`,
          name: `${currentModule}/tags-usage`,
          component: () => import(/* webpackChunkName: "tags-design" */ "./views/TagsUsage.vue"),
          meta: {
            isDetailPage: true,
            module: "tags",
          },
        },
      ],
    },
  ]
}

export function getTagGroupDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/outlet`,
      component: () => import(/* webpackChunkName: "tag-group-details-wrapper" */ "./components/tag-groups/TagGroupDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:tagGroupId/general`,
          name: `${currentModule}/tag-group-general`,
          component: () => import(/* webpackChunkName: "tag-group-general" */ "./views/tag-groups/TagGroupEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "tags",
          },
        },
      ],
    },
  ]
}

export function getTagsRoutes() {
  return [
    {
      path: "/tags",
      name: "tags",
      component: () => import(/* webpackChunkName: tag-groups */ "./views/tag-groups/TagGroups.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getTagGroupDetailPageRoutes("tag-groups", "group"), ...getTagsDetailPageRoutes("tags", "details")],
    },
  ]
}
