<template>
  <div class="s-pagination">
    <div class="s-pagination__entry">
      <div class="text-label2">{{ $t("pagination.page") }}</div>
      <KSelect v-model="store.pagination.page" name="page" class="s-pagination__select" :items="pageItems" />
    </div>
    <div class="s-pagination__entry">
      <div class="text-label2 text-grey-darken-3">{{ $t("pagination.entriesPerPage") }}</div>
      <KSelect v-model="store.pagination.perPage" name="perPage" class="s-pagination__select" :items="[10, 20, 50, 100]" />
    </div>
    <div class="s-pagination__entry text-body text-grey-darken-3">
      <div>
        <span class="text-monospace">{{ firstEntryIndex }}</span>
        -
        <span class="text-monospace">{{ lastEntryIndex }}</span>
        {{ $t("pagination.of") }}
        <span class="text-monospace">{{ store.pagination.totalCount }}</span>
      </div>
    </div>
    <div class="s-pagination__entry">
      <k-button icon variant="plain" :disabled="store.pagination.page <= 1" @click="prevPage"><v-icon>far fa-chevron-left</v-icon></k-button>
      <k-button icon variant="plain" :disabled="store.pagination.page >= totalPages" @click="nextPage"><v-icon>far fa-chevron-right</v-icon></k-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { EntryListStore } from "@/store/factories/entryListStore"
import { computed, toRef } from "vue"

const props = defineProps<{
  store: EntryListStore
}>()

const store = toRef(props, "store")

const firstEntryIndex = computed(() => {
  return (store.value.pagination.page - 1) * store.value.pagination.perPage + 1
})

const lastEntryIndex = computed(() => {
  return Math.min(store.value.pagination.totalCount, store.value.pagination.page * store.value.pagination.perPage)
})

const totalPages = computed(() => {
  return Math.max(1, Math.ceil(store.value.pagination.totalCount / store.value.pagination.perPage))
})

const pageItems = computed(() => {
  return Array.from(Array(totalPages.value).keys()).map((i) => i + 1)
})

const nextPage = () => {
  store.value.pagination.page = Math.min(totalPages.value, store.value.pagination.page + 1)
}

const prevPage = () => {
  store.value.pagination.page = Math.max(1, store.value.pagination.page - 1)
}
</script>

<style lang="scss" scoped>
.s-pagination {
  display: flex;
  padding: 12px;
  gap: 32px;
  justify-content: flex-end;
}

.s-pagination__entry {
  display: flex;
  align-items: center;
  gap: 24px;
}

.s-pagination__select {
  width: 90px;
}
</style>
