<template>
  <v-radio-group v-bind="attributes" @update:model-value="$emit('update:modelValue', $event)"><slot /></v-radio-group>
  <k-input-error v-if="$props.errors" :name="$props.name" :errors="$props.errors" :label="$props.label" />
  <k-input-error v-if="detailStore" :name="$props.name" :errors="detailStore.errors" :label="$props.label" />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue"
import { VRadioGroup } from "vuetify/components"

import { DetailPageStore } from "@/store/factories/detailPageStore"

type KRadioGroupPropsType = Partial<InstanceType<typeof VRadioGroup>["$props"]>

const KRadioGroupProps = VRadioGroup.props
KRadioGroupProps.errors = {
  required: false,
  default: {},
}
KRadioGroupProps.hideDetails = {
  required: false,
  default: true,
}

export default defineComponent<Partial<typeof KRadioGroupProps>>({
  props: KRadioGroupProps,
  emits: ["update:modelValue"],
  setup() {
    return {
      detailStore: inject("detailStore", null) as DetailPageStore | null,
    }
  },
  computed: {
    attributes() {
      const props: KRadioGroupPropsType = {
        ...this.$props,
        ...this.$attrs,
        trueIcon: "fas fa-dot-circle",
      }
      return props
    },
  },
})
</script>
