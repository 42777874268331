export function getImportRoutes() {
  return [
    {
      path: "/import",
      name: "import",
      component: () => import(/* webpackChunkName: import-old-backend */ "./components/ImportOld.vue"),
    },
    {
      path: "/import/users",
      name: "import-users",
      component: () => import(/* webpackChunkName: import-users-old-backend */ "./components/UsersImportOld.vue"),
    },
    {
      path: "/import/delete-users",
      name: "import-delete-users",
      component: () => import(/* webpackChunkName: import-delete-users-old-backend */ "./components/DeleteUsersImportOld.vue"),
    },
    {
      path: "/import/cards",
      name: "import-cards",
      component: () => import(/* webpackChunkName: import-cards-old-backend */ "./components/IndexcardsImportOld.vue"),
    },
    {
      path: "/import/questions",
      name: "import-questions",
      component: () => import(/* webpackChunkName: import-questions-old-backend */ "./components/QuestionsImportOld.vue"),
    },
  ]
}
