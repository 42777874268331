import { acceptHMRUpdate, defineStore } from "pinia"
import { MaybeRef } from "@vueuse/core"
import { api } from "@/logic/api"
import { useAppConfigStore } from "@/store/appConfig"
import { useAppProfileConfigStore } from "@/store/appProfileConfig"
import * as Sentry from "@sentry/vue"
import { getI18n, getSelectedLanguage } from "@/logic/i18n"
import { SharedAppProfileSettings } from "../../../types/appProfileSettings"
import { SharedUserRoleRightKeys } from "../../../types/userRoleRightKeys"
import { jwtDecode } from "jwt-decode"
import { removeHubspot } from "@/logic/hubspot"

export type UserRights = {
  [K in SharedUserRoleRightKeys[keyof SharedUserRoleRightKeys]]: boolean
}

export const useAccountStore = defineStore("account", {
  persist: true,
  state: () => {
    return {
      id: null as number | null,
      isLoggedIn: false as boolean,
      isMainAdmin: false as boolean,
      isSuperAdmin: false as boolean,
      username: "",
      email: "",
      avatar_url: "",
      app: {
        id: null as number | null,
        name: "",
      },
      rights: {} as UserRights,
      isRestrictedAdmin: false as boolean,
      allowedTags: [] as number[],
      hubspotVisitorToken: "",
    }
  },
  actions: {
    async fetchRights() {
      const response = await api.get("/account/rights")
      this.isMainAdmin = response.isMainAdmin
      this.isSuperAdmin = response.isSuperAdmin
      this.rights = response.rights
      this.allowedTags = response.allowedTags
      this.isRestrictedAdmin = !!response.allowedTags.length
    },
    async fetchHubspotVisitorToken() {
      const response = await api.get("/account/hubspot-visitor-token")
      this.hubspotVisitorToken = response.token
    },
    getAppIdsForEmail({ email }: { email: MaybeRef<string> }) {
      return api.post("/account/apps", {
        email,
      })
    },
    async login({ appId, email, password }: { appId: number; email: string; password: string }) {
      const appConfigStore = useAppConfigStore()
      const appProfileConfigStore = useAppProfileConfigStore()
      const response = await api.post("/account/login", {
        appId,
        email,
        password,
      })
      Sentry.setUser({ id: this.id?.toString() })
      this.isLoggedIn = true
      this.id = response.data.id
      this.username = response.data.username
      this.email = response.data.email
      this.avatar_url = response.data.avatar_url
      this.app = response.data.app
      this.isSuperAdmin = false
      appConfigStore.appId = appId
      await this.fetchRights()

      await appConfigStore.fetchSettings()
      await appProfileConfigStore.fetchSettings(appId)
      const i18n = getI18n()
      if (i18n.availableLocales.includes(response.data.ui_language)) {
        i18n.locale.value = response.data.ui_language
        getSelectedLanguage().value = response.data.ui_language
      }

      await this.fetchHubspotVisitorToken()

      return response.data
    },
    async logout() {
      this.isLoggedIn = false
      this.id = null
      this.isMainAdmin = false
      this.isSuperAdmin = false
      this.username = ""
      this.email = ""
      this.avatar_url = ""
      this.app = {
        id: null,
        name: "",
      }
      this.rights = {} as unknown as UserRights
      const appConfigStore = useAppConfigStore()
      const appProfileConfigStore = useAppProfileConfigStore()
      appConfigStore.appId = null
      appConfigStore.support_phone_number = null
      appConfigStore.settings = {}
      appProfileConfigStore.settings = {} as SharedAppProfileSettings
      appProfileConfigStore.appProfileId = 0
      Sentry.setUser(null)
      await api.post("/account/logout")

      this.hubspotVisitorToken = ""
      removeHubspot()
    },
    resetPassword({ expires, signature, userId }: { expires: string; signature: string; userId: string }) {
      return api.post(`/account/reset-password/${userId}?expires=${expires}&signature=${signature}`)
    },
    sendResetPasswordLink({ appId, email }: { appId: number; email: string }) {
      return api.post("/account/forgot-password", {
        appId,
        email,
      })
    },
    async getHubspotVisitorToken() {
      if (!this.hubspotVisitorToken) {
        await this.fetchHubspotVisitorToken()
        return this.hubspotVisitorToken
      }
      try {
        const decodedToken = jwtDecode(this.hubspotVisitorToken)

        if (!decodedToken.exp) {
          throw new Error("The expiration of JWT is invalid!")
        }

        const now = new Date()
        if (decodedToken.exp * 1000 < now.getTime()) {
          await this.fetchHubspotVisitorToken()
        }
      } catch (error) {
        await this.fetchHubspotVisitorToken()
      }
      return this.hubspotVisitorToken
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot))
}
