<template>
  <div
    class="s-panel"
    :class="{
      '-noCollapsing': hideCollapsing,
      'pt-2': !title && !description,
    }"
  >
    <div v-if="title || description" class="s-panel__header" @click="expand">
      <div>
        <div v-if="title" class="text-h2 text-vio">
          {{ title }}
        </div>
        <p v-if="description" class="s-panel__description text-label1">
          {{ description }}
        </p>
      </div>
      <v-icon v-if="!hideCollapsing" size="14" class="mr-3 s-panel__chevron">far {{ isExpanded ? "fa-chevron-down" : "fa-chevron-down fa-rotate-180" }}</v-icon>
    </div>
    <div v-if="isExpanded" class="s-panel__content">
      <slot></slot>
    </div>
    <div v-if="!hideDivider" class="s-panel__divider"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue"

const props = withDefaults(
  defineProps<{
    title?: string
    description?: string
    expandedByDefault?: boolean
    hideDivider?: boolean
    hideCollapsing?: boolean
  }>(),
  {
    title: "",
    description: "",
    expandedByDefault: true,
    hideDivider: false,
    hideCollapsing: false,
  },
)
const isExpanded = ref(props.expandedByDefault)

watch(
  () => props.expandedByDefault,
  (expandedByDefault) => {
    isExpanded.value = expandedByDefault
  },
)

const expand = () => {
  if (props.hideCollapsing) {
    return
  }
  isExpanded.value = !isExpanded.value
}
</script>

<style lang="scss" scoped>
.s-panel {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.s-panel__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;

  .s-panel.-noCollapsing & {
    cursor: unset;
  }
}

.s-panel__chevron {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.s-panel__description {
  color: $color-grey3;
}

.s-panel__content {
  margin-bottom: 8px;
}

.s-panel__divider {
  height: 1px;
  background: $color-grey2;
  margin-bottom: 8px;
}
</style>
