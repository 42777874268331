{
  appointments: {
    appointmentDeleting: {
      confirmation: "Voulez-vous vraiment placer cet événement « {appointmentName} » dans la corbeille ?",
      deletedSuccessfully: "L’événement a bien été placé dans la corbeille.",
      title: "Supprimer un événement",
    },
    appointmentDetailsPage: {
      access: "Accès",
      alerts: {
        isBinned: "Cet événement se trouve dans la corbeille.",
        isCancelled: "Cet événement est supprimé.",
      },
      availableOn: "Date de début",
      createdAt: "Créé le",
      createdSuccessfully: "Création de l’événement réussie",
      date: "Date",
      dateHint: "Format : jj.mm.aaaa",
      deleteAppointment: "Supprimer un événement",
      description: "Description",
      enableParticipationManagement: "Activer la gestion des participations",
      from: "À partir de",
      limitNumberOfParticipants: "Limiter le nombre de participants",
      location: "Lieu / URL de la réunion",
      maxNumberOfParticipants: "Nombre max. de participants",
      name: "Nom",
      notificationNoResult: "Aucun utilisateur n’a reçu de notification pour cet événement pour l’instant.",
      notifyAdminAboutChangingUserCount: "Notifier les principaux administrateurs lorsque des utilisateurs s’inscrivent / se désinscrivent",
      pastNotifications: "Notifications passées",
      publishAppointment: "Publier l’événement",
      publishedOn: "Publié le",
      registeredOn: "Inscrit le",
      reminder: "Rappel",
      saveChanges: "Enregistrer les modifications",
      search: "Rechercher",
      sections: {
        appointment: {
          title: "Événement",
        },
        details: {
          title: "Détails",
        },
        participationManagement: {
          description: "Autorisez les utilisateurs à s’inscrire à cet événement et limitez le nombre de participants.",
          title: "Gestion des participations",
        },
        publishing: {
          description: "Définissez si l’événement doit être publié immédiatement ou ultérieurement",
          title: "Publication",
        },
        reminderAndNotification: {
          description: "Envoi d’une notification lorsque l’événement est publié et lorsque l’événement commence.",
          title: "Rappel & notification",
        },
      },
      sendNotification: "Envoyer une notification lors de la publication le {publishedAt}",
      sendNotifications: {
        allUsers: {
          button: "Notifier l’utilisateur | Notifier tous les {count} utilisateurs",
          text: "Aucun utilisateur n’a accès à cet événement et ne souhaite être notifié pour l’instant. | Envoyer une notification à l’utilisateur qui dispose actuellement de l’accès à cet événement et souhaite être notifié. | Envoyer une notification à tous les {count} utilisateurs qui disposent actuellement de l’accès à cet événement et qui souhaitent être notifiés.",
          title: "Tous les utilisateurs",
        },
        header: "Envoyer une notification",
        onlyNewUsers: {
          button: "Notifier le nouvel utilisateur | Notifier les {count} nouveaux utilisateurs",
          text: "Tous les utilisateurs disposant actuellement de l’accès à cet événement ont déjà reçu une notification ou ne souhaitent pas être notifiés. | Envoyer une notification à l’utilisateur qui n’a pas encore reçu de notification pour cet événement alors qu’il souhaite être notifié. | Envoyer une notification aux {count} utilisateurs qui n’ont pas encore reçu de notification pour cet événement alors qu’ils souhaitent être notifiés.",
          title: "Nouveaux utilisateurs uniquement",
        },
        sentSuccessFully: "Les notifications vont être envoyées.",
      },
      shouldBePublishedOnSpecificDate: "Doit être publié à une date spécifique",
      subPages: {
        general: "Général",
        participationManagement: "Gestion des participations ({count})",
      },
      timeBeforeAppointmentStarts: "Délai avant l’événement",
      timeHint: "Format : hh:mm",
      type: "Type",
      unit: "Unité",
      until: "Jusqu’à",
      updatedAt: "Mis à jour le",
      username: "Nom d’utilisateur",
      users: "Utilisateurs",
    },
    appointmentList: {
      description: "Créez et gérez les événements en ligne et en présentiel.",
      filter: "Filtrer",
      filterLabels: {
        active: "Visible / en cours",
        activeWithoutParticipants: "Visible / sans participant",
        completed: "Terminé",
        name: "Nom / ID / date",
        withoutParticipants: "Sans participant",
      },
      table: {
        appointment: "Événement",
        name: "Nom",
        registered: "Inscrit",
        state: "Statut",
        tags: "Tags",
        type: "Type",
        users: "Utilisateurs",
      },
      title: "Événements",
    },
    create: "Créer un événement",
    reminderTimeUnits: {
      days: "Jours",
      hours: "Heures",
      minutes: "Minutes",
    },
    types: {
      "1": "Réunion en ligne",
      "2": "Réunion en présentiel",
    },
  },
  binList: {
    buttonLabels: {
      deleteItemsWithConnectedContent: "Supprimer les éléments",
      deleteSelected: "Supprimer la sélection",
      emptyBin: "Vider la corbeille",
      restoreItems: "Restaurer l’élément|Restaurer les éléments",
      restoreSelected: "Restaurer la sélection",
    },
    confirmToDeleteAllItems: "Voulez-vous vraiment supprimer l’élément et tous ses contenus associés ?<br>Cela supprimera : | Voulez-vous vraiment supprimer tous les {count} éléments et tous leurs contenus associés ?<br>Cela supprimera :",
    confirmToDeleteSelectedItems: "Voulez-vous vraiment supprimer l’élément sélectionné et tous ses contenus associés ?<br>Cela supprimera : | Voulez-vous vraiment supprimer les {count} éléments sélectionnés et tous leurs contenus associés ?<br>Cela supprimera :",
    confirmToRestoreSelectedItems: "Voulez-vous vraiment restaurer l’élément sélectionné ?|Voulez-vous vraiment restaurer les {count} éléments sélectionnés ?",
    modalTitles: {
      deleteItems: "Supprimer les éléments",
      emptyBin: "Vider la corbeille",
      restoreItems: "Restaurer les éléments",
    },
    moduleDescription: "Supprimez définitivement ou restaurez vos tags, formulaires, événements et contenus multimédias supprimés.",
    moduleTitle: "Corbeille",
    restoreFailed: "Impossible de restaurer les éléments. Veuillez réessayer plus tard.",
    restoreSuccessful: "L’élément a bien été restauré|Les éléments ont bien été restaurés.",
    tags: "Tags",
    title: "Titre",
    type: "Type",
    typeSelectLabel: "Types",
  },
  categories: {
    categorySelectLabelPlural: "Catégories",
    categorySelectLabelSingular: "Catégorie",
  },
  contentCategories: {
    addCategory: "Ajouter une catégorie",
    categoryHasRelatedCourses: "1 cours apparenté | {count} cours apparentés",
    categoryHasRelatedForms: "1 formulaire apparenté | {count} formulaires apparentés",
    categoryHasRelatedKeywords: "1 mot-clé apparenté | {count} mots-clés apparentés",
    categoryHasRelatedTags: "1 tag apparenté | {count} tags apparentés",
    contentCategoryCreatedSuccessfully: "Création de la catégorie réussie",
    contentCategoryPutInBinSuccessfully: "La catégorie a été placée dans la corbeille",
    contentCategorySelect: {
      add: "Ajouter",
      defaultLabel: "Catégorie",
      nameLabel: "Nom de la nouvelle catégorie",
      newCategory: "Nouvelle catégorie",
    },
    contentCategoryUpdatedSuccessfully: "Mise à jour de la catégorie réussie",
    createCategory: "Créer une catégorie",
    deleteCategoryConfirmation: "Voulez-vous vraiment placer cette catégorie dans la corbeille ?",
    deleteCategoryTitle: "Supprimer la catégorie",
    general: "Général",
    name: "Nom",
    newCategory: "Nouvelle catégorie",
    saveChanges: "Enregistrer les modifications",
    types: {
      "1300": "Mots-clés",
      "19": "Formulaires",
      "3000": "Tags",
      "5": "Cours",
    },
  },
  course: {
    chapter_deleted_successfully: "Le chapitre a bien été supprimé.",
    content_deleted_successfully: "Le contenu a bien été supprimé.",
    contents: {
      completion_time: "⌀ Temps d’exécution",
      completion_time_tooltip: "Temps moyen nécessaire aux utilisateurs pour terminer ce contenu.",
      forms: {
        all: "Tous",
        completion_duration: "Temps d’exécution",
        download: "Téléchargement",
        filters: "Filtres",
        last_worked_on: "Modifié le",
        not_submitted: "Non soumis",
        search: "Rechercher",
        star_answer: "1 Étoile|{count} Étoiles",
        started_at: "Commencé le",
        submitted: "Soumis",
        user: "Utilisateur",
      },
      passed: "Réussi",
      passed_tooltip: "Nombre d’utilisateurs ayant réussi ce contenu.",
      questions: {
        all: "Tous",
        completion_time: "Temps d’exécution",
        download: "Téléchargement",
        failed: "Échoué",
        filters: "Filtres",
        is_correct: "Correct ?",
        last_worked_on: "Modifié le",
        passed: "Réussi",
        percentage: "Pourcentage",
        remind_users: "Rappel aux participants",
        search: "Rechercher",
        started_at: "Commencé le",
        status: "Statut",
        tries: "Tentatives",
        unfinished: "Inachevé",
        user: "Utilisateur",
      },
      remind_users: "Rappel aux participants",
      score: "⌀ Score",
      score_tooltip: "Pour les tests : pourcentage moyen de questions auxquelles les utilisateurs ont répondu correctement.",
      search: "Rechercher",
      show_contents_with_statistics: "Afficher uniquement le contenu avec des statistiques",
      time_spent_here: "⌀ Temps passé ici",
      title: "Titre",
      todolists: {
        passed: "Terminé",
        started: "Commencé",
        user: "Utilisateur",
      },
      tries: "⌀ Tentatives",
      tries_tooltip: "Pour les tests : nombre de tentatives moyen nécessaire aux utilisateurs pour réussir ce test.",
      type: "Type",
    },
    participants: {
      active: "Actif",
      all: "Tous les participants + utilisateurs avec accès",
      archived: "Archivé",
      certificate_n: "Certificat {n}",
      certificates: "Certificats",
      completion_time: "Temps d’exécution",
      completion_time_tooltip: "Temps écoulé entre le début et la fin du cours.",
      download: "Téléchargement",
      download_column: "Téléchargement",
      download_column_tooltip: "Si l’utilisateur a reçu des certificats dans le cadre du cours, ils peuvent être téléchargés ici.",
      expired: "Expiré",
      failed: "Participants ayant échoué uniquement",
      filters: "Filtres",
      finished: "Participants ayant terminé uniquement",
      includeInactiveUsers: "Inclure les utilisateurs inactifs",
      last_worked_on: "Modifié le",
      last_worked_on_tooltip: "Date à laquelle l’utilisateur a ouvert le cours pour la dernière fois.",
      mark_participation_as_passed: "Marquer le cours comme réussi pour cet utilisateur",
      participating: "Participants uniquement",
      progress: "Progression",
      remind_users: "Rappel aux participants",
      remind_users_successfully: "Le rappel a bien été envoyé à l’utilisateur sélectionné.|Les rappels ont bien été envoyés aux utilisateurs sélectionnés.",
      reset_participation_status: "Réinitialiser le statut du cours et ne pas prendre en compte le dernier échec au test",
      search: "Rechercher",
      showProgress: "Afficher la progression",
      started_at: "Commencé le",
      started_at_tooltip: "Date à laquelle l’utilisateur a commencé le cours.",
      status: "Statut",
      status_tooltip: "Le statut actuel du cours de cet utilisateur.",
      statuses: {
        failed: "Échoué",
        notPassedAt: "Échoué le {0}",
        notPassedYet: "Pas encore réussi",
        notStarted: "Non commencé",
        passed: "Réussi",
        passed_at: "Réussi le {0}",
        started: "Commencé",
      },
      unfinished_participants: "Participants n’ayant pas terminé uniquement",
      unfinished_users: "Tous les utilisateurs n’ayant pas terminé",
      user: "Utilisateur",
      usertags: "Tags utilisateurs",
    },
    removers: {
      chapter: {
        confirmPermanentDeletion: "Voulez-vous supprimer définitivement ce chapitre ?",
        contents: "1 contenu de cours|{count} contenus de cours",
        deleteChapter: "Supprimer le chapitre",
        lastChapterCantBeDeleted: "Il est impossible de supprimer le dernier chapitre d’un cours.",
      },
      content: {
        certificates: "1 certificat récompensé|{count} certificats récompensés",
        confirmPermanentDeletion: "Voulez-vous supprimer définitivement ce contenu ?",
        deleteContent: "Supprimer le contenu",
      },
    },
    searchLabel: "Nom du cours",
    settings: {
      access: "Accès",
      accessManagement: "Gestion des accès",
      accessTypeOptions: {
        tagAssignment: "Attribution d’un tag",
        userAssignment: "Attribution d’un utilisateur",
      },
      active: "Actifs",
      addNotification: "Ajouter une notification",
      additionalRecipients: "Destinataires supplémentaires (séparés par des virgules)",
      additionalRecipientsHint: "E-mails séparés par des virgules",
      availableFrom: "Début du cours",
      availableUntil: "Fin du cours",
      awardTags: "Attribuer ces tags une fois le cours réussi",
      dateHint: "Format : jj.mm.aaaa",
      daysBeforeCourseEnds: "Jours avant la fin du cours",
      description: "Ajouter une description pour ce cours",
      duration: "Délai",
      durationDynamicText: "Configurez le délai dont dispose les utilisateurs après avoir commencé le cours.",
      durationOptions: {
        dynamic: "Délai basé sur le début de la participation",
        fixed: "Date de début et de fin",
      },
      enablePreview: "Activer l’aperçu des cours",
      enablePreviewText: "Autorisez les utilisateurs sans accès au cours à soumettre une demande d’accès.",
      endDateAlert: "Si vous ajoutez une date de fin au cours, vous pouvez définir les notifications à envoyer aux utilisateurs et aux administrateurs ici.",
      escalationManagement: "Gestion des escalades",
      finished: "Terminé",
      firstCourseCreation: "Première création de cours",
      howLongText: "Configurez le nombre de fois que le cours doit être créé et le temps dont dispose les utilisateurs pour terminer chaque cours.",
      howOftenText: "Configurez la fréquence de création d’un cours à partir de ce modèle.",
      inactive: "Inactifs",
      intervalTypeOptions: {
        monthly: "mois",
        weekly: "semaines",
      },
      intervalTypeOptionsInline: {
        monthly: "mois|mois",
        weekly: "semaine|semaines",
      },
      isMandatory: "Cours obligatoire",
      managers: "Responsables",
      name: "Nom du cours",
      notifyManagers: "Notifier les responsables du cours et les personnes externes avant la fin du cours",
      notifyUsers: "Notifier les utilisateurs avant la fin du cours",
      participationDuration: "Temps d’exécution",
      participationDurationType: {
        days: "Jours",
        months: "Mois",
        weeks: "Semaines",
      },
      passed: "Réussi",
      reminderTerm: "{count} jour avant | {count} jours avant",
      repeatedCourseNameAlert: "Les cours créés à partir de ce modèle s’intituleront « {name} (semaine / année actuelle) ».",
      repeating: "Type de modèle",
      repeatingTypeOptions: {
        nonRepeating: "Non répétitif",
        repeating: "Répétitif",
      },
      repetitionAlert: {
        availableNewCourse: "Chaque nouveau cours sera disponible pendant {participationDuration} {participationDurationType}.",
        latestCourseCreatedAt: "Le dernier cours a été créé le {date}.",
        learnMore: "En savoir plus",
        limitedRepetitionText: "Par la suite, {restRepetitionCount} cours supplémentaires seront créés toutes / tous les {repetitionInterval} {repetitionIntervalType}.",
        nextRepetitionCourseDate: "Le prochain cours sera créé le {date}.",
        repetitionCountAchievedText: "Ce cours a déjà été créé une fois. Aucun nouveau cours ne sera donc créé automatiquement. | Ce cours a déjà été créé {count} fois. Plus aucun nouveau cours ne sera donc créé automatiquement.",
        unlimitedRepetitionText: "Par la suite, un nouveau cours sera créé toutes / tous les {repetitionInterval} {repetitionIntervalType}.",
      },
      repetitionCount: "Nombre de répétitions",
      repetitionInterval: "Répéter toutes / tous les",
      requestAccessLink: "URL de redirection",
      retractTags: "Supprimer ces tags une fois le cours réussi",
      sections: {
        CourseRepetition: {
          title: "Création automatique de cours réguliers",
        },
        access: {
          title: "Accès",
        },
        actionsAfterPassing: {
          description: "Configurez des mises à jour automatiques des tags lorsqu’un utilisateur a réussi ce cours.",
          title: "Actions après avoir réussi le cours",
        },
        description: {
          title: "Description",
        },
        duration: {
          title: "Durée du cours",
        },
        escalationManagement: {
          title: "Gestion des escalades",
        },
        general: {
          title: "Général",
        },
        managers: {
          description: "Sélectionner les responsables du cours qui recevront des notifications concernant l’activité du cours.",
          title: "Responsables des cours",
        },
        notifications: {
          description: "Configurez des notifications automatiques lorsque certains événements surviennent.",
          title: "Notifications",
        },
        templateInheritance: {
          description: "Mettre ce modèle à disposition pour d’autres applications.",
          title: "Héritage des modèles",
        },
      },
      sendNewCourseNotification: "Informer les utilisateurs une fois le cours commencé",
      sendNewCourseNotificationTooltip: "Ce paramètre ne peut être modifié que pour les cours avec une date de début à venir.",
      sendPassedCourseMail: "Envoyer un e-mail de confirmation aux utilisateurs lorsqu’ils ont réussi le cours",
      sendRepetitionCourseReminder: "Envoyer un rappel aux responsables du cours avant de créer un cours à partir de ce modèle",
      showRequestAccessLink: "Personnaliser l’URL pour les demandes d’accès",
      showRequestAccessLinkText: "Au lieu d’informer les responsables du cours au sujet des demandes d’accès, redirigez les utilisateurs vers une URL spécifique lorsqu’ils demandent un accès.",
      templateInheritance: {
        courseIsNotAvailable: "Ce cours n’est pas disponible en {language}",
      },
      templateName: "Nom du modèle",
      timeLimit: "Délai",
      timeLimitType: {
        months: "Mois",
        weeks: "Semaines",
      },
      timeLimitTypeInline: {
        months: "mois|mois",
        weeks: "semaine|semaines",
      },
      timeUnit: "Unité de temps",
      unlimited: "Illimité",
      users: "Utilisateurs",
    },
    wbtResults: {
      duration: "Temps passé selon la formation en ligne",
      durationInMinutes: "Temps passé selon la formation en ligne (en minutes)",
      last_worked_on: "Modifié le",
      status: "Statut",
      user: "Utilisateur",
    },
  },
  courseTemplates: {
    appCategories: "Nos catégories d’application",
    appTemplates: "Notre modèle d’application",
    breadcrumbs: {
      courseTemplates: "Modèles de cours",
      courses: "Cours",
    },
    featured: "Présenté",
    filters: {
      active: "Actif",
      all: "Tous",
      archived: "Archivé",
      inactive: "Inactif",
      isNotRepeating: "Non répétitif",
      isRepeating: "Répétitif",
    },
    inheritedAppTemplates: "Modèles {appName}",
    inheritedTemplateCategories: "Catégories {appName}",
    keeunitCategories: "Catégories keeunit",
    keeunitTemplates: "Modèles keeunit",
    lessons: "Leçons",
    loadCourseTemplate: "Charger un cours",
    loadKeelearningCourseTemplate: "Charger un modèle keelearning",
    loadOwnTemplate: "Charger un modèle personnel",
    loadTemplate: "Charger un modèle",
    preview: "Aperçu",
    startWithEmptyCourse: "Cours vide",
    templates: "1 modèle | {count} modèles",
    types: {
      appTemplate: "Modèle personnel",
      course: "Cours",
      template: "modèle",
    },
  },
  courses: {
    add_course: "Ajouter un cours",
    bin: {
      relatedItems: {
        certificates: "1 Certificat | {count} Certificats",
        commentReports: "1 Rapport de commentaire | {count} Rapports de commentaire",
        comments: "1 Commentaire | {count} Commentaires",
        contents: "1 Contenu | {count} Contenus",
        participations: "1 Participation | {count} Participations",
        tests: "1 Test | {count} Tests",
      },
    },
    breadcrumbs: {
      contents: "Contenus",
      settings: "Paramètres",
    },
    categories: "Catégories",
    category: "Catégorie",
    contentCategories: {
      description: "Créez, traduisez et gérez vos catégories de cours ici",
      title: "Catégories de cours",
    },
    contentTemplates: {
      description: "Créez et gérez vos modèles de cours ici",
      title: "Modèles de cours",
    },
    contents: {
      addTagRestriction: "Ajouter une restriction de tag",
      certificate: {
        name: "Nom du certificat",
        title: "Certificat",
      },
      enforceFileUpload: "Exiger le téléchargement de fichiers",
      enforceFileUploadHint: "Les utilisateurs doivent télécharger au moins un fichier pour terminer cette liste de tâches",
      title: "Titre du contenu",
      todoList: {
        addTask: "Ajouter une tâche",
        description: "Informations complémentaires",
        removeTodoConfirmation: "Voulez-vous supprimer cette liste de tâches ?",
        removeTodoTitle: "Supprimer la liste des tâches",
        tasksHeader: "Tâches",
        title: "Tâche",
      },
    },
    courseContentN: "Contenu {n}",
    coursePreview: "Aperçu du cours",
    createTemplate: "Créer un modèle",
    filters: {
      name: "Nom / ID",
    },
    globalListHeaders: {
      allUsersCount: "Inscrit",
      availableFrom: "Début du cours",
      completed: "Terminé",
      isRepeating: "Est répétitif",
      mandatory: "Obligatoire",
      name: "Nom",
      nextRepetitionDate: "Date de la prochaine répétition",
      published: "Publié",
      repeatsEvery: "Répéter toutes / tous les",
      statistics: "Statistiques",
      user: "Utilisateur",
    },
    manageCategories: "Gérer les catégories",
    manageTemplates: "Gérer les modèles",
    moduleDescription: "Combinez le contenu en cours.",
    moduleTitle: "Cours",
    remover: {
      contentExists: "Ce contenu est toujours en lien dans ce cours : {courseTitle}, {courseChapterTitle}, {courseContentTitle}",
    },
    repetitions: {
      everyMonth: "Tous les mois",
      everyNMonths: "Tous les {frequency} mois",
      everyNWeeks: "Toutes les {frequency} semaines",
      everyWeek: "Toutes les semaines",
    },
    settings: {
      actions: {
        archive: "Archiver",
        duplicate: "Dupliquer",
        preview: "Prévisualiser",
        saveAsTemplate: "Enregistrer en tant que modèle",
        unarchive: "Récupérer",
      },
      archiveConfirmation: "Voulez-vous vraiment archiver le cours existant ? <br /><br />\r\nLe cours sera automatiquement supprimé pour tous les participants actifs. <br />\r\nLes statistiques seront conservées. <br /><br />",
      archiveCourse: "Archiver le cours",
      archivedSuccessfully: "Le cours a bien été archivé.",
      courseAlreadyTemplate: "Le cours est déjà marqué comme modèle !",
      deleteConfirmation: "Voulez-vous vraiment placer ce cours « {coursName} » dans la corbeille ?",
      deleteCourse: "Supprimer le cours",
      deletedSuccessfully: "Le cours a bien été placé dans la corbeille.",
      duplicatedSuccessfully: "Le cours a bien été dupliqué.",
      saveAsTemplate: "Enregistrer le cours comme modèle",
      saveAsTemplateConfirmation: "Voulez-vous vraiment enregistrer ce cours comme modèle ?",
      savePublishedCourseShouldContainActiveContent: "Le cours doit comporter au moins un contenu publié afin d’être publié.",
      savePublishedTemplateShouldContainActiveContent: "Le modèle doit comporter au moins un contenu publié afin d’être publié.",
      savedAsTemplateSuccessfully: "Le cours a bien été enregistré comme modèle.",
      unarchivedSuccessfully: "Le cours a bien été récupéré.",
      unsavedChangesWarning: "Veuillez d’abord enregistrer le cours.",
    },
    title: "Cours",
  },
  createApp: {
    buttonLabels: {
      submitButton: "Créer une application",
    },
    dataSaveFailure: "Malheureusement, les données n’ont pas pu être enregistrées.",
    dataSaveSuccess: "Création de l’application réussie !",
    fieldLabels: {
      defaultLanguage: "Langue par défaut",
      name: "Nom de l’application",
      slug: "ID de l’application",
      subdomain: "Sous-domaine de l’application",
    },
    title: "Créer une application",
  },
  fileUploads: {
    deleteFile: "Une erreur est survenue lors de la suppression",
    dragAndDropOr: "Glisser-déposer ou",
    fileInformationUnavailable: "Informations sur le fichier indisponibles",
    fileLoadError: "Une erreur est survenue lors du chargement",
    fileTypeUnsupported: "Le type de fichier n’est pas pris en charge",
    invalidFile: "Fichier sélectionné invalide",
    loading: "Chargement",
    selectFile: "Sélectionner un fichier",
    uploadCancelled: "Téléchargement annulé",
    uploadCancelledError: "Une erreur est survenue lors de l’annulation",
    uploadFailed: "Une erreur est survenue lors du téléchargement",
    waitForFileInformation: "En attente des informations sur le fichier",
  },
  forms: {
    categories: "Catégories",
    contentCategories: {
      description: "Créez, traduisez et gérez vos catégories de formulaires ici",
      title: "Catégories de formulaires",
    },
    create: "Créer un formulaire",
    formDeleting: {
      confirmation: "Voulez-vous vraiment placer ce formulaire « {formName} » dans la corbeille ?",
      deletedSuccessFully: "Le formulaire a bien été placé dans la corbeille.",
      title: "Supprimer un formulaire",
    },
    formDetailsPage: {
      actions: "Actions",
      addFieldButtonLabel: "Ajouter un champ",
      addFieldModal: {
        title: "Ajouter un champ de formulaire",
      },
      alerts: {
        isArchived: "Ce formulaire est archivé.",
        isBinned: "Ce formulaire se trouve dans la corbeille.",
      },
      archiveForm: "Archiver un formulaire",
      archivedSuccessFully: "Le formulaire a bien été archivé.",
      createdAt: "Créé le",
      createdSuccessfully: "Création du formulaire réussie",
      deleteForm: "Supprimer un formulaire",
      duplicateForm: "Dupliquer un formulaire",
      duplicatedSuccessFully: "Le formulaire a bien été dupliqué.",
      fields: {
        "1": {
          description: "Les utilisateurs répondent à votre question sous forme écrite.",
          name: "Question à texte libre",
        },
        "2": {
          description: "Les utilisateurs peuvent répondre à votre question à l’aide d’une note allant de 0 à 5 étoiles.",
          name: "Note par étoiles",
        },
        "3": {
          description: "Vous permet d’ajouter un titre à votre formulaire ou à des sous-sections de votre formulaire.",
          name: "Titre",
        },
        "4": {
          description: "Marque une nouvelle section.",
          name: "Séparateur",
        },
      },
      isRequired: "Obligatoire",
      module: "Module",
      name: "Nom",
      publishForm: "Publier un formulaire",
      removers: {
        field: {
          confirmPermanentDeletion: "Voulez-vous supprimer définitivement ce champ ?",
          deleteField: "Supprimer un champ",
        },
      },
      replies: "Réponses",
      saveChanges: "Enregistrer les modifications",
      search: "Rechercher",
      sections: {
        fields: {
          description: "Ajoutez des champs au formulaire et classez-les à l’aide du glisser-déposer.",
          title: "Champs de formulaire",
        },
        form: {
          title: "Formulaire",
        },
        publishing: {
          description: "Les formulaires publiés peuvent être ajoutés aux cours.",
          title: "Publication",
        },
        usage: {
          description: "Ce tableau présente les cours qui utilisent ce formulaire.",
          title: "Utilisation",
        },
      },
      subPages: {
        general: "Général",
        usage: "Utilisation ({count})",
      },
      title: "Titre",
      unarchiveForm: "Récupérer un formulaire",
      unarchivedSuccessFully: "Le formulaire a bien été récupéré.",
      updatedAt: "Mis à jour le",
    },
    formList: {
      description: "Créez et gérez des formulaires qui peuvent être intégrés aux cours.",
      filter: "Filtrer",
      filterLabels: {
        active: "Visible / en cours",
        archived: "Archivés",
        name: "Nom / ID",
      },
      table: {
        category: "Catégorie",
        tags: "Tags",
        title: "Formulaire",
        updatedAt: "Mis à jour le",
      },
      title: "Formulaires",
    },
    noFormFields: "Veuillez ajouter des champs à ce formulaire pour pouvoir l’utiliser dans un cours",
    title: "Formulaires",
  },
  global: {
    action: "Action",
    addCoverImage: "Ajouter une image de couverture",
    all_types: "Tous les types",
    cancel: "Annuler",
    close: "Fermer",
    confirmRemove: "Oui, supprimer",
    create: "Créer",
    creationDate: "Créé le",
    dataSaveFailure: "Malheureusement, les données n’ont pas pu être enregistrées.",
    dateHint: "Format : aaaa-mm-jj",
    delete: "Supprimer",
    deleteIrreversibly: "Supprimer définitivement",
    deletionDate: "Supprimé le",
    editedAt: "Modifié le",
    info: "Informations",
    languages: {
      al: "Albanais",
      bg: "Bulgare",
      cs: "Tchèque",
      de: "Allemand (tutoiement)",
      de_formal: "Allemand (vouvoiement)",
      en: "Anglais",
      es: "Espagnol",
      fr: "Français",
      hr: "Croate",
      hu: "Hongrois",
      it: "Italien",
      jp: "Japonais",
      nl: "Néerlandais",
      no: "Norvégien",
      pl: "Polonais",
      pt: "Portugais",
      ro: "Roumain",
      ru: "Russe",
      sr: "Serbe",
      tr: "Turc",
      zh: "Chinois",
    },
    manageTranslations: "Gérer les traductions",
    maxCharacters: "Max. {count} caractères",
    morphTypes: {
      "1": "Fichier de la médiathèque | Fichiers de la médiathèque",
      "10": "Question | Questions",
      "1000": "Chapitre | Chapitres",
      "1001": "Question sur le contenu du cours | Question sur les contenus du cours",
      "1003": "Contenu du cours | Contenus du cours",
      "1005": "Récompense du cours | Récompenses du cours",
      "1008": "Texte | Textes",
      "11": "Réponse | Réponses",
      "1100": "Tâche | Tâches",
      "12": "Dossier de la médiathèque | Dossiers de la médiathèque",
      "1200": "Modèle d’e-mail | Modèles d’e-mail",
      "13": "Catégorie de questions | Catégories de questions",
      "1300": "Mot-clé | Mots-clés",
      "14": "Groupe de catégories | Groupes de catégories",
      "1400": "Coupon | Coupons",
      "15": "Quiz Battle | Quiz Battles",
      "1500": "Webinaire | Webinaires",
      "16": "Application | Applications",
      "1600": "Récompense du test | Récompenses du test",
      "17": "Commentaire | Commentaires",
      "1700": "Rapport | Rapports",
      "18": "Question suggérée | Questions suggérées",
      "19": "Formulaire | Formulaires",
      "2": "Actualités",
      "20": "Événement | Événements",
      "2000": "Utilisateur",
      "21": "Liste de tâches | Listes de tâches",
      "22": "Page | Pages",
      "23": "Champ de formulaire | Champs de formulaire",
      "24": "Bannière | Bannières",
      "25": "Catégorie de contenus | Catégories de contenus",
      "26": "Élément joint à la question | Éléments joints à la question",
      "3": "Compétition de quiz | Compétitions de quiz",
      "3000": "Tag",
      "3001": "Groupe de tags",
      "4": "Certificat | Certificats",
      "5": "Cours | Cours",
      "6": "Test | Tests",
      "7": "Question de test | Questions de test",
    },
    noResultMatchSearch: "Aucun résultat ne correspond à votre recherche.",
    notificationTrackedSinceMessage: "(Les notifications sont suivies depuis janvier 2024.)",
    preview: "Aperçu",
    proceed: "Poursuivre",
    publish: "Publier",
    publishStatus: {
      completed: "Terminé",
      expired: "Expiré",
      on: "le {date}",
      unpublished: "Non publié",
      visible: "Visible",
    },
    save: "Enregistrer",
    search: "Rechercher",
    searchIcon: "Icone de recherche",
    select: "Sélectionner",
    selectApp: "Sélectionner l’application",
    settings: "Paramètres",
    showDetails: "Afficher les détails",
    startedDate: "Commencé le",
    tag: "Tag",
    tags: "Tags",
    user: {
      email: "E-mail",
      first_name: "Prénom",
      last_name: "Nom",
    },
    warning: "Avertissement",
    willAlsoDeleteFollowingData: "Les données suivantes seront également supprimées :",
  },
  kEntityImage: {
    label: "Image",
  },
  kTable: {
    noResults: "Aucun contenu trouvé avec ce critère de recherche spécifique. Vous pouvez ajuster les filtres ci-dessus pour obtenir plus de résultats.",
    noResultsWithNotAllowedEntries: "Aucun contenu trouvé. Vous n’avez peut être pas les droits d’accès à certains résultats.",
  },
  kdetailPage: {
    backToOverview: "Retour à la liste",
    discardChangesAlert: "Il reste des modifications non enregistrées. \r\nVoulez-vous ignorer les modifications ?",
    unsavedChangesWarnings: {
      discardChanges: "Ignorer les modifications",
      discardUnsavedChanges: "Voulez-vous ignorer les modifications non enregistrées ?",
      title: "Modifications non enregistrées",
    },
  },
  mediaLibrary: {
    add_content: "Ajouter du contenu",
    all_types: "Tous les types",
    cant_add_file_in_root: "Impossible d’ajouter les fichiers dans le dossier racine.",
    cant_delete_folder_has_folders_files: "Veuillez d’abord supprimer tous les dossiers et fichiers qui se trouvent dans ce dossier. {link}",
    choose_new_folder: "Choisir un nouveau dossier",
    delete_folder_confirmation: "Voulez-vous vraiment placer le dossier « {folderName} » et tout ses contenus dans la corbeille ?",
    delete_folder_title: "Supprimer un dossier de la médiathèque",
    duplicateFolder: "Dupliquer le dossier",
    duplicatedSuccessFully: "Le dossier a bien été dupliqué.",
    files: {
      access: "Accès",
      add_cover_image: "Ajouter une image de couverture",
      cant_delete_has_course_contents: "Veuillez d’abord supprimer tous les contenus de cours mis en lien dans ce fichier. {link}",
      comments: "Commentaires",
      date: "Date",
      delete_file_confirmation: "Voulez-vous vraiment placer le fichier « {fileName} » dans la corbeille ?",
      delete_file_title: "Supprimer le fichier de la médiathèque",
      description: "Description",
      details: "Détails",
      duplicateFile: "Dupliquer le fichier",
      duplicatedSuccessFully: "Le fichier a bien été dupliqué.",
      file_path: "Chemin d’accès",
      general: "Général",
      media_file_created_successfully: "Le fichier a bien été créé !",
      media_file_deleted_successfully: "Le fichier a bien été placé dans la corbeille.",
      media_file_saved_successfully: "Le fichier a bien été enregistré !",
      move_file: "Déplacer le fichier",
      notificationNoResult: "Aucun utilisateur n’a reçu de notification pour ce fichier pour l’instant.",
      pastNotifications: "Notifications passées",
      past_notifications: "Notifications passées",
      publish_file: "Publier le fichier",
      publish_on_specific_date: "Doit être publié à une date spécifique",
      published_at: "Publié le",
      publishing: "Publication",
      publishing_note: "Définissez si le fichier doit être publié immédiatement ou à une date spécifique pour les utilisateurs.",
      reminder_notifications: "Rappel & notification",
      reminder_notifications_note: "Envoi de notifications lorsque le fichier devient visible.",
      select_file_type: "Veuillez sélectionner un type de fichier",
      sendNotification: "Envoyer une notification lors de la publication le {publishedAt}",
      sendNotifications: {
        allUsers: {
          button: "Notifier l’utilisateur | Notifier tous les {count} utilisateurs",
          text: "Aucun utilisateur n’a accès à ce fichier et ne souhaite être notifié pour l’instant. | Envoyer une notification à l’utilisateur qui dispose actuellement de l’accès à ce fichier et souhaite être notifié. | Envoyer une notification à tous les {count} utilisateurs qui disposent actuellement de l’accès à ce fichier et qui souhaitent être notifiés.",
          title: "Tous les utilisateurs",
        },
        header: "Envoyer une notification",
        onlyNewUsers: {
          button: "Notifier le nouvel utilisateur | Notifier les {count} nouveaux utilisateurs",
          text: "Tous les utilisateurs disposant actuellement de l’accès à ce fichier ont déjà reçu une notification ou ne souhaitent pas être notifiés. | Envoyer une notification à l’utilisateur qui n’a pas encore reçu de notification pour ce fichier alors qu’il souhaite être notifié. | Envoyer une notification aux {count} utilisateurs qui n’ont pas encore reçu de notification pour ce fichier alors qu’ils souhaitent être notifiés.",
          title: "Nouveaux utilisateurs uniquement",
        },
        sentSuccessFully: "Les notifications vont être envoyées.",
      },
      sendNotificationsButtonLabel: "Envoyer une notification à tous les utilisateurs ou aux nouveaux",
      sendNotificationsWithUnsavedChangesMessage: "Veuillez enregistrer vos modifications avant d’envoyer des notifications.",
      send_notification_at_published_on: "Envoyer une notification lors de la publication le",
      send_notification_to_all_new_users: "Envoyer une notification à tous les utilisateurs ou aux nouveaux",
      usage: "Utilisation ({count})",
      usage_note: "Ce tableau présente toutes les utilisations de ce fichier hors de la médiathèque.",
      usage_title: "Utilisation",
      users: "Utilisateurs",
      users_with_access: "Utilisateurs avec accès",
    },
    likes: "Mentions J’aime",
    media_folder_deleted_successfully: "Le dossier a bien été placé dans la corbeille.",
    media_library_file: "Fichier",
    media_library_folder: "Dossier",
    media_library_listing_note: "Fichiers enregistrés dans l’application.",
    media_library_listing_title: "Médiathèque",
    module: "Module",
    move: "Déplacer",
    move_folder: "Déplacer un dossier",
    name: "Nom",
    new_file: "Nouveau fichier",
    new_folder: "Nouveau dossier",
    notificationNoResult: "Aucun utilisateur n’a reçu de notification pour ce fichier pour l’instant.",
    path: "Chemin d’accès",
    save_changes: "Enregistrer les modifications",
    search: "Rechercher",
    search_by_name_description: "Nom / description",
    search_folder: "Rechercher un dossier",
    suggestAIQuestions: {
      disabledGeneratingAIQuestionsMessage: "Pour l’instant, les questions générées par l’IA sont disponibles uniquement pour les PDF ou si une description de plus de 50 caractères est fournie.",
      errorGeneratingQuestions: "Une erreur est survenue lors de la création des suggestions de questions.\r\nVeuillez réessayer plus tard ou télécharger un autre fichier.",
      generateAIQuestionsFeatureDisabled: "Pour utiliser cette fonction, vous devez activer l’option « Création de questions générées par l’IA dans la médiathèque » sous Paramètres → Options.",
      generatingAIQuestionsWithUnsavedChangesMessage: "Veuillez enregistrer vos modifications avant de générer des questions.",
      generatingQuestions: "<b>Des suggestions de question sont en cours de génération.</b></br>Ce processus peut prendre jusqu’à trois minutes.",
      goToSuggestedQuestions: "Accéder aux questions suggérées",
      questionsGeneratedSuccessfully: "1 suggestion de question a été générée. | {count} suggestions de questions ont été générées.",
      reachedMaximumTimesOfAIQuestionsGeneration: "Votre programme vous permet de créer des questions générées par l’IA qu’à partir d’un maximum de {count} fichiers par mois. Pour créer plus de questions générées par l’IA, veuillez contacter notre équipe Customer Success par e-mail {email}.",
      title: "Suggérer des questions générées par l’IA",
    },
    title: "Titre",
    views: "Vues",
  },
  notifications: {
    history: {
      sentAt: "Envoyé le",
      users: "Utilisateurs",
    },
  },
  pagination: {
    entriesPerPage: "Entrées par page :",
    of: "sur",
    page: "Page :",
  },
  question: {
    labelModal: {
      description: "Cliquer sur l’image pour ajouter des labels",
      title: "Ajouter des labels",
    },
  },
  sidenavigation: {
    accounts: "Comptes",
    advertisements: "Bannières",
    app: "Application",
    appointments: "Événements",
    bin: "Corbeille",
    certificateTemplates: "Modèles de certificat",
    comments: "Commentaires",
    contents: "Contenu",
    course: "Cours",
    courses: "Cours",
    dashboard: "Tableau de bord",
    emailTemplate: "Modèles d’e-mail",
    forms: "Formulaires",
    import: "Importation",
    keywords: "Mots-clés",
    learningMaterials: "Médiathèque",
    news: "Actualités",
    pages: "Pages",
    powerlearning: "Power Learning",
    publicApiTokens: "Jetons API publics",
    questionCategory: "Catégories de questions",
    questions: "Questions",
    quizBattle: "Quiz Battle",
    quizCompetitions: "Compétitions de quiz",
    quizTeams: "Équipes de quiz",
    reviews: "Revues",
    roles: "Rôles",
    settings: "Paramètres",
    statistics: "Statistiques",
    tagGroups: "Groupes de tags",
    tags: "Tags",
    tenants: "Locataires",
    tests: "Tests",
    translations: "Modifier & traduire",
    user: "Utilisateur",
    userActivity: "Activité des utilisateurs",
    userSubmittedQuestions: "Questions soumises",
    views: "Vues",
    vouchers: "Coupon",
    wbtStatistics: "Statistiques de la formation en ligne",
    webinars: "Webinaires",
  },
  statistics: {
    course_has_low_finishing_percentage_card_content: "Seulement {percentage} % des utilisateurs ont terminé le cours {link} qui prend fin dans {daysUntilEnd} jours, le {availableUntil}.",
    course_participations: "Participations au cours",
    courses: {
      active_courses: "Cours actifs",
      all_users: "Tous les utilisateurs",
      archived: "Archivé",
      avg_time_to_finish_description: "Temps moyen nécessaire à un utilisateur pour terminer intégralement le cours.",
      breadcrumbs: {
        courses: "Cours",
        participants: "Participants au cours",
        statistics: "Analyses",
      },
      content_irregular_time_description: "Les utilisateurs ont passé ⌀ {content_duration} sur {title}, mais seulement ⌀ {other_contents_avg_time} sur d’autres contenus.",
      courseViewsTrackedSince: "Le nombre de vues des cours est compté depuis le {trackingStartDate}.",
      course_duration: "Durée du cours",
      course_dynamic_ends: "Le cours se termine {duration} après avoir été commencé par l’utilisateur",
      course_ended_on: "Le cours s’est terminé le {date}",
      course_ends_on: "Le cours se termine le {date}",
      course_started_on: "Le cours a commencé le <b>{date}</b>",
      course_starts_on: "Le cours commence le <b>{date}</b>",
      course_views_count: "Le cours a été vu 1 fois.|Le cours a été vu {count} fois.",
      courses_currently_visible: "Il n’y a aucun cours disponible actuellement.|cours est disponible actuellement.|cours sont disponibles actuellement.",
      courses_currently_visible_for_selected_users: "Il n’y a actuellement aucun cours disponible pour les utilisateurs sélectionnés.|cours est actuellement disponible pour les utilisateurs sélectionnés.|cours sont actuellement disponibles pour les utilisateurs sélectionnés.",
      editCourse: "Modifier le cours",
      global_list_filters: {
        active: "Actifs",
        active_mandatory: "Actifs & obligatoires",
        all: "Tous",
        archived: "Archivés",
        current: "Actifs & en préparation",
        expired: "Expirés",
        in_preparation: "En préparation",
      },
      global_list_headers: {
        all_users: "Tous les utilisateurs",
        all_users_tooltip: "Utilisateurs actifs avec accès au cours ou ayant participé au cours",
        avg_completion_time: "⌀ Temps d’exécution",
        avg_completion_time_excel: "⌀ Temps d’exécution (en minutes)",
        avg_completion_time_tooltip: "Temps moyen entre le début et la fin du cours",
        comments: "Commentaires",
        passed: "Réussi",
        passed_tooltip: "Utilisateurs ayant réussi le cours par rapport au nombre total d’utilisateurs",
        started: "Commencé",
        started_tooltip: "Utilisateurs ayant commencé le cours par rapport au nombre total d’utilisateurs",
        title: "Titre",
        users_with_access: "Utilisateurs avec accès",
        users_with_access_tooltip: "Utilisateurs actifs avec accès au cours",
      },
      inactive: "Inactifs",
      navigation: {
        comments: "Commentaires",
        contents: "Contenus",
        course: "Cours",
        overview: "Vue d’ensemble",
        participants: "Participants",
      },
      new_course: "Nouveau cours",
      new_participants: "Nouveaux participants",
      new_participants_over_time: "Nouveaux participants au fil du temps",
      notExist: "Le cours n’existe pas",
      participants: "Participants",
      participants_avg_time_finish: "Temps moyen d’exécution",
      participation_duration_type: {
        "0": "1 jour|{count} jours",
        "1": "1 semaine|{count} semaines",
        "2": "1 mois|{count} mois",
      },
      participation_status: {
        finished: "Terminé",
        not_started: "En attente",
        started: "En cours",
      },
      pass_rate: "Taux de réussite",
      periodRanges: {
        last30Days: "30 derniers jours",
        last7Days: "7 derniers jours",
        lastYear: "Année dernière",
      },
      templates: "Modèles",
      viewcount: "Vues",
      wbtVerbs: {
        "http://adlnet": {
          "gov/expapi/verbs/attempted": "Commencé",
          "gov/expapi/verbs/completed": "Terminé",
          "gov/expapi/verbs/experienced": "Commencé",
          "gov/expapi/verbs/failed": "Échoué",
          "gov/expapi/verbs/passed": "Réussi",
        },
      },
    },
    finished_courses: "Cours actifs terminés",
    finished_courses_tooltip: "Lorsque tous les utilisateurs auront terminé leurs cours actuellement actifs, la barre de progression affichera 100 %.",
    in_the_last3Days: "au cours des 3 derniers jours",
    in_the_last7Days: "au cours des 7 derniers jours",
    last30Days: "30 derniers jours",
    last7Days: "7 derniers jours",
    mandatory_content_finished: "Contenu obligatoire actif terminé",
    mandatory_content_finished_tooltip: "Lorsque tous les utilisateurs auront terminé leurs contenus obligatoires actuellement actifs, la barre de progression affichera 100 %.",
    minutes: "minutes",
    most_commented_course_card_content: "Le cours {link} a reçu {comments_count} commentaires {period}.",
    most_liked_course_card_content: "Le cours {link} a obtenu {likes_count} mentions J’aime {period}.",
    nDays: "1 jour|{count} jours",
    nHours: "1 heure|{count} heures",
    nMinutes: "1 minute|{count} minutes",
    nSeconds: "1 seconde|{count} secondes",
    previousLast30Days: "30 derniers jours précédents",
    previousLast7Days: "7 derniers jours précédents",
    seconds: "secondes",
    time_range: "Période",
    today: "Aujourd’hui",
    view_comments: "Afficher les commentaires",
    yesterday: "Hier",
  },
  tagGroups: {
    add_tag: "Ajouter un tag",
    cant_delete_has_tags: "Veuillez d’abord supprimer tous les tags de ce groupe de tags. {link}",
    color: "Couleur",
    count_tags_in_group: "Il y a 1 tag dans ce groupe.|Il y a {count} tags dans ce groupe.",
    create_tag_group: "Créer un groupe de tags ",
    delete_tag_group: "Supprimer le groupe de tags",
    delete_tag_group_confirmation: "Voulez-vous vraiment placer le groupe de tags « {tagGroup} » et tous ses tags dans la corbeille ?",
    delete_tag_group_title: "Supprimer le groupe de tags",
    description: "Ici, vous pouvez gérer les groupes de tags et les tags associés.",
    edit_color_icon: "Modifier la couleur et l’icone",
    general: "Général",
    icon: "Icone",
    name: "Nom",
    new_tag_group: "Nouveau groupe de tags ",
    no_tag_groups_yet: "Aucun groupe de tags n’a été ajouté pour l’instant.",
    no_tags_in_tag_group: "Aucun tag n’a été ajouté à ce groupe de tags pour l’instant.",
    save_changes: "Enregistrer les modifications",
    search_for_tag: "Rechercher un tag",
    show_highscore_tag: "Afficher les utilisateurs avec des tags de cette catégorie dans le meilleur score de la Quiz Battle",
    signup_required: "Doit être défini lors de l’inscription",
    signup_selectable: "Peut être défini par l’utilisateur lors de l’inscription",
    tag_group_created_successfully: "Création du groupe de tags réussie",
    tag_group_deleted_successfully: "Groupe de tags placé dans la corbeille",
    tag_group_updated_successfully: "Mise à jour du groupe de tags réussie",
    title: "Tags",
  },
  tagSelect: {
    label: "Tags",
  },
  tags: {
    all_types: "Tous les types",
    can_not_delete_tag_with_usage_count: "Impossible de supprimer le tag « {label} », il est utilisé {count} fois. {link}",
    created_at: "Créé le",
    delete_tag: "Supprimer le tag",
    delete_tag_confirmation: "Voulez-vous vraiment placer ce tag dans la corbeille ?",
    delete_tag_title: "Supprimer le tag",
    edit_tag_group: "Réassigner un groupe de tags",
    edited_at: "Modifié le",
    exclusive_label: "Autoriser uniquement les Quiz Battles internes au tag",
    general: "Général",
    hideHighscore_label: "Ne pas afficher dans les statistiques des quiz",
    module: "Module",
    save_changes: "Enregistrer les modifications",
    search: "Rechercher",
    tagIsBinned: "Ce TAG est dans la corbeille",
    tag_created_successfully: "Création du tag réussie",
    tag_deleted_successfully: "Le tag a été placé dans la corbeille.",
    tag_group: "Groupe de tags",
    tag_settings: "Paramètres des tags",
    title: "Titre",
    usage: "Utilisation | Utilisations ({count})",
    usage_note: "Ce tableau présente tous les utilisateurs et tous les contenus qui utilisent ce tag.",
    usage_title: "Utilisation",
  },
  tenants: {
    administrators: "Administrateurs",
    create_tenant: "Créer un locataire",
    create_tenant_btn: "Créer un locataire",
    created_at: "Créé le",
    general: "Général",
    main_app: "Application principale",
    main_app_admin: "Définissez un utilisateur existant en tant qu’administrateur pour l’application principale. Les administrateurs de l’application principale ne sont limités que par leur rôle ; ils peuvent voir tous les contenus et les utilisateurs dans les modules respectifs.",
    main_app_description: "Les utilisateurs qui ne sont pas attribués à un locataire sont automatiquement attribués à cette application principale.",
    module_description: "Vous pouvez gérer vos locataires ici. Les locataires disposent de leurs propres paramètres et peuvent avoir leurs propres administrateurs.",
    name: "Nom",
    new_tenant_headline: "Nouveau locataire",
    open_settings: "Ouvrir les paramètres",
    promote_admin: "Définir en tant qu’administrateur",
    save_changes: "Enregistrer les modifications",
    settings: "Paramètres",
    settings_description: "Chaque locataire dispose de paramètres individuels. Paramétrez l’affichage, la visibilité des modules, etc.",
    tag: "Tag",
    tag_description: "Le contenu avec ce tag est attribué à ce locataire",
    tenant_admin: "Définissez un utilisateur existant en tant qu’administrateur pour ce locataire. Cet administrateur pourra alors gérer automatiquement les utilisateurs et les contenus de ce locataire uniquement.",
    tenant_created_successfully: "Le locataire a été créé. Continuez en ajustant ses paramètres.",
    title: "Locataires",
    user_admin: "L’utilisateur a été défini en tant qu’administrateur pour {name}",
    users: "Utilisateurs",
  },
  topNavigation: {
    appSwitcher: "App Switcher",
    greetings: "Bonjour {username}",
    guide: "Guide",
    help: "Aide",
    helpdesk: "Centre d’aide",
    logout: "Déconnexion",
    newCourse: "Nouveau cours",
    openApp: "Ouvrir l’application",
    privacyPolicy: "Politique de confidentialité",
    telephoneSupport: "Assistance téléphonique : {telephoneNumber}",
  },
  translations: {
    advertisement: {
      description: "Contenu",
      leaderboardImageUrl: "Graphique (desktop)",
      link: "Lien",
      rectangleImageUrl: "Graphique (mobile)",
    },
    appointment: {
      coverImageUrl: "Image de couverture",
      description: "Description",
      name: "Nom",
    },
    autoTranslateHumanTranslations: "Remplacer les textes saisis manuellement par la traduction automatique",
    autoTranslateMessage: "Traduire tous les champs en {language} (bêta)",
    automaticTranslate: "Traduire automatiquement la langue suivante : {defaultLanguage} (bêta)",
    autotranslateRunning: "Traduction automatique activée pour 1 contenu.|Traduction automatique activée pour {count} contenus.",
    azureVideoPreview: {
      awaitingProcessing: "En attente de traitement",
      isProcessing: "Traitement en cours... \r\nL’opération peut prendre un moment. \r\nVous pouvez quitter cette page et revenir plus tard.",
      processingCanceled: "Le traitement a été interrompu",
      processingComplete: "Traitement terminé",
      processingError: "Erreur lors du traitement",
      processingIsBeingCanceled: "Le traitement est en cours d’annulation",
      processingIsBeingStarted: "Le traitement a commencé",
      videoStatus: "Vidéo :",
    },
    bulkAutoTranslateButtonLabel: "Traduction automatique",
    category: {
      coverimage: "Image de couverture",
      name: "Nom",
    },
    categoryGroup: {
      name: "Nom",
    },
    certificate: {
      image: "Image",
    },
    changesNotTranslatedYet: "Les modifications ne sont pas traduites pour l’instant. \r\nDernières modifications :",
    confirmAutoTranslate: "Confirmer la traduction",
    contentCategory: {
      name: "Nom",
    },
    copyContentFromDefaultLangauge: "Copier le contenu à partir de la langue suivante : {defaultLanguage}",
    countElementsTranslatedManually: "1 traduction a été récemment ajoutée ou modifiée manuellement par un admin. Voulez-vous remplacer cette entrée par la traduction automatique ?|{count} traductions ont été récemment ajoutées ou modifiées manuellement par un admin. Voulez-vous remplacer ces textes par la traduction automatique ?",
    course: {
      chapter: {
        content: {
          description: "Description",
          title: "Titre",
        },
        title: "Titre",
      },
      coverimage: "Image de couverture",
      description: "Description",
      requestAccessLink: "Lien vers la demande d’accès",
      text: {
        content: "Contenu",
        headline: "Titre",
        image: "Image",
      },
      title: "Titre",
    },
    dataSaveFailure: "Malheureusement, les données n’ont pas pu être enregistrées.",
    downloadAllowed: "Autoriser le téléchargement",
    editLabels: "Modifier les labels",
    exportHelpText: "Exporte tous les textes actuellement filtrés pour traduction / révision",
    exportSelectedHelpText: "Exporte tous les textes actuellement sélectionnés pour traduction / révision",
    form: {
      coverImageUrl: "Image de couverture",
      title: "Titre",
    },
    formfield: {
      title: "Titre",
    },
    hereYouCanTranslateAllContent: "Vous pouvez traduire tout le contenu ici. Vous pouvez également exporter et importer les contenus.",
    import: {
      importError: "Échec de l’importation. Assurez-vous d’avoir sélectionné le bon fichier.",
      importSuccess: "Le fichier a bien été importé.",
    },
    keyword: {
      description: "Description",
      name: "Nom",
    },
    labelPlaceholder: "Label",
    mailTemplate: {
      body: "E-mail",
      title: "Sujet",
    },
    medialibrary: {
      coverimage: "Image de couverture",
      description: "Description",
      file: "Fichier",
      name: "Nom",
    },
    medialibraryFolder: {
      name: "Nom",
    },
    missingTranslationsCount: {
      contentCount: "1 contenu|{count} de ces contenus",
      message: "{0} n’a pas encore été traduit en intégralité vers la langue sélectionnée.|{0} n’ont pas encore été traduits en intégralité vers la langue sélectionnée.",
    },
    mustBeValidLink: "Doit commencer par http(s)://",
    mustBeValidYoutubeUrl: "Doit contenir une URL YouTube valide",
    news: {
      content: "Contenu",
      coverimage: "Image de couverture",
      title: "Titre",
    },
    page: {
      content: "Contenu",
      title: "Titre",
    },
    primaryLanguage: "langue primaire",
    question: {
      title: "Titre",
    },
    questionanswer: {
      answer: "Réponse",
      feedback: "Feedback",
    },
    saveAndClose: "Enregistrer et fermer",
    saveAndNext: "Enregistrer et suivant",
    saveContent: "Enregistrer",
    saveToEditLabels: "Enregistrez la traduction avant de modifier les intitulés.",
    scormWBTMessage: "Actuellement, nous ne collectons pas de statistiques pour les formations en ligne au format SCORM. En intégrant la formation en ligne SCORM dans un cours, vous pouvez accéder à la progression des utilisateurs grâce aux statistiques des cours.<br /> Si vous souhaitez suivre ces événements au sein de votre formation en ligne, utilisez le format moderne xAPI.",
    selectEntriesFromTheTable: "Sélectionner les entrées à partir du tableau",
    selectFileType: "Veuillez sélectionner le type de fichier",
    startAutoTranslateButton: "Ne traduire aucune entrée|Traduire 1 entrée|Traduire {count} entrées",
    statusOptions: {
      all: "Tous",
      notTranslated: "Non traduit",
      translated: "Traduit",
    },
    subtileProcessingError: "Sous-titre : erreur lors de la génération. Veuillez contacter l’assistance.",
    subtitleHint: "Sélectionner la langue de la vidéo",
    subtitleLabel: "Sous-titre",
    subtitleProcessing: "Sous-titre :",
    switchTranslationsData: "Afficher le vrai nom et l’ID de l’utilisateur dans le lecteur",
    tableHeaderAll: "Tous",
    tableHeaderLastUpdated: "Dernière modification",
    tableHeaderStatus: "Statut",
    tableHeaderTitle: "Titre",
    tableHeaderTranslate: "Traduire",
    tableHeaderType: "Type",
    test: {
      description: "Description",
      name: "Nom",
    },
    title: "Modifier & traduire",
    toOriginalContent: "Ouvrir le contenu d’origine",
    todolistitem: {
      description: "Description",
      title: "Titre",
    },
    totalElementsToAutoTranslate: "Au total, 1 entrée est sélectionnée pour traduction.|Au total, {count} entrées sont sélectionnées pour traduction.",
    translationStatus: "Statut de la traduction",
    translationTitle: "Titre",
    typeOptions: {
      advertisement: "Bannière",
      appointment: "Événement",
      category: "Catégorie",
      categoryGroup: "Catégories de questions",
      certificateTemplate: "Modèles de certificat",
      contentCategory: "Catégories de contenus",
      course: "Cours",
      courseAward: "Récompense du cours",
      courseChapter: "Chapitre du cours",
      courseContent: "Contenu du cours",
      emailTemplate: "Modèle d’e-mail",
      form: "Formulaires",
      keyword: "Mot-clé",
      medialibraryFile: "Fichier de la médiathèque",
      medialibraryFolder: "Dossier de la médiathèque",
      news: "Actualités",
      page: "Pages",
      questions: "Questions",
      reporting: "Rapports",
      tag: "Tag",
      tagGroup: "Groupe de tags",
      test: "Tests",
      user: "Utilisateur",
      voucher: "Coupon",
      webinar: "Webinaire",
    },
    typeSelectLabel: "Types",
    unsavedChanges: "Certaines modifications n’ont pas été enregistrées",
    updated: "Mis à jour",
    videoProcessingError: "Vidéo : erreur de traitement. Veuillez contacter l’assistance.",
    videoSaveHint: "Veuillez enregistrer le contenu pour pouvoir traiter la vidéo.",
    wbtType: "Type de formation en ligne",
  },
  users: {
    userSelect: {
      defaultLabel: "Utilisateur",
      noUserFound: "Aucun utilisateur trouvé",
      searchHint: "Rechercher par nom d’utilisateur / e-mail",
    },
  },
}
