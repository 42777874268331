export function getContentCategoryDetailsPageRoutes(currentModule: string, routePrefix: string) {
  return [
    {
      path: routePrefix,
      name: `${currentModule}/content-categories-outlet`,
      component: () => import("./components/ContentCategoryDetailsWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:contentCategoryId/general`,
          name: `${currentModule}/content-category-general`,
          component: () => import("./views/ContentCategoryEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "content-categories",
          },
        },
      ],
    },
  ]
}
