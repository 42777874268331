<template>
  <a v-if="appProfiles.length === 1" :href="frontendUrl" target="_blank" class="c-appButton">
    <div class="c-appButton__image">
      <img :src="appProfileConfigStore.settings.app_icon as string" />
    </div>
    <div class="c-appButton__text">{{ $t("topNavigation.openApp") }}</div>
  </a>

  <div v-else-if="appProfiles.length > 1" class="c-appButton">
    <div class="c-appButton__text">{{ $t("topNavigation.openApp") }}</div>
    <v-menu activator="parent">
      <v-list density="compact">
        <v-list-item v-for="appProfile in appProfiles" :key="appProfile.id" :href="appProfile.url" target="_blank" :title="appProfile.name" :subtitle="appProfile.url">
          <template v-if="appProfile.app_icon" #prepend>
            <div class="c-appButton__image" style="height: 40px">
              <img :src="appProfile.app_icon as string" />
            </div>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import { useAppProfileConfigStore } from "@/store/appProfileConfig"
import { computed, watch } from "vue"
import { useAppConfigStore } from "@/store/appConfig"
import { notEmpty } from "@/logic/helpers"

const appConfigStore = useAppConfigStore()
const appProfileConfigStore = useAppProfileConfigStore()

watch(
  () => appConfigStore.appId,
  () => {
    appProfileConfigStore.fetchAllProfiles(appConfigStore.appId!)
  },
  {
    immediate: true,
  },
)

function getFrontendUrl(profileSettings: { external_domain: null | string; subdomain: string }) {
  if (profileSettings.external_domain) {
    return `https://${profileSettings.external_domain}`
  }
  if (profileSettings.subdomain) {
    return `https://${profileSettings.subdomain}.keelearning.de`
  }
  return undefined
}

const frontendUrl = computed(() => {
  return getFrontendUrl(appProfileConfigStore.settings)
})

const appProfiles = computed(() => {
  return appProfileConfigStore.allProfiles
    .map((profile) => {
      if (!getFrontendUrl(profile.settings)) {
        return null
      }
      return {
        id: profile.id,
        name: profile.settings.app_name,
        app_icon: profile.settings.app_icon,
        url: getFrontendUrl(profile.settings) || undefined,
      }
    })
    .filter(notEmpty)
})
</script>

<style lang="scss">
.c-appButton {
  cursor: pointer;
  background: $color-vio1;
  border: 1px solid $color-pink;
  border-radius: 4px;
  color: $color-white;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  min-width: 166px;
  line-height: 36px;
  outline: none;
  overflow: hidden;
  text-decoration: none;
  width: min-content;

  &:active,
  &:focus-visible,
  &:hover {
    color: $color-tu1;
  }

  &:focus-visible {
    border-color: $color-tu1;
  }
}

.c-appButton__image {
  background: $color-white;
  width: 56px;
  flex-shrink: 0;
  padding: 3px;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.c-appButton__text {
  flex: 1 1 auto;
  padding: 0 16px;
  text-align: center;
  white-space: nowrap;
}
</style>
