export function getNewsDetailPageRoutes(currentModule: string, routePrefix: string) {
  return [
    {
      path: routePrefix,
      name: `${currentModule}/news-outlet`,
      component: () => import("./components/NewsDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:newsId/general`,
          name: `${currentModule}/news-general`,
          component: () => import("./views/NewsEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "news",
          },
        },
        {
          path: `:newsId/comments`,
          name: `${currentModule}/news-comments`,
          component: () => import("./views/NewsEditComments.vue"),
          meta: {
            isDetailPage: true,
            module: "news",
          },
        },
      ],
    },
  ]
}

export function getNewsRoutes() {
  return [
    {
      path: "/news",
      name: "news",
      component: () => import(/* webpackChunkName: "news-list" */ "./views/NewsList.vue"),
      children: getNewsDetailPageRoutes("news", "detail"),
    },
  ]
}
