import { App } from "vue"
import { createI18n, Translation } from "vue-i18n"
import { getSelectedLanguage } from "@/logic/i18n"
// @ts-ignore
import de from "../../lang/de.json5"
// @ts-ignore
import en from "../../lang/en.json5"
// @ts-ignore
import fr from "../../lang/fr.json5"

import { de as dateFnsDe, enGB as dateFnsEn, fr as dateFnsFr } from "date-fns/locale"
import { parseISO, setDefaultOptions } from "date-fns"

export const setupI18n = async (app: App<Element>) => {
  app.component("Translation", Translation)

  const datetimeFormats = {
    de: {
      date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      datetime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      },
    },
    en: {
      date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      datetime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      },
    },
    fr: {
      date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      datetime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      },
    },
  }

  // Merge all module specific translation files with our global german and english translation files
  const moduleTranslations: Record<string, () => Promise<any>> = import.meta.glob("../modules/*/*.json5")
  await Promise.all(
    Object.entries(moduleTranslations).map(async ([moduleKey, translationsPromise]) => {
      const translations = await translationsPromise()
      const split = moduleKey.split("/")
      const language = split.at(-1)!.split(".")[0]
      const module = split.at(-2)
      switch (language) {
        case "de":
          de[module] = {
            ...translations.default,
            ...(de[module] ?? {}),
          }
          break
        case "en":
          en[module] = {
            ...translations.default,
            ...(en[module] ?? {}),
          }
          break
        case "fr":
          fr[module] = {
            ...translations.default,
            ...(fr[module] ?? {}),
          }
          break
        default:
          console.error("Invalid language given", language)
      }
    }),
  )

  const i18n = createI18n({
    legacy: false,
    locale: getSelectedLanguage().value,
    fallbackLocale: "de",
    warnHtmlMessage: false,
    warnHtmlInMessage: "off",
    messages: {
      de,
      en,
      fr,
    },
    // @ts-expect-error seems to be a bug in the ts definition
    datetimeFormats,
  })
  app.use(i18n)
  app.config.globalProperties.$date = (date: string | Date, format?: string) => {
    if (typeof date === "string") {
      date = parseISO(date)
    }
    if (!format) {
      return i18n.global.d(date)
    }
    return i18n.global.d(date, format)
  }

  let dateFnsLocale = dateFnsEn
  switch (getSelectedLanguage().value) {
    case "de":
      dateFnsLocale = dateFnsDe
      break
    case "en":
      dateFnsLocale = dateFnsEn
      break
    case "fr":
      dateFnsLocale = dateFnsFr
      break
  }

  setDefaultOptions({
    locale: dateFnsLocale,
  })
}
