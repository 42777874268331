<template>
  <div class="s-icon-color-pickers-container" :class="{ 'with-elevation': elevation }">
    <div class="s-leftColumn">
      <KColorPicker v-model="colorModelValue" :disabled="disabled" name="color" elevation="0" hide-inputs show-swatches class="s-color-picker" width="100%" :modes="['hex']"></KColorPicker>
    </div>
    <div class="s-rightColumn">
      <KIconPicker v-model="iconModelValue" :disabled="disabled" :selected-color="colorModelValue" name="icon" class="s-icon-picker"></KIconPicker>
      <KButton v-if="!embedded" primary class="w-100" @click="selectValues">{{ $t("global.select") }} </KButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue"

type KIconPickerProps = {
  color: string
  icon: string
  elevation?: boolean
  disabled?: boolean
  embedded?: boolean
}
const props = withDefaults(defineProps<KIconPickerProps>(), {
  elevation: true,
  disabled: false,
  embedded: false,
})
const emits = defineEmits(["update:color", "update:icon", "select-values"])

/* eslint-disable vue/no-setup-props-destructure */
const colorModelValue = ref(props.color)
const iconModelValue = ref(props.icon)
/* eslint-enable vue/no-setup-props-destructure */

const selectValues = () => {
  if (props.disabled || props.embedded) {
    return
  }
  emits("update:color", colorModelValue)
  emits("update:icon", iconModelValue)
  emits("select-values", { color: colorModelValue.value, icon: iconModelValue.value })
}

if (props.embedded) {
  watch(colorModelValue, () => {
    emits("update:color", colorModelValue)
  })
  watch(iconModelValue, () => {
    emits("update:icon", iconModelValue)
  })
}
</script>

<style scoped lang="scss">
.s-icon-color-pickers-container {
  display: flex;
  padding: 16px;
  gap: 32px;
  border-radius: 4px;
  border: 1px solid $color-grey3;
  background: #fff;
  max-width: 576px;

  &.with-elevation {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  }

  .s-leftColumn {
    width: 300px !important;
    flex: 1;
  }

  .s-rightColumn {
    display: flex;
    flex-direction: column;
  }
}

:global(.s-icon-color-pickers-container .s-icon-picker) {
  flex: 1;
}
</style>
