<template>
  <v-checkbox v-bind="attributes"></v-checkbox>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VCheckbox } from "vuetify/components"

type KCheckboxProps = Partial<InstanceType<typeof VCheckbox>["$props"]>

export default defineComponent<KCheckboxProps, { attributes: any }>({
  props: VCheckbox.props,
  computed: {
    attributes() {
      const props: KCheckboxProps = {
        ...this.$props,
        ...this.$attrs,
      }
      props.color = "grey-darken-4"
      return props
    },
  },
})
</script>
