import { acceptHMRUpdate, defineStore } from "pinia"

import { api } from "@/logic/api"
import { useAccountStore } from "@/modules/account/store"

export type TagGroupEntry = {
  id: number
  name: string
  signup_selectable: boolean
  show_highscore_tag: boolean
  signup_required: boolean
  color: string
  icon: string
  created_at: string
  updated_at: string
  is_in_bin: boolean
  tags: TagEntry[]
}
export type TagEntry = {
  id: number
  label: string
  tag_group_id: number
  flag_all_users: boolean
  created_at: string
  is_binned: boolean
}
export type Tags = {
  entries: TagEntry[]
  tagGroups: TagGroupEntry[]
  isLoading: boolean
}

export const useTagsStore = defineStore("tags", {
  state: (): Tags => {
    return {
      entries: [],
      tagGroups: [],
      isLoading: false,
    }
  },
  actions: {
    async fetchTags() {
      if (!useAccountStore().isLoggedIn) {
        return
      }
      this.isLoading = true
      const response = await api.get("/tags")
      this.entries = response.data
      this.tagGroups = response.tagGroups
      this.isLoading = false
    },
  },
  getters: {
    allUsersTagId(state) {
      return state.entries.find((tag: TagEntry) => tag.flag_all_users)?.id
    },
    /**
     * This returns an array of objects that look like this:
     * {
     *   tagGroup: TagGroupEntry,
     *   tags: TagEntry[]
     * }
     */
    groupsWithTags() {
      const tagsWithoutGroup: TagEntry[] = []
      const resultMap: Record<number, { tagGroup: TagGroupEntry; tags: TagEntry[] }> = {}
      this.entries.forEach((tag) => {
        let tagGroup = null
        if (tag.tag_group_id) {
          tagGroup = this.tagGroups.find((tagGroup) => tagGroup.id === tag.tag_group_id)
        }
        if (!tagGroup) {
          // This shouldn't happen, but it's nice to handle this case, because if something else does break, that means this will not tear down the whole app.
          tagsWithoutGroup.push(tag)
          return
        }
        if (resultMap[tagGroup.id] === undefined) {
          resultMap[tagGroup.id] = {
            tagGroup: tagGroup,
            tags: [],
          }
        }
        resultMap[tagGroup.id].tags.push(tag)
      })
      const sortedTagGroups = Object.values(resultMap).sort((a, b) => a.tagGroup.name.localeCompare(b.tagGroup.name))

      // Add the generic tags to the top of the list
      sortedTagGroups.unshift({
        tagGroup: {
          id: -1,
          name: "Nicht zugeordnet",
          signup_selectable: false,
          show_highscore_tag: true,
          signup_required: false,
          color: "#455a64",
          icon: "far fa-cog",
          created_at: "",
          updated_at: "",
          is_in_bin: false,
          tags: [],
        },
        tags: tagsWithoutGroup,
      })

      sortedTagGroups.forEach((tagGroup) => {
        tagGroup.tags.sort((a, b) => a.label.localeCompare(b.label))
      })

      return sortedTagGroups
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useTagsStore, import.meta.hot))
}
