export function getUsersRoutes() {
  return [
    {
      path: "/usersnew",
      name: "usersnew",
      component: () => import(/* webpackChunkName: "users-list" */ "./views/UsersView.vue"),
    },
    {
      path: "/users",
      name: "users",
      component: () => import(/* webpackChunkName: "users-old-backend */ "./components/UserOld.vue"),
    },
    {
      path: "/user-roles",
      name: "user-roles",
      component: () => import(/* webpackChunkName: "user-roles-old-backend */ "./components/UserRolesOld.vue"),
    },
  ]
}
