<template>
  <div v-bind="attributes">
    <slot :formatted-duration="duration"></slot>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, useAttrs } from "vue"

const props = defineProps<{
  time: number | null
}>()

const attributes = useAttrs()
const { t } = useI18n()

const duration = computed(() => {
  const seconds = props.time
  if (!seconds) {
    return null
  }
  if (seconds < 60) {
    return t("statistics.nSeconds", Math.round(seconds))
  }
  if (seconds < 60 * 60) {
    return t("statistics.nMinutes", Math.round(seconds / 60))
  }
  if (seconds < 60 * 60 * 24) {
    return t("statistics.nHours", Math.round((seconds / 60 / 60) * 10) / 10)
  }
  return t("statistics.nDays", Math.round((seconds / 60 / 60 / 24) * 10) / 10)
})
</script>
