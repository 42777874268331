<template>
  <div class="s-header">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.s-header {
  background: $color-grey1;
  border-bottom: 1px solid $color-grey2;
  padding: 26px 16px;
}
</style>
