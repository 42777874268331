<template>
  <v-switch v-bind="vSwitchAttributes">
    <template v-if="attributes.hint" #label>
      <div>
        <div class="w-100 text-h3">{{ attributes.label }}</div>
        <div class="text-label2">{{ attributes.hint }}</div>
      </div>
    </template>
  </v-switch>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VSwitch } from "vuetify/components"

type KSwitchProps = Partial<InstanceType<typeof VSwitch>["$props"]>

export default defineComponent<KSwitchProps, { attributes: any; vSwitchAttributes: any }>({
  props: VSwitch.props,
  computed: {
    attributes() {
      const props: KSwitchProps = {
        ...this.$props,
        ...this.$attrs,
        inset: true,
        color: "primary",
        density: "compact",
      }
      return props
    },
    vSwitchAttributes() {
      const attributes = { ...this.attributes }
      delete attributes.hint

      return attributes
    },
  },
})
</script>
