<template>
  <v-btn v-bind="attributes">
    <slot />
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VBtn } from "vuetify/components"

const KBtnProps = VBtn.props
KBtnProps.primary = {
  type: Boolean,
  required: false,
  default: false,
}

type KBtnPropsType = Partial<
  InstanceType<typeof VBtn>["$props"] & {
    primary: boolean
  }
>

export default defineComponent<KBtnPropsType, { attributes: any }>({
  props: KBtnProps,
  computed: {
    attributes() {
      let variant = this.$props.variant
      if (!variant || variant === "elevated") {
        variant = "flat"
      }
      const props: KBtnPropsType = {
        ...this.$props,
        ...this.$attrs,
        variant: variant,
      }
      if (!props.color && variant === "flat") {
        if (props.primary) {
          props.color = "primary"
        } else {
          props.color = "secondary"
        }
      }
      if (props.disabled) {
        props.color = ""
      }
      return props
    },
  },
})
</script>
