import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { useWindowFocus } from "@vueuse/core"
import { getAccesslogsRoutes } from "@/modules/accesslogs/routes"
import { getAccountRoutes } from "@/modules/account/routes"
import { getAdvertisementsRoutes } from "@/modules/advertisements/route"
import { getAppSwitcherRoutes } from "@/modules/app-switcher/routes"
import { getAppointmentsRoutes } from "@/modules/appointments/route"
import { getCategoriesRoutes } from "@/modules/Categories/routes"
import { getCommentsRoutes } from "@/modules/comments/routes"
import { getCompetitionsRoutes } from "@/modules/competitions/routes"
import { getCoursesRoutes } from "@/modules/courses/route"
import { getDashboardRoutes } from "@/modules/dashboard/routes"
import { getFormsRoutes } from "@/modules/forms/route"
import { getGlobalRoutes } from "@/modules/global/routes"
import { getImportRoutes } from "@/modules/import/routes"
import { getIndexCardsRoutes } from "@/modules/index-cards/routes"
import { getKeywordsRoutes } from "@/modules/keywords/routes"
import { getMailsRoutes } from "@/modules/mails/routes"
import { getMediaLibrariesRoutes } from "@/modules/media-libraries/routes"
import { getNewsRoutes } from "@/modules/news/routes"
import { getPagesRoutes } from "@/modules/pages/routes"
import { getQuestionsRoutes } from "@/modules/questions/routes"
import { getQuizTeamsRoutes } from "@/modules/quiz-teams/routes"
import { getSettingsRoutes } from "@/modules/settings/routes"
import { getStatsQuizPlayersRoutes } from "@/modules/stats/quiz/routes"
import { getStatsRatingsRoutes } from "@/modules/stats/ratings/routes"
import { getStatsUsersRoutes } from "@/modules/stats/users/routes"
import { getStatsUserActivityRoutes } from "@/modules/stats/user-activity/routes"
import { getStatsWbtRoutes } from "@/modules/stats/wbt/routes"
import { getSuggestedQuestionsRoutes } from "@/modules/suggested-questions/routes"
import { getTagsRoutes } from "@/modules/tags/routes"
import { getTenantRoutes } from "@/modules/tenants/routes"
import { getTestsRoutes } from "@/modules/tests/route"
import { getTranslationsRoutes } from "@/modules/translations/routes"
import { getUsersRoutes } from "@/modules/users/routes"
import { getViewsRoutes } from "@/modules/stats/views/routes"
import { getVouchersRoutes } from "@/modules/vouchers/routes"
import { getWebinarsRoutes } from "@/modules/webinars/route"
import { getCertificateTemplatesRoutes } from "@/modules/certificates/route"
import { getBinRoutes } from "@/modules/bin/routes"
import { useAccountStore } from "@/modules/account/store"
import { useAppConfigStore } from "@/store/appConfig"
import { useAppProfileConfigStore } from "@/store/appProfileConfig"
import { watch } from "vue"
import { useTagsStore } from "@/modules/tags/store"
import { getPowerlearningRoutes } from "@/modules/powerlearning/route"

const routes: RouteRecordRaw[] = [
  ...getAccesslogsRoutes(),
  ...getAccountRoutes(),
  ...getAdvertisementsRoutes(),
  ...getAppointmentsRoutes(),
  ...getAppSwitcherRoutes(),
  ...getCategoriesRoutes(),
  ...getCompetitionsRoutes(),
  ...getCommentsRoutes(),
  ...getCoursesRoutes(),
  ...getDashboardRoutes(),
  ...getFormsRoutes(),
  ...getGlobalRoutes(),
  ...getImportRoutes(),
  ...getIndexCardsRoutes(),
  ...getKeywordsRoutes(),
  ...getMailsRoutes(),
  ...getMediaLibrariesRoutes(),
  ...getNewsRoutes(),
  ...getPagesRoutes(),
  ...getPowerlearningRoutes(),
  ...getQuestionsRoutes(),
  ...getQuizTeamsRoutes(),
  ...getSettingsRoutes(),
  ...getStatsQuizPlayersRoutes(),
  ...getStatsRatingsRoutes(),
  ...getStatsUsersRoutes(),
  ...getStatsUserActivityRoutes(),
  ...getStatsWbtRoutes(),
  ...getSuggestedQuestionsRoutes(),
  ...getTagsRoutes(),
  ...getTenantRoutes(),
  ...getTestsRoutes(),
  ...getTranslationsRoutes(),
  ...getUsersRoutes(),
  ...getViewsRoutes(),
  ...getVouchersRoutes(),
  ...getWebinarsRoutes(),
  ...getBinRoutes(),
  ...getCertificateTemplatesRoutes(),
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      // If you want to activate the following line, consider that the KDetailPage component also has some custom scroll handling which won't work when this line is active.
      // return { top: 0 }
    }
  },
})

async function validateAppId() {
  const appConfigStore = useAppConfigStore()
  const accountStore = useAccountStore()
  if (!accountStore.isLoggedIn) {
    return
  }
  const currentAppConfigState = localStorage.getItem("appConfig")
  if (!currentAppConfigState) {
    return
  }
  const parsedAppConfigState = JSON.parse(currentAppConfigState) as Record<string, any>
  if (!parsedAppConfigState?.appId) {
    return
  }
  if (appConfigStore.appId !== parsedAppConfigState.appId) {
    // If the app id changed, refresh the whole page so that we can be sure the admin sees all the latest data
    window.location.reload()
  }
}

watch(useWindowFocus(), async (isFocused) => {
  if (isFocused) {
    // This only activates when the actual window is focused (not when only the iframe is focused), but for our use case that should be fine.
    await validateAppId()
  }
})

router.beforeEach(async (to) => {
  const accountStore = useAccountStore()
  if (!accountStore.isLoggedIn && !to.meta.anonymousOnly) {
    return { name: "login" }
  }
  if (accountStore.isLoggedIn && to.meta.anonymousOnly) {
    return { name: "dashboard" }
  }
})

router.afterEach((to, from) => {
  if (to.name === "logout") {
    // As these requests are asynchronous, we do not want to execute them while logging out, because they might interfere with our state.
    return
  }

  const accountStore = useAccountStore()
  if (!accountStore.isLoggedIn) {
    return
  }

  // On navigation, refresh settings and rights
  // We don't want to do this when the user navigates within the same page and just a query parameter changes,
  // that's why we only do it if the matched route name changes
  if (from.matched[0]?.name !== to.matched[0]?.name) {
    const appConfigStore = useAppConfigStore()
    const appProfileConfigStore = useAppProfileConfigStore()
    const tagsStore = useTagsStore()

    tagsStore.fetchTags().then()
    accountStore.fetchRights().then()
    appConfigStore.fetchSettings().then()
    if (appConfigStore.appId) {
      appProfileConfigStore.fetchSettings(appConfigStore.appId).then()
    }
  }
})
export function useCurrentRouter() {
  return router
}
export default router
