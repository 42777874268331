import { acceptHMRUpdate, defineStore } from "pinia"
import { api } from "@/logic/api"
import { SharedAppProfileSettings } from "../../types/appProfileSettings"

type BasicAppProfile = {
  id: number
  settings: {
    app_name: string
    external_domain: null | string
    subdomain: string
    app_icon: null | string
  }
}

export const useAppProfileConfigStore = defineStore("appProfileConfig", {
  persist: true,
  state: () => {
    return {
      appProfileId: 0,
      settings: {} as SharedAppProfileSettings,
      allProfiles: [] as BasicAppProfile[],
    }
  },
  actions: {
    async fetchSettings(appId: number) {
      const response = await api.get(`/apps/${appId}/profiles/settings`)
      this.settings = response.settings
      this.appProfileId = response.appProfileId
    },
    async fetchAllProfiles(appId: number) {
      const response = await api.get(`/apps/${appId}/profiles`)
      this.allProfiles = response.data
    },
  },
  getters: {
    getFrontendUrl(): string | undefined {
      if (this.settings.external_domain) {
        return `https://${this.settings.external_domain}`
      }
      if (this.settings.subdomain) {
        return `https://${this.settings.subdomain}.keelearning.de`
      }
      return undefined
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useAppProfileConfigStore, import.meta.hot))
}
