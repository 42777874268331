export function getGlobalRoutes() {
  return [
    {
      path: "/design",
      name: "design",
      component: () => import("./views/DesignSystemView.vue"),
    },
    {
      path: "/design-tag-select",
      name: "design-tag-select",
      component: () => import(/* webpackChunkName: "global-design-tag-select" */ "./views/TagSelectView.vue"),
    },
  ]
}
