<template>
  <div :class="componentClass" class="s-wrapper">
    <v-select color="primary" v-bind="attributes" :error="hasError" @update:model-value="handleModelValueUpdate">
      <template v-if="attributes.grouped" #item="{ props, item }">
        <v-divider v-if="item.raw.type === 'divider'"></v-divider>
        <v-list-subheader v-else-if="item.raw.type === 'subheader'">{{ item.title }}</v-list-subheader>
        <v-list-item v-else v-bind="props">
          <template #prepend>
            <k-radio :model-value="attributes.modelValue.includes(item.value)"></k-radio>
          </template>
        </v-list-item>
      </template>
    </v-select>
    <k-input-error v-if="detailStore" :name="$props.name" :errors="detailStore.errors" :label="$props.label"></k-input-error>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue"
import { VSelect } from "vuetify/components"
import { DetailPageStore } from "@/store/factories/detailPageStore"

const KSelectProps = VSelect.props
KSelectProps.items = {
  type: [Array, Object],
  required: true,
}
KSelectProps.name = {
  required: false,
  default: "",
}
type KSelectPropsType = Partial<typeof KSelectProps>
KSelectProps.grouped = {
  type: Boolean,
  default: false,
}
KSelectProps.alwaysReturnArray = {
  type: Boolean,
  default: false,
}

export default defineComponent<KSelectPropsType>({
  props: VSelect.props,
  emits: ["update:modelValue"],
  setup() {
    return {
      detailStore: inject("detailStore", null) as null | DetailPageStore,
    }
  },
  computed: {
    attributes() {
      const props: KSelectPropsType = {
        ...this.$props,
        ...this.$attrs,
        variant: "outlined",
        density: "compact",
        menuIcon: "far fa-chevron-down",
        menuProps: {
          offset: 4,
        },
        hideDetails: !this.$props.hint,
      }
      delete props.class
      delete props.menu
      delete props.items
      // Vuetify doesn't support specifying the items as an object like this:
      // { courses: 'Kurse', tests: 'Tests', users: 'Benutzer' }
      // But that's a really easy way of doing it, so we allow it, and convert the object notation to
      // Vuetify's syntax here
      if (typeof this.$props.items === "object" && !Array.isArray(this.$props.items)) {
        props.items = Object.entries(this.$props.items).map(([key, value]) => {
          return {
            value: key,
            title: value,
          }
        })
      } else {
        props.items = this.$props.items
      }
      // We handle this event manually
      delete props["onUpdate:modelValue"]
      return props
    },
    componentClass() {
      return this.$props.class
    },
    hasError() {
      return !!this.$props.name && ((this.$props.errors && !!this.$props.errors[this.$props.name]) || (this.detailStore && this.detailStore.errors && !!this.detailStore.errors[this.$props.name]))
    },
  },
  methods: {
    updateModelValue(value: string[] | string) {
      if (this.$props.alwaysReturnArray && !Array.isArray(value)) {
        this.$emit("update:modelValue", [value])
        return
      }
      this.$emit("update:modelValue", value)
    },
    handleModelValueUpdate(newItems: string[] | undefined) {
      // Custom implementation of grouped items, e.g. when one item in a group is selected, deselect all of the other items in that group.
      // For non-grouped items, we just update the model value as normal.
      const oldItems = this.attributes.modelValue
      if (!this.attributes.grouped || !newItems || !oldItems) {
        this.updateModelValue(newItems)
        return
      }
      // Find out if a new item has been selected
      const newItemValue = newItems.find((newItem) => !oldItems.includes(newItem))
      if (!newItemValue) {
        this.updateModelValue(newItems)
        return
      }
      // Find the group of the newly selected item
      const newItemObject = this.attributes.items.find((item: any) => item.value === newItemValue)
      if (!newItemObject) {
        this.updateModelValue(newItems)
        return
      }
      // Iterate over all new items and remove all items that are in the group of the new item, but are not the new item itself
      const updatedNewItems = newItems.filter((itemValue) => {
        if (itemValue === newItemValue) {
          // The newly selected item should always be included
          return true
        }
        const itemGroup = this.attributes.items.find((itemObject: any) => itemObject.value === itemValue)?.group
        // Items should be removed if they are in the same group as the newly selected item
        return itemGroup !== newItemObject.group
      })
      this.updateModelValue(updatedNewItems)
    },
  },
})
</script>

<style lang="scss" scoped>
.s-wrapper {
  flex: 1 0;
}
</style>
