export function getTenantDetailPageRoutes(currentModule: string, routePrefix: string) {
  return [
    {
      path: routePrefix,
      name: `${currentModule}/tenant-outlet`,
      component: () => import("./components/TenantDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:tenantId/general`,
          name: `${currentModule}/tenant-general`,
          component: () => import("./views/TenantEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "tenant",
          },
        },
      ],
    },
  ]
}

export function getTenantRoutes() {
  return [
    {
      path: "/tenants",
      name: "tenants",
      component: () => import(/* webpackChunkName: tenants */ "./views/TenantsList.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: getTenantDetailPageRoutes("tenant", "details"),
    },
  ]
}
