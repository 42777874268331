<template>
  <v-snackbar v-for="(snackbar, idx) in snackbarsStore.snackbars" :key="idx" v-model="snackbar.visible" position="sticky" :timeout="snackbar.timeout" :color="snackbar.color" :content-class="snackbar.contentClass" location="top right">
    <div v-html="snackbar.message" />
    <template #actions>
      <k-button color="white" icon @click="snackbar.visible = false">
        <v-icon>far fa-times</v-icon>
      </k-button>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useSnackbarsStore } from "@/store/snackbars"

const snackbarsStore = useSnackbarsStore()
</script>
