import { useAppConfigStore } from "@/store/appConfig"
import { useAccountStore } from "@/modules/account/store"
import { getConstants } from "@/logic/constants"
import { useI18n } from "vue-i18n"
import { computed, ComputedRef } from "vue"

export type TranslationsTypes = { value: number; title: string }[]
export function useTranslationsTypes(): ComputedRef<TranslationsTypes> {
  const appConfigStore = useAppConfigStore()
  const accountStore = useAccountStore()
  const constants = getConstants()
  const { t } = useI18n()

  const canEditCourses = appConfigStore.settings.module_courses && accountStore.rights["courses-edit"]
  const canEditKeywords = appConfigStore.settings.module_keywords && accountStore.rights["keywords-edit"]
  const canEditMails = accountStore.rights["mails-edit"]
  const canEditTags = accountStore.rights["tags-edit"]
  const canEditForms = appConfigStore.settings.module_forms && accountStore.rights["forms-edit"]

  return computed<TranslationsTypes>(() => {
    const types: TranslationsTypes = []

    if (canEditCourses) {
      types.push({ value: constants.MORPH_TYPES.COURSE, title: t("translations.typeOptions.course") })
    }
    if (appConfigStore.settings.module_questions && accountStore.rights["questions-edit"]) {
      types.push({ value: constants.MORPH_TYPES.QUESTION, title: t("translations.typeOptions.questions") })
    }
    if (appConfigStore.settings.module_learningmaterials && accountStore.rights["learningmaterials-edit"]) {
      types.push({ value: constants.MORPH_TYPES.MEDIALIBRARY_FOLDER, title: t("translations.typeOptions.medialibraryFolder") })
    }
    if (appConfigStore.settings.module_news && accountStore.rights["news-edit"]) {
      types.push({ value: constants.MORPH_TYPES.NEWS, title: t("translations.typeOptions.news") })
    }
    if (accountStore.rights["pages-edit"]) {
      types.push({ value: constants.MORPH_TYPES.PAGE, title: t("translations.typeOptions.page") })
    }
    if (appConfigStore.settings.module_appointments && accountStore.rights["appointments-edit"]) {
      types.push({ value: constants.MORPH_TYPES.APPOINTMENT, title: t("translations.typeOptions.appointment") })
    }
    if (canEditForms) {
      types.push({ value: constants.MORPH_TYPES.FORM, title: t("translations.typeOptions.form") })
    }
    if (appConfigStore.settings.module_advertisements && accountStore.rights["advertisements-edit"]) {
      types.push({ value: constants.MORPH_TYPES.ADVERTISEMENT, title: t("translations.typeOptions.advertisement") })
    }
    if (canEditKeywords) {
      types.push({ value: constants.MORPH_TYPES.KEYWORD, title: t("translations.typeOptions.keyword") })
    }
    if (accountStore.rights["categories-edit"]) {
      types.push({ value: constants.MORPH_TYPES.CATEGORYGROUP, title: t("translations.typeOptions.categoryGroup") })
    }
    if (appConfigStore.settings.module_tests && accountStore.rights["tests-edit"]) {
      types.push({ value: constants.MORPH_TYPES.TEST, title: t("translations.typeOptions.test") })
    }
    if (canEditCourses || canEditKeywords || canEditTags || canEditForms) {
      types.push({ value: constants.MORPH_TYPES.CONTENT_CATEGORY, title: t("translations.typeOptions.contentCategory") })
    }
    if (canEditMails) {
      types.push({ value: constants.MORPH_TYPES.MAIL_TEMPLATE, title: t("translations.typeOptions.emailTemplate") })
    }
    if (canEditCourses) {
      types.push({ value: constants.MORPH_TYPES.CERTIFICATE, title: t("translations.typeOptions.certificateTemplate") })
    }

    return types
  })
}
