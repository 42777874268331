<template>
  <div
    class="s-card"
    :class="{
      [typeClasses]: typeClasses,
    }"
  >
    <slot name="title">
      <div class="s-card__title">
        <template v-if="props.title">
          <div class="d-flex justify-space-between align-items-center">
            <span>
              <v-tooltip v-if="titleTooltip" location="top left" activator="parent" :text="titleTooltip" />
              <span v-if="icon" class="mr-2">
                <v-icon class="text-grey-darken-4 fa-1x">{{ icon }}</v-icon>
              </span>
              <span class="text-h2">{{ props.title }}</span>
            </span>
            <slot v-if="$slots['action']" name="action"></slot>
          </div>
        </template>
      </div>
    </slot>
    <div
      class="s-card__body"
      :class="{
        'pa-0': props.noPaddingContent,
      }"
    >
      <slot></slot>
    </div>

    <div v-if="$slots['actions']" class="s-card__footer">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    type?: "info" | "warning" | ""
    noPaddingContent?: boolean
    title?: string
    titleTooltip?: string
    height?: string
  }>(),
  {
    type: "",
    noPaddingContent: false,
    title: "",
    titleTooltip: "",
    height: "260",
  },
)

const computedHeight = computed(() => {
  let newHeight: string = props.height
  if (!newHeight.includes("px")) {
    newHeight += "px"
  }
  return newHeight
})

const icon = computed(() => {
  let iconValue = ""
  switch (props.type) {
    case "warning":
      iconValue = "far fa-exclamation-triangle"
      break
    case "info":
      iconValue = "far fa-exclamation-circle"
      break
  }
  return iconValue
})

const typeClasses = computed(() => {
  let classValue = ""
  switch (props.type) {
    case "warning":
      classValue = "s-card__warning"
      break
  }
  return classValue
})
</script>

<style scoped lang="scss">
.s-card {
  border-radius: 4px;
  background: #fff;
  border: 1px solid $color-grey2;
  max-height: v-bind(computedHeight);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);

  &__warning {
    border-color: #f95779;
    background: #fff7f8;
  }

  .s-card__title {
    padding: 16px;
    height: 52px;
  }

  .s-card__body {
    padding: 16px;
    flex: 1;
  }
  .s-card__footer {
    padding: 0 16px 16px 16px;
  }
}
</style>
