import { acceptHMRUpdate, defineStore } from "pinia"

export type Snackbar = {
  message: string
  color: string
  contentClass: string
  timeout: number
  visible: boolean
}

export const useSnackbarsStore = defineStore("snackbars", {
  state: () => {
    return {
      snackbars: [] as Snackbar[],
    }
  },
  actions: {
    error(message: string) {
      this.snackbars.push({
        message,
        color: "red",
        contentClass: "text-white",
        timeout: 5000,
        visible: true,
      })
    },
    success(message: string) {
      this.snackbars.push({
        message,
        color: "green",
        contentClass: "text-white",
        timeout: 5000,
        visible: true,
      })
    },
    info(message: string) {
      this.snackbars.push({
        message,
        color: "blue",
        contentClass: "text-white",
        timeout: 5000,
        visible: true,
      })
    },
    warning(message: string) {
      this.snackbars.push({
        message,
        color: "orange",
        contentClass: "text-white",
        timeout: 5000,
        visible: true,
      })
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useSnackbarsStore, import.meta.hot))
}
