<template>
  <div class="s-breadcrumb">
    <component
      :is="item.component"
      v-for="(item, idx) in computedItems"
      v-bind="item.props"
      :key="item.label"
      :to="item.to"
      class="s-breadcrumb__item text-h2"
      :class="{
        'text-vio-lighten-2': idx < items.length - 1,
        'text-tu-lighten-1': idx === items.length - 1,
      }"
    >
      <span :class="{ 'text-break': item.canBreak === true }">{{ item.label }}</span>
      <v-icon v-if="idx < items.length - 1" :size="16">far fa-chevron-right</v-icon>
    </component>
  </div>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from "vue-router"
import { computed } from "vue"

export type Item = {
  label: string
  to?: RouteLocationRaw
  canBreak?: boolean
}
const props = defineProps<{
  items: Item[]
}>()

const computedItems = computed(() => {
  return props.items.map((item) => {
    return {
      ...item,
      component: item.to ? "router-link" : "div",
      props: item.to ? { to: item.to } : {},
    }
  })
})
</script>

<style lang="scss" scoped>
.s-breadcrumb {
  display: flex;
  padding: 8px;
  gap: 8px;
}

.s-breadcrumb__item {
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;

  @at-root a#{&}:hover {
    & > span {
      color: $color-tu2 !important;
    }
  }
}
</style>
