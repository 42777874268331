{
  appointments: {
    appointmentDeleting: {
      confirmation: 'Sind Sie sicher, dass Sie den Termin "{appointmentName}" in den Papierkorb schieben möchten?',
      deletedSuccessfully: "Der Termin wurde in den Papierkorb geschoben.",
      title: "Termin löschen",
    },
    appointmentDetailsPage: {
      access: "Zugang",
      alerts: {
        isBinned: "Dieser Termin ist im Papierkorb.",
        isCancelled: "Dieser Termin is abgesagt.",
      },
      availableOn: "Startdatum",
      createdAt: "Erstellt am",
      createdSuccessfully: "Der Termin wurde erstellt",
      date: "Datum",
      dateHint: "Format: dd.mm.yyyy",
      deleteAppointment: "Termin löschen",
      description: "Beschreibung",
      enableParticipationManagement: "Teilnehmerverwaltung aktivieren",
      from: "Ab",
      limitNumberOfParticipants: "Anzahl der Teilnehmer beschränken",
      location: "Ort / Meeting-URL",
      maxNumberOfParticipants: "Maximale Teilnehmeranzahl",
      name: "Name",
      notificationNoResult: "Es hat noch kein User eine Benachrichtigung für diesen Termin erhalten.",
      notifyAdminAboutChangingUserCount: "E-Mail an Hauptadmins senden wenn sich ein User an-/abmeldet",
      pastNotifications: "Versendete Benachrichtigungen",
      publishAppointment: "Termin veröffentlichen",
      publishedOn: "Veröffentlicht am",
      registeredOn: "Registriert am",
      reminder: "Erinnerung",
      saveChanges: "Änderungen speichern",
      search: "Suchen",
      sections: {
        appointment: {
          title: "Termin",
        },
        details: {
          title: "Details",
        },
        participationManagement: {
          description: "Hier können Sie Usern ermöglichen, sich aktiv zu diesem Termin einzuschreiben und darüber auch die Anzahl der angemeldeten User einschränken.",
          title: "Teilnehmerverwaltung",
        },
        publishing: {
          description: "Entscheiden Sie, ob der Termin sofort für die User veröffentlicht werden soll oder erst in der Zukunft.",
          title: "Veröffentlichung",
        },
        reminderAndNotification: {
          description: "Benachrichtigung, wenn der Termin sichtbar wird und wenn der Termin startet.",
          title: "Erinnerung & Benachrichtigung",
        },
        title: "Titel",
      },
      sendNotification: "Benachrichtigung für den {publishedAt} vormerken",
      sendNotifications: {
        allUsers: {
          button: "Einen User benachrichtigen |  Alle {count} User benachrichtigen",
          text: "Aktuell hat kein User der benachrichtig werden möchte Zugriff zu diesem Termin. | Sende eine Benachrichtigung an den einen User, der aktuell Zugriff zu diesem Termin hat und benachrichtigt werden will. | Sende eine Benachrichtigung an alle {count} User, die aktuell Zugriff zu diesem Termin haben und benachrichtigt werden wollen.",
          title: "Alle User",
        },
        header: "Benachrichtigungen versenden",
        onlyNewUsers: {
          button: "Einen User benachrichtigen | {count} User benachrichtigen",
          text: "Kein User der benachrichtigt werden möchte, wurde noch nicht über diesen Termin benachrichtigt. | Sende eine Benachrichtigung an den User, der noch nicht über diesen Termin benachrichtigt wurde, aber benachrichtigt werden möchte. | Sende eine Benachrichtigung an die {count} User, die noch nicht über diesen Termin benachrichtigt wurden, dies aber wollen.",
          title: "Nur neue User",
        },
        sentSuccessFully: "Die Benachrichtigungen werden nun versendet.",
      },
      sendNotificationsButtonLabel: "Alle, oder neue User benachrichtigen",
      shouldBePublishedOnSpecificDate: "Soll zu einem bestimmten Datum veröffentlicht werden",
      subPages: {
        general: "Allgemein",
        participationManagement: "Teilnehmer Verwaltung ({count})",
      },
      timeBeforeAppointmentStarts: "Bevor der Termin startet",
      timeHint: "Format: hh:mm",
      type: "Typ",
      unit: "Einheit",
      until: "Bis",
      updatedAt: "Aktualisiert am",
      username: "User",
      users: "User",
    },
    appointmentList: {
      description: "Erstellen und verwalten Sie Online- und Präsenz-Termine.",
      filter: "Filter",
      filterLabels: {
        active: "Sichtbar / in Bearbeitung",
        activeWithoutParticipants: "Sichtbar / Ohne Teilnehmer",
        completed: "Abgeschlossen",
        name: "Name / ID / Datum",
        withoutParticipants: "Ohne Teilnehmer",
      },
      table: {
        appointment: "Termin",
        name: "Name",
        registered: "Angemeldete User",
        state: "Status",
        tags: "TAGs",
        type: "Typ",
        users: "User",
      },
      title: "Termine",
    },
    create: "Termin erstellen",
    reminderTimeUnits: {
      days: "Tage",
      hours: "Stunden",
      minutes: "Minuten",
    },
    types: {
      "1": "Online",
      "2": "Präsenztermin",
    },
  },
  binList: {
    buttonLabels: {
      deleteItemsWithConnectedContent: "Inhalt(e) löschen",
      deleteSelected: "Ausgewählte Löschen",
      emptyBin: "Papierkorb Leeren",
      restoreItems: "Inhalt wiederherstellen|Inhalte wiederherstellen",
      restoreSelected: "Ausgewählte Wiederherstellen",
    },
    confirmToDeleteAllItems: "Möchten Sie wirklich 1 Inhalt und die damit verknüpften Inhalte löschen? | Möchten Sie wirklich alle {count} Inhalte und die damit verknüpften Inhalte löschen?",
    confirmToDeleteSelectedItems: "Möchten Sie wirklich 1 ausgewählten Inhalt und die damit verknüpften Inhalte löschen? | Möchten Sie wirklich die {count} ausgewählten Inhalte und die damit verknüpften Inhalte löschen?",
    confirmToRestoreSelectedItems: "Möchten Sie wirklich 1 ausgewählten Inhalt wiederherstellen?|Möchten Sie wirklich {count} ausgewählte Inhalte wiederherstellen?",
    modalTitles: {
      deleteItems: "Inhalte endgültig löschen",
      emptyBin: "Papierkorb leeren",
      restoreItems: "Inhalte wiederherstellen",
    },
    moduleDescription: "Hier finden Sie gelöschte TAGs, Termine, Formulare und Mediatheksinhalte und können diese wiederherstellen oder final löschen.",
    moduleTitle: "Papierkorb",
    restoreFailed: "Der Inhalt konnte nicht wiederhergestellt werden.|Die Inhalte konnten nicht wiederhergestellt werden.",
    restoreSuccessful: "Der Inhalt wurde wiederhergestellt.|Die Inhalte wurden wiederhergestellt.",
    tags: "TAGs",
    title: "Titel",
    type: "Typ",
    typeSelectLabel: "Typen",
  },
  categories: {
    categorySelectLabelPlural: "Kategorien",
    categorySelectLabelSingular: "Kategorie",
  },
  contentCategories: {
    addCategory: "Kategorie hinzufügen",
    categoryHasRelatedCourses: "1 zugehöriger Kurs | {count} zugehörige Kurse",
    categoryHasRelatedForms: "1 zugehöriges Formular | {count} zugehörige Formulare",
    categoryHasRelatedKeywords: "1 zugehöriges Schlagwort | {count} zugehörige Schlagwörter",
    categoryHasRelatedTags: "1 zugehöriger TAG | {count} zugehörige TAGs",
    contentCategoryCreatedSuccessfully: "Kategorie erfolgreich erstellt",
    contentCategoryPutInBinSuccessfully: "Kategorie wurde in den Papierkorb geschoben.",
    contentCategorySelect: {
      add: "Hinzufügen",
      defaultLabel: "Kategorie",
      nameLabel: "Name der Kategorie",
      newCategory: "Neue Kategorie",
    },
    contentCategoryUpdatedSuccessfully: "Kategorie erfolgreich aktualisiert",
    createCategory: "Kategorie erstellen",
    deleteCategoryConfirmation: "Sind Sie sicher, dass Sie diese Kategorie in den Papierkorb schieben möchten?",
    deleteCategoryTitle: "Kategorie löschen",
    general: "Allgemein",
    name: "Name",
    newCategory: "Neue Kategorie",
    saveChanges: "Änderungen speichern",
    types: {
      "1300": "Schlagwörter",
      "19": "Formulare",
      "3000": "TAGs",
      "5": "Kurse",
    },
  },
  course: {
    chapter_deleted_successfully: "Das Kapitel wurde erfolgreich gelöscht.",
    content_deleted_successfully: "Der Inhalt wurde erfolgreich gelöscht.",
    contents: {
      completion_time: "⌀ Zeit zum Abschluss",
      completion_time_tooltip: "Wie lange User seit ihrem Kursstart durchschnittlich gebraucht haben um diesen Inhalt zu bearbeiten.",
      forms: {
        all: "Alle",
        completion_duration: "Bearbeitungsdauer",
        download: "Download",
        filters: "Filter",
        last_worked_on: "Zuletzt Bearbeitet",
        not_submitted: "Nicht eingereicht",
        search: "Suchen",
        star_answer: "1 Stern|{count} Sterne",
        started_at: "Gestartet am",
        submitted: "Eingereicht",
        user: "User",
      },
      passed: "Bestanden",
      passed_tooltip: "Wie viele User den Inhalt bereits bearbeitet haben.",
      questions: {
        all: "Alle",
        completion_time: "Zeit zum Abschluss",
        download: "Download",
        failed: "Nicht bestanden",
        filters: "Filter",
        is_correct: "Ist das richtig?",
        last_worked_on: "Zuletzt Bearbeitet",
        passed: "Bestanden",
        percentage: "Ergebnis",
        remind_users: "User erinnern",
        search: "Suchen",
        started_at: "Gestartet am",
        status: "Status",
        tries: "Versuche",
        unfinished: "Noch nicht abgeschlossen",
        user: "User",
      },
      remind_users: "User erinnern",
      score: "⌀ Ergebnis",
      score_tooltip: "Für Tests: Wie viel Prozent der Fragen User durchschnittlich in ihrem letzten Testversuch richtig beantwortet haben.",
      search: "Suchen",
      show_contents_with_statistics: "Nur Inhalte mit Statistiken anzeigen",
      time_spent_here: "⌀ Hier verbrachte Zeit",
      title: "Titel",
      todolists: {
        noComments: "Aktuell gibt es noch keine Kommentare.",
        passed: "Abgeschlossen",
        started: "Gestartet",
        user: "User",
      },
      tries: "⌀ Versuche",
      tries_tooltip: "Für Tests: Wie viele Versuche User durchschnittlich gebraucht haben um den Test zu bestehen.",
      type: "Typ",
    },
    participants: {
      active: "Aktiv",
      all: "Alle Teilnehmer + User mit Zugriff",
      archived: "Archiviert",
      certificate_n: "Zertifikat {n}",
      certificates: "Zertifikate",
      completion_time: "Zeit zum Abschluss",
      completion_time_tooltip: "Die Dauer zwischen Start des Kurses durch den User und dem Abschluss des Kurses.",
      download: "Download",
      download_column: "Download",
      download_column_tooltip: "Wenn der User Zertifikate im Kurs erhalten hat, können diese hier herunter geladen werden.",
      expired: "Abgelaufen",
      failed: "Nur nicht-bestandene Teilnehmer",
      filters: "Filter",
      finished: "Nur abgeschlossene Teilnahmen",
      includeInactiveUsers: "Inaktive user einbeziehen",
      last_worked_on: "Zuletzt Bearbeitet",
      last_worked_on_tooltip: "Wann der User zuletzt Fortschritt im Kurs erzielt hat.",
      mark_participation_as_passed: "Kurs für den User als bestanden markieren",
      participating: "Nur Teilnehmer",
      progress: "Fortschritt",
      remind_users: "User erinnern",
      remind_users_successfully: "Die Erinnerung wurde erfolgreich an den ausgewählten User gesendet.|Die Erinnerungen wurden erfolgreich an die ausgewählten User gesendet.",
      reset_participation_status: "Kursstatus zurücksetzen und letzten fehlgeschlagenen Testversuch nicht werten",
      search: "Suchen",
      showProgress: "Zeige Fortschritt",
      started_at: "Gestartet am",
      started_at_tooltip: "Wann der User den Kurs gestartet hat.",
      status: "Status",
      status_tooltip: "Der aktuelle Kursstatus dieses Users",
      statuses: {
        failed: "Nicht bestanden",
        notPassedAt: "Nicht bestanden am {0}",
        notPassedYet: "Noch nicht bestanden",
        notStarted: "Nicht gestartet",
        passed: "Bestanden",
        passed_at: "Bestanden am {0}",
        started: "Gestartet",
      },
      unfinished_participants: "Nur nicht-abgeschlossene Teilnehmer",
      unfinished_users: "Alle nicht-abgeschlossenen User",
      user: "User",
      usertags: "User TAGs",
    },
    removers: {
      chapter: {
        confirmPermanentDeletion: "Möchten Sie dieses Kapitel endgültig löschen?",
        contents: "1 Kursinhalt|{count} Kursinhalte",
        deleteChapter: "Kapitel löschen",
        lastChapterCantBeDeleted: "Das letzte Kapitel eines Kurses kann nicht gelöscht werden.",
      },
      content: {
        certificates: "1 verliehenes Zertifikat|{count} verliehene Zertifikate",
        confirmPermanentDeletion: "Möchten Sie diesen Inhalt endgültig löschen?",
        deleteContent: "Inhalt löschen",
      },
    },
    searchLabel: "Name",
    settings: {
      access: "Zugang",
      accessManagement: "Zugangsmanagement",
      accessTypeOptions: {
        tagAssignment: "TAG-Zuweisung",
        userAssignment: "Benutzerzuweisung",
      },
      active: "Aktiv",
      addNotification: "Benachrichtigung hinzufügen",
      additionalRecipients: "Zusätzliche Empfänger",
      additionalRecipientsHint: "Kommegetrennte E-Mail Adressen",
      availableFrom: "Kursbeginn",
      availableUntil: "Kursende",
      awardTags: "Diese TAGs nach dem Bestehen des Kurses zuweisen",
      canNavigateFreely: "Freies Navigieren durch den Kurs erlauben",
      dateHint: "Format: tt.mm.jjjj",
      daysBeforeCourseEnds: "Tage vor Kursende",
      description: "Fügen Sie eine Beschreibung für diesen Kurs hinzu",
      duration: "Zeitbeschränkung",
      durationDynamicText: "Konfigurieren Sie, wie viel Zeit User haben sollten, nachdem sie den Kurs begonnen haben.",
      durationOptions: {
        dynamic: "Zeitlimit basierend auf dem Start der Teilnahme",
        fixed: "Start- und Enddatum",
      },
      enablePreview: "Kursvorschau aktivieren",
      enablePreviewText: "Erlauben Sie Usern, die keinen Zugang zum Kurs haben, den Zugang dazu anzufordern.",
      endDateAlert: "Wenn Sie dem Kurs ein End-Datum geben, können Sie hier die Benachrichtigungen einstellen, die an User und Admins versendet werden.",
      escalationManagement: "Eskalationsmanagement",
      finished: "Abgeschlossen",
      firstCourseCreation: "Erste Kurserstellung",
      howLongText: "Konfigurieren Sie, wie oft der Kurs erstellt werden soll und wie viel Zeit User haben, um den jeweiligen Kurs abzuschließen.",
      howOftenText: "Konfigurieren Sie, wie oft ein Kurs aus dieser Vorlage erstellt werden soll.",
      inactive: "Inaktiv",
      intervalTypeOptions: {
        monthly: "Monate",
        weekly: "Wochen",
      },
      intervalTypeOptionsInline: {
        monthly: "Monat|Monate",
        weekly: "Woche|Wochen",
      },
      isMandatory: "Pflichtkurs",
      managers: "Manager",
      name: "Kursname",
      notifyManagers: "Kurs Manager und externe Personen vor Kursende benachrichtigen",
      notifyUsers: "User vor Kursende benachrichtigen",
      participationDuration: "Abschlusszeit",
      participationDurationType: {
        days: "Tage",
        months: "Monate",
        weeks: "Wochen",
      },
      passed: "Bestanden",
      reminderTerm: "{count} Tag vorher | {count} Tage vorher",
      repeatedCourseNameAlert: 'Kurse, die aus dieser Vorlage erstellt werden, werden „{name} (aktuelle Woche/Jahr)" genannt.',
      repeating: "Vorlagentyp",
      repeatingTypeOptions: {
        nonRepeating: "Nicht wiederholend",
        repeating: "Wiederholend",
      },
      repetitionAlert: {
        availableNewCourse: "Neue Kurse sind für {timeLimit} {timeLimitType} verfügbar.",
        latestCourseCreatedAt: "Der letzte Kurs wurde am {date} erstellt.",
        learnMore: "Mehr erfahren",
        limitedRepetitionText: "Danach wird 1 weiterer Kurs nach {repetitionInterval} {repetitionIntervalType} erstellt.|Danach werden {count} weitere Kurse alle {repetitionInterval} {repetitionIntervalType} erstellt.",
        nextRepetitionCourseDate: "Der nächste Kurs wird am {date} erstellt.",
        repetitionCountAchievedText: "Dieser Kurs wurde bereits {count} Mal erstellt. Daher wird kein neuer Kurs mehr automatisch erstellt. | Dieser Kurs wurde bereits {count} Male erstellt. Daher wird kein neuer Kurs mehr automatisch erstellt.",
        unlimitedRepetitionText: "Danach wird alle {repetitionInterval} {repetitionIntervalType} ein neuer Kurs erstellt.",
      },
      repetitionCount: "Anzahl der Wiederholungen",
      repetitionInterval: "Wiederhole alle",
      requestAccessLink: "Umleitungs-URL",
      retractTags: "Diese TAGs nach dem Bestehen des Kurses entziehen",
      sections: {
        CourseRepetition: {
          title: "Automatische regelmäßige Kurserstellung",
        },
        access: {
          title: "Zugang",
        },
        actionsAfterPassing: {
          description: "Konfigurieren Sie automatische TAG-Aktualisierungen, nachdem ein User diesen Kurs bestanden hat.",
          title: "Maßnahmen nach dem Bestehen des Kurses",
        },
        description: {
          title: "Beschreibung",
        },
        duration: {
          title: "Kursdauer",
        },
        escalationManagement: {
          title: "Eskalationsmanagement",
        },
        general: {
          title: "Allgemein",
        },
        managers: {
          description: "Wählen Sie Kurs Manager aus, die über Aktivitäten im Kurs benachrichtigt werden.",
          title: "Kurs Manager",
        },
        notifications: {
          description: "Konfigurieren Sie automatisch gesendete Benachrichtigungen, wenn bestimmte Ereignisse eintreten.",
          title: "Benachrichtigungen",
        },
        templateInheritance: {
          description: "Stellen Sie diese Vorlage anderen Apps zur Verfügung.",
          title: "Vorlagen-Vererbung",
        },
      },
      sendNewCourseNotification: "User informieren, sobald der Kurs beginnt",
      sendNewCourseNotificationTooltip: "Diese Einstellung kann nur geändert werden, wenn der Kurs ein Startdatum in der Zukunft hat.",
      sendPassedCourseMail: "Eine Bestätigungs-E-Mail an User senden, nachdem sie den Kurs bestanden haben",
      sendRepetitionCourseReminder: "Kurs Manager vor der Erstellung eines Kurses aus dieser Vorlage erinnern",
      showRequestAccessLink: "Benutzerdefinierte URL für Zugangsanfragen",
      showRequestAccessLinkText: "Leiten Sie User an eine spezifizierte URL weiter, wenn sie Zugang anfordern, anstatt die Kursleiter über Zugangsanfragen zu informieren.",
      templateInheritance: {
        courseIsNotAvailable: "Dieser Kurs ist nicht in der Sprache {language} verfügbar.",
      },
      templateName: "Vorlagenname",
      timeLimit: "Zeitlimit",
      timeLimitType: {
        months: "Monate",
        weeks: "Wochen",
      },
      timeLimitTypeInline: {
        months: "Monat|Monate",
        weeks: "Woche|Wochen",
      },
      timeUnit: "Zeiteinheit",
      unlimited: "Unbegrenzt",
      users: "User",
    },
    wbtResults: {
      duration: "Bearbeitungsdauer laut WBT",
      durationInMinutes: "Bearbeitungsdauer laut WBT (Minuten)",
      last_worked_on: "Zuletzt Bearbeitet",
      status: "Status",
      user: "User",
    },
  },
  courseTemplates: {
    appCategories: "Eigene Kategorien",
    appTemplates: "Eigene Vorlagen",
    breadcrumbs: {
      courseTemplates: "Kursvorlagen",
      courses: "Kurse",
    },
    featured: "Ausgewählt",
    filters: {
      active: "Aktiv",
      all: "Alle",
      archived: "Archiviert",
      inactive: "Inaktiv",
      isNotRepeating: "Nicht wiederholend",
      isRepeating: "Wiederholend",
    },
    inheritedAppTemplates: "{appName} Vorlagen",
    inheritedTemplateCategories: "{appName} Kategorien",
    keeunitCategories: "Keeunit-Kategorien",
    keeunitTemplates: "Keeunit-Vorlagen",
    lessons: "Lektionen",
    loadKeelearningCourseTemplate: "keelearning Vorlage laden",
    loadOwnTemplate: "Eigene Vorlage laden",
    loadTemplate: "Vorlage laden",
    preview: "Vorschau",
    startWithEmptyCourse: "Mit leerem Kurs starten",
    templates: "1 Vorlage | {count} Vorlagen",
    types: {
      appTemplate: "Eigene Vorlage",
      course: "Kurs",
      template: "Vorlage",
    },
  },
  courses: {
    add_course: "Kurs erstellen",
    bin: {
      relatedItems: {
        certificates: "1 Erhaltene Zertifikat | {count} Erhaltene Zertifikate",
        commentReports: "1 Comment report | {count} Kommentar-Meldungen",
        comments: "1 Kommentar | {count} Kommentare",
        contents: "1 Kursinhalt | {count} Kursinhalte",
        participations: "1 Kursteilnahme | {count} Kursteilnahmen",
        tests: "1 Test | {count} Tests",
      },
    },
    breadcrumbs: {
      contents: "Inhalte",
      settings: "Einstellungen",
    },
    categories: "Kategorien",
    category: "Kategorie",
    contentCategories: {
      description: "Hier können Sie Ihre Kurskategorien erstellen, übersetzen und verwalten",
      title: "Kurs Kategorien",
    },
    contentTemplates: {
      description: "Erstellen und verwalten Sie hier Ihre Kursvorlagen",
      title: "Kursvorlagen",
    },
    contents: {
      addTagRestriction: "TAG Beschränkung",
      certificate: {
        name: "Zertifikatsname ändern",
        title: "Zertifikat",
      },
      commentModuleMustBeActivated: "Das Kommentarmodul muss für diese Funktion aktiviert sein",
      enforceFileUpload: "Datei-Upload erzwingen",
      enforceFileUploadHint: "Benutzer müssen mindestens eine Datei hochladen, um diese Aufgabenliste abzuschließen",
      title: "Inhaltstitel",
      todoList: {
        addTask: "Aufgabe hinzufügen",
        description: "Beschreibung",
        removeTodoConfirmation: "Sind Sie sicher, dass Sie dieses Todo entfernen möchten?",
        removeTodoTitle: "Todo entfernen",
        tasksHeader: "Aufgabenliste",
        title: "Aufgabe",
      },
    },
    courseContentN: "Inhalt {n}",
    coursePreview: "Kurs Vorschau",
    createTemplate: "Vorlage Erstellen",
    filters: {
      name: "Name / ID",
    },
    globalListHeaders: {
      allUsersCount: "Eingeschrieben",
      availableFrom: "Kurs start",
      completed: "Absolviert",
      isRepeating: "Wiederholt sich",
      mandatory: "Verpflichtend",
      name: "Name",
      nextRepetitionDate: "Nächstes Wiederholungsdatum",
      published: "Veröffentlicht",
      repeatsEvery: "Wiederholt sich alle",
      statistics: "Statistiken",
      user: "User",
    },
    manageCategories: "Kategorien verwalten",
    manageTemplates: "Vorlagen verwalten",
    moduleDescription: "Kombinieren Sie Inhalte zu einem Kurs.",
    moduleTitle: "Kurse",
    remover: {
      contentExists: 'Dieser Inhalt ist noch im folgenden Kurs verlinkt: "{courseTitle}", "{courseChapterTitle}", "{courseContentTitle}". {link}',
    },
    repetitions: {
      everyMonth: "Jeden Monat",
      everyNMonths: "Alle {frequency} Monate",
      everyNWeeks: "Alle {frequency} Wochen",
      everyWeek: "Jede Woche",
    },
    settings: {
      actions: {
        archive: "Archivieren",
        duplicate: "Duplizieren",
        preview: "Vorschau",
        saveAsTemplate: "Als Vorlage speichern",
        unarchive: "Dearchivieren",
      },
      archiveConfirmation: "Möchten Sie den bestehenden Kurs archivieren? <br /><br />\nDer Kurs wird für alle aktiven Teilnehmer automatisch abgesagt. <br />\nDie Statistik bleibt erhalten. <br /><br />",
      archiveCourse: "Kurs archivieren",
      archivedSuccessfully: "Kurs erfolgreich archiviert!",
      courseAlreadyTemplate: "Kurs ist bereits als Vorlage markiert!",
      deleteConfirmation: 'Sind Sie sicher, dass Sie diesen Kurs "{courseName}" in den Papierkorb verschieben möchten?',
      deleteCourse: "Kurs löschen",
      deletedSuccessfully: "Kurs wurde in den Papierkorb verschoben!",
      duplicatedSuccessfully: "Kurs erfolgreich dupliziert!",
      saveAsTemplate: "Kurs als Vorlage speichern",
      saveAsTemplateConfirmation: "Möchten Sie diesen Kurs wirklich als Vorlage speichern?",
      savePublishedCourseShouldContainActiveContent: "Der Kurs benötigt mindestens einen veröffentlichten Inhalt um veröffentlicht zu werden.",
      savePublishedTemplateShouldContainActiveContent: "Die Kursvorlage benötigt mindestens einen veröffentlichten Inhalt um veröffentlicht zu werden.",
      savedAsTemplateSuccessfully: "Kurs als Vorlage erfolgreich gespeichert!",
      unarchivedSuccessfully: "Kurs erfolgreich unarchiviert!",
      unsavedChangesWarning: "Bitte speichern Sie den Kurs zuerst.",
    },
    title: "Kurse",
  },
  createApp: {
    buttonLabels: {
      submitButton: "App erstellen",
    },
    dataSaveFailure: "Die Daten konnten leider nicht gespeichert werden.",
    dataSaveSuccess: "Die App wurde erfolgreich angelegt! Die initialen Inhalte zu erstellen dauert noch einige Minuten.",
    fieldLabels: {
      defaultLanguage: "Default-Sprache",
      hubspotCompany: "Hubspot Unternehmen",
      name: "App-Name",
      slug: "App-ID",
      subdomain: "App-Subdomain",
    },
    hubspotLoadingFailure: "Hubspot Unternehmen konnten nicht geladen werden.",
    title: "Neue App erstellen",
  },
  fileUploads: {
    corruptFileDetected: "Bitte vergewissern Sie sich, dass die Datei nicht aus einem Zip-Ordner stammt und nicht beschädigt ist.",
    deleteFile: "Beim Löschen ist ein Fehler aufgetreten",
    dragAndDropOr: "Drag & drop oder",
    fileInformationUnavailable: "Dateiinformationen nicht verfügbar",
    fileLoadError: "Beim Laden ist ein Fehler aufgetreten",
    fileTypeUnsupported: "Dieser Dateityp wird nicht unterstützt",
    invalidFile: "Ungültige Datei ausgewählt",
    loading: "Lade...",
    selectFile: "Datei auswählen",
    uploadCancelled: "Upload abgebrochen",
    uploadCancelledError: "Beim Widerrufen ist ein Fehler aufgetreten",
    uploadFailed: "Beim Hochladen ist ein Fehler aufgetreten",
    waitForFileInformation: "Warte auf Dateiinformationen",
  },
  forms: {
    categories: "Kategorien",
    contentCategories: {
      description: "Hier können Sie Formular Kategorien erstellen und verwalten.",
      title: "Formular Kategorien",
    },
    create: "Formular erstellen",
    formDeleting: {
      confirmation: 'Wollen Sie das Formular "{formName}" in den Papierkorb verschieben?',
      deletedSuccessFully: "Formular wurde in den Papierkorb verschoben.",
      title: "Formular löschen",
    },
    formDetailsPage: {
      actions: "Aktionen",
      addFieldButtonLabel: "Feld hinzufügen",
      addFieldModal: {
        title: "Formularfeld hinzufügen",
      },
      alerts: {
        isArchived: "Dieses Formular ist archiviert.",
        isBinned: "Dieses Formular ist im Papierkorb.",
      },
      archiveForm: "Formular archivieren",
      archivedSuccessFully: "Das Formular wurde archiviert.",
      createdAt: "Erstellt am",
      createdSuccessfully: "Formular wurde erstellt",
      deleteForm: "Formular löschen",
      duplicateForm: "Formular duplizieren",
      duplicatedSuccessFully: "Das Formular wurde dupliziert.",
      fields: {
        "1": {
          description: "Benutzer antworten auf Ihre Frage in Schriftform.",
          name: "Freitextfrage",
        },
        "2": {
          description: "Benutzer können auf Ihre Frage mit einem Sterne-Rating von 0 bis 5 antworten.",
          name: "Bewertung",
        },
        "3": {
          description: "Erlaubt Ihnen, dem Formular Überschriften und Abschnitte hinzuzufügen.",
          name: "Überschrift",
        },
        "4": {
          description: "Markiert einen neuen Abschnitt.",
          name: "Trenner",
        },
      },
      isRequired: "Pflichtfeld",
      module: "Modul",
      name: "Name",
      publishForm: "Formular veröffentlichen",
      removers: {
        field: {
          confirmPermanentDeletion: "Wollen Sie dieses Feld löschen?",
          deleteField: "Feld löschen",
        },
      },
      replies: "Antworten",
      saveChanges: "Änderungen speichern",
      search: "Suchen",
      sections: {
        fields: {
          description: "Fügen Sie Felder zum Formular hinzu und bearbeiten Sie sie via Drag&Drop.",
          title: "Formularfelder",
        },
        form: {
          title: "Formular",
        },
        publishing: {
          description: "Veröffentlichte Formulare können in Kurse eingefügt werden.",
          title: "Veröffentlichen",
        },
        usage: {
          description: "Diese Tabelle listet die Kurse auf, welche dieses Formular beinhalten.",
          title: "Nutzung",
        },
      },
      subPages: {
        general: "Allgemein",
        usage: "Nutzung ({count})",
      },
      title: "Titel",
      unarchiveForm: "Archivierung aufheben",
      unarchivedSuccessFully: "Archivierung rückgängig gemacht.",
      updatedAt: "Bearbeitet am",
    },
    formList: {
      description: "Erstellen und verwalten Sie Formulare die Sie in Kursen nutzen können.",
      filter: "Filter",
      filterLabels: {
        active: "Sichtbar / in Bearbeitung",
        archived: "Archiviert",
        name: "Name / ID",
      },
      table: {
        category: "Kategorie",
        tags: "TAGs",
        title: "Formular",
        updatedAt: "Aktualisiert am",
      },
      title: "Formulare",
    },
    title: "Formulare",
  },
  global: {
    action: "Aktion",
    addCoverImage: "Bild hinzufügen",
    all_types: "Alle Arten",
    cancel: "Abbrechen",
    close: "Schließen",
    confirmRemove: "Ja, entfernen",
    create: "Erstellen",
    creationDate: "Erstellt am",
    dataSaveFailure: "Die Daten konnten leider nicht gespeichert werden.",
    dateHint: "Format: dd.mm.yyyy",
    delete: "Löschen",
    deleteIrreversibly: "Dauerhaft löschen",
    deletionDate: "Gelöscht am",
    editedAt: "Bearbeitet am",
    info: "Infos",
    languages: {
      al: "Albanisch",
      bg: "Bulgarisch",
      cs: "Tschechisch",
      de: "Deutsch (Du)",
      de_formal: "Deutsch (Sie)",
      en: "Englisch",
      es: "Spanisch",
      fr: "Französisch",
      hr: "Kroatisch",
      hu: "Ungarisch",
      it: "Italienisch",
      jp: "Japanisch",
      nl: "Niederländisch",
      no: "Norwegisch",
      pl: "Polnisch",
      pt: "Portugiesisch",
      ro: "Rumänisch",
      ru: "Russisch",
      sr: "Serbisch",
      tr: "Türkisch",
      zh: "Chinesisch",
    },
    manageTranslations: "Übersetzungen verwalten",
    maxCharacters: "Maximal {count} Zeichen",
    morphTypes: {
      "1": "Mediatheks-Datei | Mediatheks-Dateien",
      "10": "Frage | Fragen",
      "1000": "Kapitel",
      "1001": "Lernfrageninhalt | Lernfrageninhalte",
      "1003": "Kursinhalt | Kursinhalte",
      "1005": "Kurspreis | Kurspreise",
      "1008": "Text | Texte",
      "11": "Antwort | Antworten",
      "1100": "Aufgabe | Aufgaben",
      "12": "Mediatheks-Ordner",
      "1200": "E-Mail-Template | E-Mail-Templates",
      "13": "Lernfragen-Kategorie | Lernfragen-Kategorien",
      "1300": "Schlagwort | Schlagwörter",
      "14": "Kategorie-Gruppe | Kategorie-Gruppen",
      "1400": "Voucher | Voucher",
      "15": "Quiz-Battle | Quiz-Battles",
      "1500": "Webinar | Webinare",
      "16": "App | Apps",
      "1600": "Testpreis | Testpreise",
      "17": "Kommentar | Kommentare",
      "1700": "Reporting | Reportings",
      "18": "Vorgeschlagene Frage | Vorgeschlagene Fragen",
      "19": "Formular | Formulare",
      "2": "News",
      "20": "Termin | Termine",
      "2000": "User",
      "21": "Aufgabenliste | Aufgabenlisten",
      "22": "Seite | Seiten",
      "23": "Formular-Feld | Formular-Felder",
      "24": "Banner",
      "25": "Inhalts-Kategorie | Inhalts-Kategorien",
      "26": "Fragen Anhang | Fragen Anhänge",
      "3": "Gewinnspiel | Gewinnspiele",
      "3000": "TAG",
      "3001": "TAG-Gruppe",
      "4": "Zertifikat | Zertifikate",
      "5": "Kurs | Kurse",
      "6": "Test | Tests",
      "7": "Test-Frage | Test-Fragen",
    },
    noResultMatchSearch: "Es wurden keine Ergebnisse gefunden, die Ihrer Suche entsprechen.",
    notificationTrackedSinceMessage: "(Benachrichtigungen tracken wir seit Januar 2024.)",
    preview: "Vorschau",
    proceed: "Weiter",
    publish: "Veröffentlichen",
    publishStatus: {
      completed: "Abgeschlossen",
      expired: "Abgelaufen",
      on: "ab dem {date}",
      unpublished: "Unveröffentlicht",
      visible: "Sichtbar",
    },
    save: "Speichern",
    search: "Suchen",
    searchIcon: "Icon suchen",
    select: "Auswählen",
    selectApp: "App wählen",
    settings: "Einstellungen",
    showDetails: "Details anzeigen",
    startedDate: "Startet am",
    tag: "TAG",
    tags: "TAGs",
    user: {
      email: "E-mail",
      first_name: "Vorname",
      last_name: "Nachname",
    },
    warning: "Warnung",
    willAlsoDeleteFollowingData: "Die folgenden Daten werden ebenfalls gelöscht:",
  },
  kEntityImage: {
    label: "Bild",
  },
  kTable: {
    noResults: "Keine Inhalte mit den angegebenen Suchkriterien gefunden. Sie können oben die Filter anpassen um Ergebnisse zu sehen.",
    noResultsWithNotAllowedEntries: "Keine Inhalte gefunden. Eventuell gibt es noch weitere Ergebnisse auf die Sie keinen Zugriff haben.",
  },
  kdetailPage: {
    backToOverview: "Zurück zur Übersicht",
    discardChangesAlert: "Sie haben noch ungespeicherte Änderungen. Möchten Sie die Änderungen verwerfen?",
    unsavedChangesWarnings: {
      discardChanges: "Änderungen verwerfen",
      discardUnsavedChanges: "Möchten Sie ungespeicherte Änderungen verwerfen?",
      title: "Ungespeicherte Änderungen",
    },
  },
  mediaLibrary: {
    add_content: "Inhalt hinzufügen",
    all_types: "Alle Arten",
    cant_add_file_in_root: "Im Hauptordner können keine Dateien hinzugefügt werden.",
    cant_delete_folder_has_folders_files: "Bitte löschen Sie zunächst alle Ordner und Dateien, die sich in diesem Ordner befinden. {link}",
    choose_new_folder: "Wählen Sie einen neuen Ordner",
    delete_folder_confirmation: 'Sind Sie sicher, dass Sie den Ordner "{folderName}" und alle seine Inhalte in den Papierkorb schieben möchten?',
    delete_folder_title: "Ordner löschen",
    duplicateFolder: "Ordner duplizieren",
    duplicatedSuccessFully: "Der Ordner wurde dupliziert.",
    files: {
      access: "Zugang",
      add_cover_image: "Titelbild hinzufügen",
      cant_delete_has_course_contents: "Bitte löschen Sie zuerst alle mit dieser Datei verknüpften Kursinhalte. {link} ",
      comments: "Kommentare",
      date: "Datum",
      delete_file_confirmation: 'Sind Sie sicher, dass Sie die Datei "{fileName}" in den Papierkorb schieben möchten?',
      delete_file_title: "Datei löschen",
      description: "Beschreibung",
      details: "Einzelheiten",
      duplicateFile: "Datei duplizieren",
      duplicatedSuccessFully: "Die Datei wurde dupliziert.",
      file_path: "Pfad der Datei",
      general: "Allgemein",
      media_file_created_successfully: "Datei wurde erfolgreich erstellt!",
      media_file_deleted_successfully: "Datei wurde in den Papierkorb geschoben.",
      media_file_saved_successfully: "Datei wurde erfolgreich gespeichert!",
      move_file: "Datei verschieben",
      notificationNoResult: "Es hat noch kein User eine Benachrichtigung für diese Datei erhalten.",
      pastNotifications: "Versendete Benachrichtigungen",
      past_notifications: "Frühere Benachrichtigungen",
      publish_file: "Datei veröffentlichen",
      publish_on_specific_date: "Soll an einem bestimmten Datum veröffentlicht werden",
      published_at: "Veröffentlicht am",
      publishing: "Veröffentlichen",
      publishing_note: "Entscheiden Sie ob die Datei sofort für die User veröffentlicht werden soll, oder an einem bestimmten Tag.",
      reminder_notifications: "Erinnerung & Benachrichtigung",
      reminder_notifications_note: "Notifications für wenn der Termin sichtbar wird und für wenn der Termin startet.",
      select_file_type: "Bitte wählen Sie einen Dateityp",
      sendNotification: "Benachrichtigung für den {publishedAt} vormerken",
      sendNotifications: {
        allUsers: {
          button: "Einen User benachrichtigen |  Alle {count} User benachrichtigen",
          text: "Aktuell hat kein User der benachrichtig werden möchte Zugriff zu dieser Datei. | Sende eine Benachrichtigung an den einen User, der aktuell Zugriff zu dieser Datei hat und benachrichtigt werden will. | Sende eine Benachrichtigung an alle {count} User, die aktuell Zugriff zu dieser Datei haben und benachrichtigt werden wollen.",
          title: "Alle User",
        },
        header: "Benachrichtigungen versenden",
        onlyNewUsers: {
          button: "Einen User benachrichtigen | {count} User benachrichtigen",
          text: "Kein User der benachrichtigt werden möchte, wurde noch nicht über diese Datei benachrichtigt. | Sende eine Benachrichtigung an den User, der noch nicht über diese Datei benachrichtigt wurde, aber benachrichtigt werden möchte. | Sende eine Benachrichtigung an die {count} User, die noch nicht über diese Datei benachrichtigt wurden, dies aber wollen.",
          title: "Nur neue User",
        },
        sentSuccessFully: "Die Benachrichtigungen werden nun versendet.",
      },
      sendNotificationsButtonLabel: "Alle, oder neue User benachrichtigen",
      sendNotificationsWithUnsavedChangesMessage: "Bitte speichern Sie Ihre Änderungen, bevor Sie Benachrichtigungen versenden.",
      send_notification_at_published_on: "Benachrichtigung senden, wenn veröffentlicht am",
      send_notification_to_all_new_users: "Benachrichtigung an alle oder neue Benutzer senden",
      usage: "Verwendung ({count})",
      usage_note: "Diese Tabelle zeigt alle Verwendungen dieser Datei außerhalb der Mediathek.",
      usage_title: "Verwendung",
      users: "Users",
      users_with_access: "Benutzer mit Zugang",
    },
    likes: "Likes",
    media_folder_deleted_successfully: "Ordner wurde in den Papierkorb geschoben.",
    media_library_file: "Datei",
    media_library_folder: "Ordner",
    media_library_listing_note: "Hinterlegen Sie Dateien in der App.",
    media_library_listing_title: "Mediathek",
    module: "Modul",
    move: "Umziehen",
    move_folder: "Ordner verschieben",
    name: "Name",
    new_file: "Neue Datei",
    new_folder: "Neuer Ordner",
    notificationNoResult: "Es hat noch kein User eine Benachrichtigung für diese Datei erhalten.",
    path: "Pfad",
    save_changes: "Änderungen speichern",
    search: "Suchen",
    search_by_name_description: "Name / Beschreibung",
    search_folder: "Ordner durchsuchen",
    suggestAIQuestions: {
      disabledGeneratingAIQuestionsMessage: "KI-Fragen sind derzeit nur für PDFs verfügbar oder wenn eine Beschreibung mit mehr als 50 Zeichen angegeben wird.",
      errorGeneratingQuestions: "Beim Erstellen der Fragenvorschläge ist ein Fehler aufgetreten.\nBitte versuchen Sie es später erneut, oder laden Sie eine andere Datei hoch.",
      generateAIQuestionsFeatureDisabled: 'Um diese Funktion zu nutzen, müssen Sie unter Einstellungen → Optionen "KI Fragen Erstellung in der Mediathek" aktivieren.',
      generatingAIQuestionsWithUnsavedChangesMessage: "Bitte speichern Sie Ihre Änderungen, bevor Sie Fragen erstellen.",
      generatingQuestions: "<b>Es werden Fragenvorschläge generiert.</b></br>Dieser Vorgang dauert bis zu drei Minuten.",
      goToSuggestedQuestions: "Zu den vorgeschlagenen Fragen gehen",
      questionsGeneratedSuccessfully: "Es wurden 1 Fragevorschläge generiert. | Es wurden {count} Fragenvorschläge generiert.",
      reachedMaximumTimesOfAIQuestionsGeneration: "Mit deinem Paket kannst du pro Kalendermonat maximal für {count} Dateien KI-Fragen erstellen. Um weitere KI-Fragen erstellen zu können, kontaktiere unser Customer Success Team per E-Mail {email}.",
      title: "KI-Fragen vorschlagen",
    },
    title: "Titel",
    views: "Aufrufe",
  },
  notifications: {
    history: {
      sentAt: "Verschickt am",
      users: "User",
    },
  },
  pagination: {
    entriesPerPage: "Einträge pro Seite:",
    of: "von",
    page: "Seite:",
  },
  question: {
    labelModal: {
      description: "Wählen Sie per Klick auf das Bild die Stellen aus, die Sie markieren möchten.",
      title: "Bild-Marker",
    },
  },
  sidenavigation: {
    accounts: "Accounts",
    advertisements: "Banner",
    app: "App",
    appointments: "Termine",
    bin: "Papierkorb",
    certificateTemplates: "Zertifikatsvorlagen",
    comments: "Kommentare",
    contents: "Inhalt",
    course: "Kurse",
    courses: "Kurse",
    dashboard: "Dashboard",
    emailTemplate: "E-Mail-Vorlagen",
    forms: "Formulare",
    import: "Import",
    keywords: "Schlagwörter",
    learningMaterials: "Mediathek",
    news: "News",
    pages: "Seiten",
    powerlearning: "Powerlearning",
    publicApiTokens: "Public API Tokens",
    questionCategory: "Fragenkategorien",
    questions: "Fragen & Lernkarten",
    quizBattle: "Quiz-Battle",
    quizCompetitions: "Quiz-Gewinnspiele",
    quizTeams: "Quiz-Teams",
    reviews: "Bewertungen",
    roles: "Rollen",
    settings: "Einstellungen",
    statistics: "Statistiken",
    tagGroups: "TAG-Gruppen",
    tags: "TAGs",
    tenants: "Mandanten",
    tests: "Tests",
    translations: "Bearbeiten & Übersetzen",
    user: "User",
    userActivity: "User-Aktivität",
    userSubmittedQuestions: "Eingereichte Fragen",
    views: "Aufrufe",
    vouchers: "Voucher",
    wbtStatistics: "WBT-Statistik",
    webinars: "Webinare",
  },
  statistics: {
    course_has_low_finishing_percentage_card_content: "Nur {percentage}% der Benutzer haben den Kurs {link} abgeschlossen. Er endet in {daysUntilEnd} Tagen am {availableUntil}.",
    course_participations: "Kursteilnahmen",
    courses: {
      active_courses: "Aktive Kurse",
      all_users: "Alle User",
      archived: "Archiviert",
      avg_time_to_finish_description: "Durchschnittliche Zeit zwischen Beginn und Abschluss des Kurses durch die User.",
      breadcrumbs: {
        courses: "Kurse",
        participants: "Kurs Teilnehmer",
        statistics: "Statistiken",
      },
      content_irregular_time_description: "User verbringen ⌀ {content_duration} in {title}, aber nur ⌀ {other_contents_avg_time} in anderen Inhalten.",
      courseViewsTrackedSince: "Die Kursaufrufe werden seit dem {trackingStartDate} erfasst.",
      course_duration: "Dauer des Kurses",
      course_dynamic_ends: "Der Kurs endet {duration}, nachdem ein User ihn gestartet hat",
      course_ended_on: "Der Kurs hat am <b>{date}</b> geendet",
      course_ends_on: "Der Kurs endet am <b>{date}</b>",
      course_started_on: "Der Kurs hat am <b>{date}</b> begonnen",
      course_starts_on: "Der Kurs beginnt am <b>{date}</b>",
      course_views_count: "Dieser Kurs wurde 1 Mal angesehen.|Dieser Kurs wurde {count} Mal angesehen.",
      courses_currently_visible: "Aktuell sind keine Kurse für User verfügbar.|Kurs ist aktuell für User verfügbar.|Kurse sind aktuell für User verfügbar.",
      courses_currently_visible_for_selected_users: "Aktuell sind keine Kurse für die ausgewählten User verfügbar.|Kurs ist aktuell für die ausgewählten User verfügbar.|Kurse sind aktuell für die ausgewählten User verfügbar.",
      editCourse: "Kurs bearbeiten",
      global_list_filters: {
        active: "Aktiv",
        active_mandatory: "Aktive Pflichtkurse",
        all: "Alle",
        archived: "Archiviert",
        current: "Aktiv & In Bearbeitung",
        expired: "Abgelaufen",
        in_preparation: "In Bearbeitung",
      },
      global_list_headers: {
        all_users: "Alle User",
        all_users_tooltip: "Aktive User, die aktuell Zugang zum Kurs haben, oder in der Vergangenheit daran teilgenommen haben",
        avg_completion_time: "⌀ Bearbeitungszeit",
        avg_completion_time_excel: "⌀ Bearbeitungszeit (Minuten)",
        avg_completion_time_tooltip: "Die durchschnittliche Zeit vom Beginn bis zum Abschluss des Kurses",
        comments: "Kommentare",
        passed: "Bestanden",
        passed_tooltip: "User, die den Kurs bestanden haben, im Vergleich zu allen User",
        started: "Gestartet",
        started_tooltip: "User, die den Kurs gestartet haben, im Vergleich zu allen User",
        title: "Titel",
        users_with_access: "User mit Zugriff",
        users_with_access_tooltip: "Aktive User, die aktuell Zugang zum Kurs haben",
      },
      inactive: "Inaktiv",
      navigation: {
        comments: "Kommentare",
        contents: "Inhalte",
        course: "Kurs",
        overview: "Übersicht",
        participants: "Teilnehmer",
      },
      new_course: "Neuer Kurs",
      new_participants: "Neue Teilnehmer",
      new_participants_over_time: "Neue Teilnehmer über Zeit",
      notExist: "Dieser Kurs wurden nicht gefunden",
      participants: "Teilnehmer",
      participants_avg_time_finish: "Durchschnittliche Zeit bis zum Abschluss",
      participation_duration_type: {
        "0": "1 Tag|{count} Tage",
        "1": "1 Woche|{count} Wochen",
        "2": "1 Monat|{count} Monate",
      },
      participation_status: {
        finished: "Beendet",
        not_started: "Noch nicht begonnen",
        started: "In Bearbeitung",
      },
      pass_rate: "Bestehensrate",
      periodRanges: {
        last30Days: "Letzte 30 Tage",
        last7Days: "Letzte 7 Tage",
        lastYear: "Letztes Jahr",
      },
      templates: "Templates",
      viewcount: "Aufrufe",
      wbtVerbs: {
        attempted: "Gestartet",
        "http://adlnet.gov/expapi/verbs/attempted": "Gestartet",
        "http://adlnet.gov/expapi/verbs/completed": "Abgeschlossen",
        "http://adlnet.gov/expapi/verbs/experienced": "Gestartet",
        "http://adlnet.gov/expapi/verbs/failed": "Nicht bestanden",
        "http://adlnet.gov/expapi/verbs/passed": "Bestanden",
      },
    },
    finished_courses: "Abgeschlossene aktive Kurse",
    finished_courses_tooltip: "Wenn alle User alle ihre momentan aktiven Kurse abgeschlossen haben, steht diese Zahl auf 100%.",
    in_the_last3Days: "in den letzten 3 Tagen",
    in_the_last7Days: "in den letzten 7 Tagen",
    last30Days: "Letzte 30 Tage",
    last7Days: "Letzte 7 Tage",
    mandatory_content_finished: "Aktive Pflichtinhalte absolviert",
    mandatory_content_finished_tooltip: "Wenn alle User alle ihre momentan aktiven Pflichtinhalte abgeschlossen haben, steht diese Zahl auf 100%.",
    minutes: "Minuten",
    most_commented_course_card_content: "Der Kurs {link} hat {period} {comments_count} Kommentare erhalten.",
    most_liked_course_card_content: "Der Kurs {link} hat {period} {likes_count} Likes erhalten.",
    nDays: "1 Tag|{count} Tage",
    nHours: "1 Stunde|{count} Stunden",
    nMinutes: "1 Minute|{count} Minuten",
    nSeconds: "1 Sekunde|{count} Sekunden",
    previousLast30Days: "Vorherige 30 Tage",
    previousLast7Days: "Vorherige 7 Tage",
    seconds: "Sekunden",
    time_range: "Zeitspanne",
    today: "Heute",
    view_comments: "Kommentare ansehen",
    yesterday: "Gestern",
  },
  tagGroups: {
    add_tag: "TAG hinzufügen",
    cant_delete_has_tags: "Bitte löschen Sie zuerst alle TAGs dieser TAG-Gruppe. {link}",
    color: "Farbe",
    count_tags_in_group: "Es gibt 1 TAG in dieser TAG-Gruppe.|Es gibt {count} TAGs in dieser TAG-Gruppe.",
    create_tag_group: "TAG-Gruppe erstellen",
    delete_tag_group: "TAG-Gruppe löschen",
    delete_tag_group_confirmation: 'Sind Sie sicher, dass Sie die TAG-Gruppe "{tagGroup}" und alle ihre TAGs in den Papierkorb schieben möchten?',
    delete_tag_group_title: "TAG-Gruppe löschen",
    description: "Hier könnnen Sie die TAG-Gruppen und die dazugehörigen TAGs verwalten.",
    edit_color_icon: "Farbe und Symbol bearbeiten",
    general: "Allgemein",
    icon: "Symbol",
    name: "Name",
    new_tag_group: "Neue TAG-Gruppe",
    no_tag_groups_yet: "Es wurden noch keine TAG-Gruppen hinzugefügt.",
    no_tags_in_tag_group: "Diese TAG-Gruppe hat noch keine TAGs.",
    save_changes: "Änderungen speichern",
    search_for_tag: "Nach TAG suchen",
    show_highscore_tag: "User mit TAGs dieser Kategorie im Quiz-Battle Highscore anzeigen",
    signup_required: "Muss bei der Registrierung ausgewählt werden",
    signup_selectable: "Bei der Registrierung vom User auswählbar",
    tag_group_created_successfully: "Tag-Gruppe erfolgreich erstellt",
    tag_group_deleted_successfully: "TAG-Gruppe erfolgreich in den Papierkorb geschoben",
    tag_group_updated_successfully: "TAG-Gruppe erfolgreich aktualisiert",
    title: "TAGs",
  },
  tagSelect: {
    label: "TAGs",
  },
  tags: {
    all_types: "Alle Arten",
    can_not_delete_tag_with_usage_count: 'Der TAG "{label}" kann nicht gelöscht werden, er wird {count} Mal verwendet. {link}',
    created_at: "Erstellt am",
    delete_tag: "TAG löschen",
    delete_tag_confirmation: "Sind Sie sicher, dass Sie diesen TAG in den Papierkorb verschieben möchten?",
    delete_tag_title: "TAG löschen",
    edit_tag_group: "TAG-Gruppe neu zuweisen",
    edited_at: "Bearbeitet am",
    exclusive_label: "Nur TAG-interne Quiz-Battle erlauben",
    general: "Allgemein",
    hideHighscore_label: "Nicht in Quiz-Statistik aufführen",
    module: "Modul",
    save_changes: "Änderungen speichern",
    search: "Suchen",
    tagIsBinned: "Dieser TAG ist im Papierkorb.",
    tag_created_successfully: "TAG erfolgreich erstellt",
    tag_deleted_successfully: "TAG wurde in den Papierkorb geschoben.",
    tag_group: "TAG-Gruppe",
    tag_settings: "TAG-Einstellungen",
    title: "Titel",
    usage: "Verwendung | Verwendung ({count})",
    usage_note: "Diese Tabelle zeigt alle Benutzer und alle Inhalte, die diesen TAG verwenden.",
    usage_title: "Verwendung",
  },
  tenants: {
    administrators: "Administratoren",
    create_tenant: "Mandant erstellen",
    create_tenant_btn: "Mandanten erstellen",
    created_at: "Erstellt am",
    general: "Allgemein",
    main_app: "Haupt-App",
    main_app_admin: "Fügen Sie einen bestehenden User als Administrator für die Haupt-App hinzu. Administratoren der Haupt-App sind nur durch ihre Rolle eingeschränkt, können aber in den jeweiligen Modulen alle Inhalte und User sehen.",
    main_app_description: "User, die keinem Mandanten zugeordnet sind, sind automatisch dieser Haupt-App zugeordnet.",
    module_description: "Hier können Sie verschiedene Mandanten anlegen. Mandanten haben eigene Einstellungen und können eigene Administratoren haben.",
    name: "Name",
    new_tenant_headline: "Neuer Mandant",
    open_settings: "Einstellungen öffnen",
    promote_admin: "Zum Administrator ernennen",
    save_changes: "Änderungen speichern",
    settings: "Einstellungen",
    settings_description: "Jeder Mandant hat individuelle Einstellungen. Definieren Sie eigene Design-Einstellungen, Sichtbarkeit von Modulen, etc.",
    tag: "TAG",
    tag_description: "Inhalte mit diesem TAG sind diesem Mandanten zugewiesen.",
    tenant_admin: "Fügen Sie einen bestehenden User als Administrator für diesen Mandanten hinzu. Dieser Administrator kann dadurch automatisch nur die User und Inhalte dieses Mandanten verwalten.",
    tenant_created_successfully: "Der Mandant wurde erstellt. Passen Sie im nächsten Schritt die Einstellungen an.",
    title: "Mandanten",
    user_admin: "Der User wurde als Administrator zum Mandanten '{name}' hinzugefügt",
    users: "User",
  },
  topNavigation: {
    appSwitcher: "App Switcher",
    greetings: "Hallo {username}",
    guide: "Guide",
    help: "Hilfe",
    helpdesk: "Helpdesk",
    logout: "Logout",
    newCourse: "Neuer Kurs",
    openApp: "App öffnen",
    privacyPolicy: "Datenschutzerklärung",
    telephoneSupport: "Telefonsupport: {telephoneNumber}",
  },
  translations: {
    advertisement: {
      description: "Inhalt",
      leaderboardImageUrl: "Grafik (Desktop)",
      link: "Link",
      rectangleImageUrl: "Grafik (Mobile)",
    },
    appointment: {
      coverImageUrl: "Bild",
      description: "Beschreibung",
      name: "Name",
    },
    autoTranslateHumanTranslations: "Auch manuell hinterlegte Texte mit automatischer Übersetzung überschreiben",
    autoTranslateMessage: "Alle Felder automatisch nach {language} übersetzen (beta)",
    automaticTranslate: "{defaultLanguage} automatisch übersetzen (beta)",
    autotranslateRunning: "Automatische Übersetzung läuft bei 1 Inhalt.|Automatische Übersetzung läuft bei {count} Inhalten.",
    azureVideoPreview: {
      awaitingProcessing: "Warte auf Verarbeitung",
      isProcessing: "Verarbeitung läuft. Das kann einen Moment dauern. Sie können die Seite auch verlassen und später wieder zurückkehren.",
      processingCanceled: "Verarbeitung wurde abgebrochen",
      processingComplete: "Verarbeitung abgeschlossen",
      processingError: "Fehler beim Verarbeite",
      processingIsBeingCanceled: "Verarbeitung wird abgebrochen",
      processingIsBeingStarted: "Verarbeitung wird gestartet",
      videoStatus: "Video:",
    },
    bulkAutoTranslateButtonLabel: "Autotranslate",
    category: {
      coverimage: "Coverbild",
      name: "Name",
    },
    categoryGroup: {
      name: "Name",
    },
    certificate: {
      image: "Bild",
    },
    changesNotTranslatedYet: "Änderungen noch nicht übersetzt. Letzte Änderungen:",
    confirmAutoTranslate: "Übersetzung bestätigen",
    contentCategory: {
      name: "Name",
    },
    copyContentFromDefaultLangauge: "Inhalt von {defaultLanguage} kopieren",
    countElementsTranslatedManually: "1 Übersetzung wurde zuletzt von einem Admin manuell hinterlegt oder angepasst. Möchten Sie diesen Eintrag mit der automatischen Übersetzung überschreiben?|{count} Übersetzungen wurden zuletzt von einem Admin manuell hinterlegt oder angepasst. Möchten Sie diese Texte mit der automatischen Übersetzung überschreiben?",
    course: {
      chapter: {
        content: {
          description: "Beschreibung",
          title: "Titel",
        },
        title: "Titel",
      },
      coverimage: "Coverbild",
      description: "Beschreibung",
      requestAccessLink: "Link zur Zugangsanfrage",
      text: {
        content: "Inhalt",
        headline: "Überschrift",
        image: "Bild",
      },
      title: "Titel",
    },
    dataSaveFailure: "Die Daten konnten leider nicht gespeichert werden.",
    downloadAllowed: "Download erlauben",
    editLabels: "Bild Markierungen bearbeiten",
    exportHelpText: "Exportiert alle aktuell gefilterten Textinhalte damit sie übersetzt / überarbeitet werden können",
    exportSelectedHelpText: "Exportiert alle ausgewählten Textinhalte damit sie übersetzt / überarbeitet werden können",
    form: {
      coverImageUrl: "Coverbild",
      title: "Titel",
    },
    formfield: {
      title: "Titel",
    },
    hereYouCanTranslateAllContent: "Hier könnnen Sie alle Inhalte übersetzen. Sie haben ebenfalls die Möglichkeit, die Inhalte zu exportieren und importieren.",
    import: {
      importError: "Der Import konnte nicht durchgeführt werden. Bitte stellen Sie sicher, dass Sie die korrekte Datei gewählt haben.",
      importSuccess: "Die Datei wurde erfolgreich importiert.",
    },
    importHelpText: "Importiert eine zuvor hier exportierte Datei wieder",
    keyword: {
      description: "Beschreibung",
      name: "Name",
    },
    labelPlaceholder: "Markertext",
    mailTemplate: {
      body: "E-Mail",
      title: "Betreff",
    },
    medialibrary: {
      coverimage: "Coverbild",
      description: "Beschreibung",
      file: "Datei",
      name: "Name",
    },
    medialibraryFolder: {
      name: "Name",
    },
    missingTranslationsCount: {
      contentCount: "1 Inhalt|{count} dieser Inhalte",
      message: "{0} wurde noch nicht vollständig in die ausgewählte Sprache übersetzt.|{0} wurden noch nicht vollständig in die ausgewählte Sprache übersetzt.",
    },
    mustBeValidLink: "Muss mit http(s):// beginnen",
    mustBeValidYoutubeUrl: "Muss eine gültige YouTube URL sein",
    news: {
      content: "Inhalt",
      coverimage: "Coverbild",
      title: "Titel",
    },
    page: {
      content: "Inhalt",
      title: "Titel",
    },
    primaryLanguage: "Primärsprache",
    question: {
      title: "Titel",
    },
    questionanswer: {
      answer: "Antwort",
      feedback: "Feedback",
    },
    saveAndClose: "Speichern und schließen",
    saveAndNext: "Speichern und weiter",
    saveContent: "Inhalt speichern",
    saveToEditLabels: "Speichern Sie die Übersetzung um die Bild Markierungen zu bearbeiten.",
    scormWBTMessage: "Aktuell erfassen wir keine Statistiken für Web-based Trainings im SCORM-Format. Durch das Einbinden des SCORM-WBTs in einen Kurs, können Sie über die Kursstatistiken den Lernerfolg abrufen.<br /> Wenn Sie die Events innerhalb Ihres WBTs tracken möchten, laden Sie dieses im modernen xAPI-Format hoch.",
    selectEntriesFromTheTable: "Wählen Sie Einträge aus der Tabelle aus",
    selectFileType: "Bitte Dateityp auswählen",
    startAutoTranslateButton: "Keine Einträge übersetzen|1 Eintrag übersetzen|{count} Einträge übersetzen",
    statusOptions: {
      all: "Alle",
      notTranslated: "Nicht übersetzt",
      translated: "Übersetzt",
    },
    subtileProcessingError: "Untertitel: Fehler beim Generieren. Bitte wenden Sie sich an den Support.",
    subtitleHint: "Wählen Sie die Sprache des Videos aus",
    subtitleLabel: "Untertitel",
    subtitleProcessing: "Untertitel:",
    switchTranslationsData: "Realname & User-ID im Player anzeigen",
    tableHeaderAll: "Alle",
    tableHeaderLastUpdated: "Letzte Änderung",
    tableHeaderStatus: "Status",
    tableHeaderTitle: "Titel",
    tableHeaderTranslate: "Übersetzen",
    tableHeaderType: "Typ",
    test: {
      description: "Beschreibung",
      name: "Name",
    },
    title: "Bearbeiten & Übersetzen",
    toOriginalContent: "Zum Originalinhalt",
    todolistitem: {
      description: "Beschreibung",
      title: "Titel",
    },
    totalElementsToAutoTranslate: "Es ist insgesamt 1 Eintrag zur Übersetzung ausgewählt.|Es sind insgesamt {count} Einträge zur Übersetzung ausgewählt.",
    translationStatus: "Übersetzungsstatus",
    translationTitle: "Titel",
    typeOptions: {
      advertisement: "Banner",
      appointment: "Termine",
      category: "Kategorien",
      categoryGroup: "Lernfragen-Kategorien",
      certificateTemplate: "Zertifikatvorlagen",
      contentCategory: "Inhalts-Kategorien",
      course: "Kurse",
      courseAward: "Kurs-Auszeichnungen",
      courseChapter: "Kurskapitel",
      courseContent: "Kursinhalte",
      emailTemplate: "E-Mail-Vorlagen",
      form: "Formulare",
      keyword: "Schlagwörter",
      medialibraryFile: "Mediathek Dateien",
      medialibraryFolder: "Mediathek Ordner",
      news: "News",
      page: "Seiten",
      questions: "Lernfragen",
      reporting: "Reportings",
      tag: "TAGs",
      tagGroup: "TAG-Gruppen",
      test: "Tests",
      user: "User",
      voucher: "Voucher",
      webinar: "Webinare",
    },
    typeSelectLabel: "Typen",
    unsavedChanges: "Es gibt ungespeicherte Änderungen",
    updated: "Geändert",
    videoProcessingError: "Video: Fehler beim Verarbeiten. Bitte wenden Sie sich an den Support.",
    videoSaveHint: "Bitte speichern Sie den Inhalt um die Verarbeitung des Videos zu starten.",
    wbtType: "WBT Typ",
  },
  users: {
    userSelect: {
      defaultLabel: "User",
      noUserFound: "Keine User gefunden",
      searchHint: "Suche nach Username / Email",
    },
  },
}
