import { useAccountStore } from "@/modules/account/store"
import { watch } from "vue"
import { useAppConfigStore } from "@/store/appConfig"
import { Vue } from "@sentry/vue/types/types"
import { initSentry, setUserInSentry } from "@/logic/sentry"

export const initHubspot = (app: Vue) => {
  loadExternalScript(import.meta.env.VITE_HUBSPOT_TRACKING)

  const accountStore = useAccountStore()
  const appConfigStore = useAppConfigStore()

  window.hsConversationsSettings = {
    loadImmediately: false,
  }

  function onConversationsAPIReady() {
    watch([() => accountStore.hubspotVisitorToken, () => appConfigStore.settings], () => loadWidget(), { immediate: true })
  }

  if (window.HubSpotConversations) {
    onConversationsAPIReady()
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }

  const loadWidget = async () => {
    if (!accountStore.isLoggedIn) {
      removeHubspot()
      return
    }
    const status = window.HubSpotConversations?.widget.status()
    if (status?.loaded) {
      return
    }
    if (!Object.keys(appConfigStore.settings).length) {
      return
    }
    if (appConfigStore.settings.hide_intercom_chat) {
      initSentry(app)
      if (accountStore.isLoggedIn) {
        setUserInSentry(accountStore.id!)
      }
      return
    }
    const visitorToken = await accountStore.getHubspotVisitorToken()

    window.hsConversationsSettings = {
      identificationEmail: accountStore.email,
      identificationToken: visitorToken,
    }

    window.HubSpotConversations?.widget.load()
    window.HubSpotConversations?.on("widgetLoaded", () => {
      initSentry(app)
      if (accountStore.isLoggedIn) {
        setUserInSentry(accountStore.id!)
      }
    })
  }
  function loadExternalScript(url: string) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = url
    script.async = true

    script.onerror = () => {
      console.error(`Error loading script: ${url}`)
    }

    document.head.appendChild(script)
  }
}

export const removeHubspot = () => {
  window.HubSpotConversations?.widget.remove()
}
