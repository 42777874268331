export function getStatsQuizPlayersRoutes() {
  return [
    {
      path: "/stats/quiz",
      name: "stats-quiz",
      component: () => import(/* webpackChunkName: stats-quiz-old-backend */ "./components/QuizOld.vue"),
    },
    {
      path: "/stats/quiz/reporting",
      name: "stats-quiz-reporting",
      component: () => import("./components/QuizReportingOld.vue"),
    },
  ]
}
