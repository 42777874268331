import { getNewsDetailPageRoutes } from "@/modules/news/routes"

export function getTranslationDetailPageRoutes(currentModule: string, routePrefix: string) {
  return [
    {
      path: routePrefix,
      name: `${currentModule}/translations-outlet`,
      component: () => import(/* webpackChunkName: "translations-wrapper" */ "./components/TranslationsDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:translationType/:translationId/general`,
          name: `${currentModule}/translations-general`,
          component: () => import(/* webpackChunkName: "translations-general" */ "./views/TranslationsEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "translations",
          },
        },
      ],
    },
  ]
}

export function getTranslationsRoutes() {
  return [
    {
      path: "/translations",
      name: "translations",
      component: () => import(/* webpackChunkName: "translations-list" */ "./views/TranslationsView.vue"),
      children: [...getTranslationDetailPageRoutes("translations", "detail"), ...getNewsDetailPageRoutes("translations", "news/")],
    },
  ]
}
