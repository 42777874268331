export function getAppSwitcherRoutes() {
  return [
    {
      path: "/appswitcher",
      name: "appswitcher",
      component: () => import("./views/AppSwitcher.vue"),
    },
    {
      path: "/create-app",
      name: "createApp",
      component: () => import("./views/CreateAppForm.vue"),
    },
  ]
}
