<template>
  <v-chip class="text-label1 s-chip" :class="{ '-binned': $props.isBinned }" v-bind="attributes">
    <template #default>
      <v-tooltip v-if="$props.isBinned" location="bottom start" :text="$props.binnedChipMessage">
        <template #activator="{ props }">
          <div v-bind="props">
            <slot />
            <v-icon v-if="$props.isBinned" class="s-chip__icon-binned ml-1">fa fa-ban fa-1x</v-icon>
          </div>
        </template>
      </v-tooltip>
      <template v-else>
        <slot />
      </template>
    </template>
    <template v-if="$slots['prepend']" #prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-else #prepend>
      <v-icon v-if="$props.icon" class="mr-1">{{ $props.icon }} fa-1x</v-icon>
    </template>
    <template v-if="$slots['append']" #append>
      <slot name="append"></slot>
    </template>
    <template v-else #append>
      <button v-if="$props.clearable" class="s-chip__clear-button" @click.stop="$emit('clear')">
        <v-icon>fa fa-times fa-1x</v-icon>
      </button>
    </template>
  </v-chip>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VChip } from "vuetify/components"

const KChipProps = VChip.props
KChipProps.clearable = {
  type: Boolean,
  required: false,
  default: false,
}
KChipProps.isBinned = {
  type: Boolean,
  required: false,
  default: false,
}
KChipProps.binnedChipMessage = {
  type: String,
  required: false,
  default: "",
}
KChipProps.variant = {
  type: String,
  required: false,
  default: "flat",
}
KChipProps.size = {
  type: String,
  required: false,
  default: "24px",
}
KChipProps.icon = {
  type: String,
  required: false,
  default: "",
}

type KChipPropsType = Partial<
  InstanceType<typeof VChip>["$props"] & {
    clearable: boolean
    isBinned: boolean
    icon: string
    binnedChipMessage: string
  }
>

export default defineComponent<KChipPropsType, { attributes: any }>({
  props: KChipProps,
  emits: ["clear"],
  computed: {
    attributes() {
      const props: KChipPropsType = {
        ...this.$props,
        ...this.$attrs,
      }

      return props
    },
  },
})
</script>

<style lang="scss" scoped>
.s-chip {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 2px 8px;
  position: relative;

  &.-binned {
    border-color: #f95779;
  }
}

.s-chip__icon-binned {
  color: #f95779;
}

.s-chip__clear-button {
  margin-left: 8px;
}
</style>
