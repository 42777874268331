<template>
  <div>
    <v-navigation-drawer v-model="isOpen" disable-route-watcher disable-resize-watcher temporary floating location="right" width="500" absolute class="c-detailPage">
      <div class="c-detailPage__wrapper">
        <slot name="asd"></slot>
        <KDetailHeader>
          <template #left>
            <KHeaderButton prepend-icon="far fa-chevron-left" @click="isOpen = false">
              {{ $t("kdetailPage.backToOverview") }}
            </KHeaderButton>
          </template>
          <template #default>
            <slot v-if="!store.isLoading" name="actionButtons" />
          </template>
        </KDetailHeader>
        <div class="c-detailPage__content">
          <div v-if="store.hasSubPages || $slots.sidebarHeader" class="c-detailPage__sidebar">
            <div v-if="$slots.sidebarHeader" class="c-detailPage__sidebarHeader">
              <slot name="sidebarHeader"></slot>
            </div>
            <div class="c-detailPage__sidebarContent">
              <KDetailPageMenu v-if="store.hasSubPages" :store="store" />
              <div v-if="$slots.sidebarContent">
                <slot name="sidebarContent"></slot>
              </div>
            </div>
          </div>
          <div class="c-detailPage__main">
            <router-view v-if="!store.isLoading" />
            <KDetailLoading v-else />
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <UnsavedChangesModal v-model="isUnsavedChangesWarningOpen" :close-unsaved-changes-warning="closeUnsavedChangesWarning" :discard-changes="discardChanges" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, toRef, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import KDetailHeader from "@/components/KDetailPage/KDetailHeader.vue"
import KDetailPageMenu from "@/components/KDetailPage/KDetailPageMenu.vue"
import { getRootRoutePath } from "@/logic/detailPage"
import { DetailPageStore } from "@/store/factories/detailPageStore"
import KDetailLoading from "@/components/KDetailPage/KDetailLoading.vue"
import KHeaderButton from "@/components/KHeaderButton.vue"
import { useMagicKeys, whenever } from "@vueuse/core"
import { useUnsavedChangesWarning } from "@/composables/unsavedChangesWarning"
import UnsavedChangesModal from "@/components/KDetailPage/UnsavedChangesModal.vue"

const props = withDefaults(
  defineProps<{
    store: DetailPageStore
    loadDataImmediately?: boolean
  }>(),
  {
    loadDataImmediately: true,
  },
)

const route = useRoute()
const router = useRouter()
const store = toRef(props, "store")

const { isUnsavedChangesWarningOpen, closeUnsavedChangesWarning, discardChanges, handleUnload } = useUnsavedChangesWarning(store.value)

const isOpen = computed({
  get() {
    return route.meta.isDetailPage === true
  },
  set(shouldBeOpen: boolean): void {
    if (shouldBeOpen) {
      if (props.loadDataImmediately) {
        props.store.loadData()
      }
    } else {
      router.push(getRootRoutePath(route))
    }
  },
})

onUnmounted(() => {
  tearDown()
})

function tearDown() {
  window.removeEventListener("beforeunload", handleUnload)
  document.documentElement.style.overflow = "unset"
}

// Reload the detail page's data whenever it's opened
watch(
  isOpen,
  () => {
    if (isOpen.value) {
      if (props.store.entryId && props.loadDataImmediately) {
        props.store.loadData()
      }
      window.addEventListener("beforeunload", handleUnload)
      document.documentElement.style.overflow = "hidden"
    } else {
      tearDown()
    }
  },
  {
    immediate: true,
  },
)

const keys = useMagicKeys()

whenever(keys.escape, () => {
  isOpen.value = false
})
</script>

<style lang="scss" scoped>
$topMargin: 4px;

:global(.c-detailPage) {
  margin-top: $topMargin !important;
  position: fixed !important;
  height: calc(100vh - #{64px + $topMargin}) !important;
  border-top-left-radius: 10px;
  overflow: hidden;
  min-width: 660px;
  max-width: 1000px;
  width: 80vw !important;
}

.c-detailPage__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.c-detailPage__content {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  flex: 1;
}

.c-detailPage__sidebar {
  width: 240px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-right: 1px;
  background: $color-grey2;
}

.c-detailPage__sidebarHeader {
  background: $color-grey1;
}

.c-detailPage__sidebarContent {
  background: $color-grey1;
  flex: 1;
}

.c-detailPage__main {
  scroll-padding-top: 10px;
  overflow-y: auto;
  padding-bottom: 40px;
  flex: 1;
}
</style>
