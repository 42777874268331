import { acceptHMRUpdate, defineStore } from "pinia"
import { api } from "@/logic/api"
import { SharedAppSettings } from "../../types/appSettings"
import { ModuleNameWithVisibilityCheck } from "@/logic/modules"
import { exhaustiveCheck } from "@/logic/tsHelpers"

type AppMetafield = {
  key: string
  label: string
  type: string
  description: string | null
  compare: boolean
  import: boolean
  personal_data: boolean
  signup: boolean
  signup_optional: boolean
  unique: boolean
}

export const useAppConfigStore = defineStore("appConfig", {
  persist: true,
  state: () => {
    return {
      appId: null as null | number,
      support_phone_number: null as null | string,
      settings: {} as SharedAppSettings | Record<string, never>,
      metafields: [] as AppMetafield[],
    }
  },
  getters: {
    isModuleVisible: (state): ((module: ModuleNameWithVisibilityCheck) => boolean) => {
      return (module: ModuleNameWithVisibilityCheck): boolean => {
        switch (module) {
          case "advertisements":
            return state.settings.module_advertisements
          case "appointments":
            return state.settings.module_appointments
          case "competitions":
            return state.settings.module_competitions && !state.settings.hide_competitions
          case "comments":
            return state.settings.module_comments
          case "courses":
            return state.settings.module_courses
          case "forms":
            return state.settings.module_forms
          case "keywords":
            return state.settings.module_keywords
          case "learningmaterials":
            return state.settings.module_learningmaterials
          case "news":
            return state.settings.module_news
          case "suggested_questions":
            return state.settings.module_suggested_questions
          case "tests":
            return state.settings.module_tests
          case "questions":
            return state.settings.module_questions
          case "quiz":
            return state.settings.module_quiz
          case "vouchers":
            return state.settings.module_vouchers
          case "webinars":
            return state.settings.module_webinars
          case "tenants":
            return state.settings.module_tenants
          case "public_api":
            return state.settings.module_public_api
          case "certificates":
            return state.settings.module_courses
          default:
            exhaustiveCheck(module)
        }
      }
    },
  },
  actions: {
    async fetchSettings() {
      const response = await api.get(`/apps/${this.appId}/settings`)
      this.settings = response.settings
      this.support_phone_number = response.support_phone_number
      this.metafields = response.metafields
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useAppConfigStore, import.meta.hot))
}
