import { getContentCategoryDetailsPageRoutes } from "@/modules/content-categories/routes"
import { getConstants } from "@/logic/constants"

export function getFormDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}/form-outlet`,
      component: () => import("./components/FormDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:formId/general`,
          name: `${currentModule}/form-general`,
          component: () => import("./views/FormEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "forms",
          },
        },
        {
          path: `:formId/usage`,
          name: `${currentModule}/form-usage`,
          component: () => import("./views/FormUsage.vue"),
          meta: {
            isDetailPage: true,
            module: "forms",
          },
        },
      ],
    },
  ]
}
export function getFormsRoutes() {
  return [
    {
      path: "/forms",
      name: "forms",
      component: () => import("./views/FormList.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getFormDetailPageRoutes("forms")],
    },
    {
      path: "/forms/categories",
      name: "forms-categories",
      component: () => import("./views/FormCategories.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [...getContentCategoryDetailsPageRoutes(`${getConstants().MORPH_TYPES.FORM}-content-categories`, "details")],
    },
  ]
}
